import { useIntl } from "react-intl"

import { Logo } from "src/components/Logo/Logo"
import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export function DashboardPageLayout({
  children,
}: {
  children?: React.ReactNode
}) {
  const intl = useIntl()

  const pageTitle = intl.formatMessage({
    defaultMessage: "Home",
    id: "dashboard.page_title",
  })

  const pageDescription = intl.formatMessage({
    defaultMessage: "Home page",
    id: "dashboard.page_description",
  })

  return (
    <>
      <MetaTags title={pageTitle} description={pageDescription} />
      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              backgroundColour="nous-glow"
              topComponent={<Logo className="h-5" />}
              showAvatar
              showNotificationsButton
            />
          ),
        }}
        desktopNav={<DesktopNav />}
        className="bg-white md:bg-transparent"
      >
        {children ?? <LoadingPagePlaceholder />}
      </GlowPageLayout>
    </>
  )
}
