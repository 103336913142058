import React from "react"

import { Link, LinkProps } from "@tanstack/react-router"
import clsx from "clsx"

import { GlowText } from "./GlowText"
import { GlowResponsiveSpacing, glowSpacingToClassNames } from "./structure"

type Props = {
  size?: "base" | "sm" | "xs"
  className?: string
  margin?: GlowResponsiveSpacing
  padding?: GlowResponsiveSpacing
} & (
  | {
      label: string | React.ReactNode[]
      icon?: React.ReactElement
      rightIcon?: React.ReactElement
    }
  | { label?: never; icon: React.ReactElement; rightIcon?: never }
) &
  (
    | ({ href: string; to?: undefined } & React.ComponentProps<"a">)
    | ({ href?: undefined; to: string } & Omit<LinkProps, "to">)
    | ({
        href?: undefined
        to?: undefined
      } & React.ComponentProps<"button">)
  )

export function GlowLink({
  label,
  icon,
  rightIcon,
  size = "base",
  className,
  ...props
}: Props) {
  const c = clsx(
    "underline text-grey-400 underline-offset-4 opacity-64 flex gap-2 items-center justify-center",
    "hover:opacity-100 transition-opacity",
    "focus:outline-none focus:text-serene-blue-600 transition-colors focus:opacity-100",
    className,
    ...glowSpacingToClassNames(props.padding ?? {}, "p"),
    ...glowSpacingToClassNames(props.margin ?? {}, "m"),
  )
  const content = (
    <>
      {icon && (
        <span className={size === "base" ? "h-6 w-6" : "h-5 w-5"}>{icon}</span>
      )}
      {label && (
        <GlowText
          size={size}
          fontWeight={size === "base" ? "medium" : "normal"}
        >
          {label}
        </GlowText>
      )}
      {rightIcon &&
        React.cloneElement(rightIcon, {
          className: size === "base" ? "h-4 w-4" : "h-2.5 w-2.5",
        })}
    </>
  )

  if (props.to) {
    return (
      <Link className={c} {...props}>
        {content}
      </Link>
    )
  }

  if (props.href === undefined) {
    return (
      <button className={c} {...props}>
        {content}
      </button>
    )
  }

  return (
    <a className={c} {...props}>
      {content}
    </a>
  )
}

type GlowNonInteractiveLinkProps = {
  size?: "base" | "sm" | "xs"
  className?: string
  margin?: GlowResponsiveSpacing
  padding?: GlowResponsiveSpacing
} & (
  | {
      label: string | React.ReactNode[]
      icon?: React.ReactElement
      rightIcon?: React.ReactElement
    }
  | { label?: never; icon: React.ReactElement; rightIcon?: never }
) &
  React.ComponentProps<"span">

export function GlowNonInteractiveLink({
  label,
  icon,
  rightIcon,
  size = "base",
  className,
  ...props
}: GlowNonInteractiveLinkProps) {
  const c = clsx(
    "underline text-grey-400 underline-offset-4 opacity-64 flex gap-2 items-center justify-center",
    "hover:opacity-100 transition-opacity",
    "focus:outline-none focus:text-serene-blue-600 transition-colors focus:opacity-100",
    className,
    ...glowSpacingToClassNames(props.padding ?? {}, "p"),
    ...glowSpacingToClassNames(props.margin ?? {}, "m"),
  )
  const content = (
    <>
      {icon && (
        <span className={size === "base" ? "h-6 w-6" : "h-5 w-5"}>{icon}</span>
      )}
      {label && (
        <GlowText
          size={size}
          fontWeight={size === "base" ? "medium" : "normal"}
        >
          {label}
        </GlowText>
      )}
      {rightIcon && React.cloneElement(rightIcon, { className: "h-4 w-4" })}
    </>
  )
  return (
    <span className={c} {...props}>
      {content}
    </span>
  )
}
