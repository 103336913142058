import clsx from "clsx"

import { GlowFlexbox, GlowBadge, GlowText } from "src/glow"
import { useViewport, Viewport } from "src/hooks/responsive"

export const ServiceLevelSelectButton = ({
  isSelected,
  onSelect,
  image,
  label,
  text,
  variant = "default",
}: {
  isSelected: boolean
  onSelect: () => void
  image: string
  label: string
  text: string
  variant?: "default" | "snappy"
}) => {
  const viewport = useViewport()

  const isMobile = viewport <= Viewport.SM

  return (
    <button
      onClick={onSelect}
      className={clsx(
        "shadow-0 relative w-1/3 rounded-lg border-2 transition active:drop-shadow-none",
        isMobile && "w-full min-w-[176px] snap-center",
        isSelected
          ? "border-virtuous-green-400 bg-virtuous-green-100"
          : "border-gray-500/20 bg-white",
        isSelected
          ? "hover:border-virtuous-green-500 hover:drop-shadow-[2px_2px_0px_#26513B]"
          : "hover:border-gray-500 hover:drop-shadow-[2px_2px_0px_#1C1917]",
      )}
    >
      <GlowFlexbox
        direction={
          variant === "snappy" ? "column" : { sm: "row", md: "column" }
        }
        alignItems="center"
        justifyContent={
          variant === "snappy" ? "center" : { sm: "start", md: "center" }
        }
        padding={{
          x: "4",
          y: "4",
        }}
        gap="3"
      >
        <img src={image} alt={label} className="h-12 w-auto md:h-20" />
        <GlowFlexbox
          direction="column"
          gap="1"
          alignItems={
            variant === "snappy" ? "center" : { sm: "start", md: "center" }
          }
          justifyContent={variant === "snappy" ? "center" : undefined}
        >
          <GlowBadge
            variant={isSelected ? "white" : isMobile ? "default" : "white"}
            label={label}
            size="sm"
          />
          <GlowText
            fontWeight="medium"
            isDisplay
            className="text-gray-500"
            textAlign="center"
            size="sm"
          >
            {text}
          </GlowText>
        </GlowFlexbox>
      </GlowFlexbox>
      {isSelected && (
        <div className="bg-virtuous-green-400 absolute right-1.5 top-1.5 h-2 w-2 rounded-full" />
      )}
    </button>
  )
}
