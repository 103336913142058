/**
 * @generated SignedSource<<f9d58193a30278504eeeffc40b643d47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavAvatarQuery$variables = Record<PropertyKey, never>;
export type NavAvatarQuery$data = {
  readonly currentUser: {
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_user">;
  } | null | undefined;
};
export type NavAvatarQuery = {
  response: NavAvatarQuery$data;
  variables: NavAvatarQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavAvatarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Avatar_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NavAvatarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "initials",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "619c8e5b215a744e0487e4abf2a16fd2",
    "id": null,
    "metadata": {},
    "name": "NavAvatarQuery",
    "operationKind": "query",
    "text": "query NavAvatarQuery {\n  currentUser {\n    ...Avatar_user\n    id\n  }\n}\n\nfragment Avatar_user on User {\n  initials\n  firstName\n}\n"
  }
};

(node as any).hash = "734063936219496a5ba2682131de8db2";

export default node;
