/**
 * @generated SignedSource<<950e17b05a9f4c277d5b0e8ffda5b5a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateAccountPopup_address$data = {
  readonly firstLine: string | null | undefined;
  readonly " $fragmentType": "CreateAccountPopup_address";
};
export type CreateAccountPopup_address$key = {
  readonly " $data"?: CreateAccountPopup_address$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateAccountPopup_address">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateAccountPopup_address",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstLine",
      "storageKey": null
    }
  ],
  "type": "HouseholdAddress",
  "abstractKey": null
};

(node as any).hash = "54b725b77f31bdf322ce1062b5195c8e";

export default node;
