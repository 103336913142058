import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { graphql, useFragment } from "react-relay"

import { GlowFlexbox, GlowIcon, GlowText, GlowLink } from "src/glow"

import { BankAccount$key } from "./__generated__/BankAccount.graphql"

const asterisk = defineMessage({
  id: "component.bankAccount.asterisk",
  defaultMessage: "*",
})

export const BankAccount = (props: {
  bankAccount: BankAccount$key
  onEdit: () => void
}) => {
  const bankAccount = useFragment(
    graphql`
      fragment BankAccount on BankAccount {
        title
        firstName
        lastName
        accountNumber
        sortCode
      }
    `,
    props.bankAccount,
  )

  const intl = useIntl()

  return (
    <GlowFlexbox
      direction="row"
      gap="6"
      alignItems="center"
      justifyContent="space-between"
      className="w-full"
    >
      <GlowIcon name="saving_bank_1_regular" className="h-5 w-5 shrink-0" />
      <GlowFlexbox direction="column" gap="1" className="grow">
        <GlowText size="base">
          <FormattedMessage
            id="component.bankAccount.name"
            defaultMessage="{title} {initial}. {lastName}"
            values={{
              title: bankAccount.title,
              initial: bankAccount.firstName?.[0],
              lastName: bankAccount.lastName,
            }}
          />
        </GlowText>
        <GlowFlexbox direction="row" gap="4">
          <GlowText as="p" size="sm" className="text-gray-500/64">
            {intl.formatMessage(
              {
                id: "component.bankAccount.accountNumber",
                defaultMessage:
                  "{asterisk}{asterisk}{asterisk}{asterisk}{lastFour}",
              },
              {
                firstThree: bankAccount.accountNumber?.substring(0, 3),
                firstFour: bankAccount.accountNumber?.substring(0, 4),
                lastFour: bankAccount.accountNumber?.substring(4, 8),
                asterisk: (
                  <span className="align-sub">
                    {intl.formatMessage(asterisk)}
                  </span>
                ),
              },
            )}
          </GlowText>
          <GlowText as="p" size="sm" className="text-gray-500/64">
            {intl.formatMessage(
              {
                id: "component.bankAccount.sortCode",
                defaultMessage:
                  "{asterisk}{asterisk}-{asterisk}{asterisk}-{lastTwo}",
              },
              {
                firstTwo: bankAccount.sortCode
                  ?.replaceAll("-", "")
                  .substring(0, 2),
                lastTwo: bankAccount.sortCode
                  ?.replaceAll("-", "")
                  .substring(4, 6),
                asterisk: (
                  <span className="align-sub">
                    {intl.formatMessage(asterisk)}
                  </span>
                ),
              },
            )}
          </GlowText>
        </GlowFlexbox>
      </GlowFlexbox>
      <GlowLink
        label={intl.formatMessage({
          id: "component.bankAccount.editButton",
          defaultMessage: "Edit",
        })}
        onClick={props.onEdit}
      />
    </GlowFlexbox>
  )
}
