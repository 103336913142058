import { useState } from "react"

import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl"

import { Alert } from "src/components/Alert/Alert"
import {
  GlowButton,
  GlowFlexbox,
  GlowForm,
  GlowFormInputQuizCheckbox,
  GlowFormTextArea,
  GlowText,
} from "src/glow"
import { CheckedRelayEnum } from "src/types"
import { capitalize } from "src/utils/capitalize"
import { serviceLevelNameMap } from "src/utils/serviceLevelNameMap"

import undelegationReasonToCheckboxLabelRecord from "./utils/undelegationReasonToCheckboxLabelRecord"

import { NousServiceLevel } from "../../__generated__/SettingsPageSavingsMode_household.graphql"
import { UndelegationReason } from "../../__generated__/SettingsPageSavingsModeUpdateHouseholdSavingsModeMutation.graphql"
import { UpdateSavingsModeArgs } from "../../SettingsPageSavingsMode"

type UndelegationReasonsFormProps = {
  onConfirm: ({
    serviceLevel,
    undelegationReasons,
  }: UpdateSavingsModeArgs) => void
  updating: boolean
  from: CheckedRelayEnum<NousServiceLevel>
}

type FormState = {
  [Key in CheckedRelayEnum<UndelegationReason>]: boolean
} & {
  otherReason: string
}

const UndelegationReasonsForm = ({
  onConfirm,
  updating,
  from,
}: UndelegationReasonsFormProps) => {
  const intl = useIntl()

  const [formState, setFormState] = useState<FormState>({
    WANT_MORE_CONTROL: false,
    DID_NOT_LIKE_PROPOSED_DEALS: false,
    DID_NOT_UNDERSTAND_DELEGATION: false,
    DOES_NOT_TRUST_NOUS: false,
    OTHER: false,
    otherReason: "",
  })

  const selectedReasons = Object.keys(formState)
    .filter(
      (key): key is CheckedRelayEnum<UndelegationReason> =>
        key !== "otherReason",
    )
    .filter((key) => formState[key] === true)

  const isInvalidSubmission =
    selectedReasons.length < 1 || selectedReasons.length > 3

  return (
    <GlowFlexbox direction="column" gap="4" alignItems="center">
      <GlowText size="sm">
        <FormattedMessage
          id="page.settings.view.savingsMode.undelegationReasons.selectUpTo"
          defaultMessage="Select up to 3 reasons"
        />
      </GlowText>

      <GlowForm
        value={formState}
        onChange={setFormState}
        onSubmit={async (formState) => {
          const undelegationReasons = {
            reasons: selectedReasons,
            otherReason: selectedReasons.includes("OTHER")
              ? formState.otherReason.trim()
              : null,
          }

          onConfirm({
            serviceLevel: "DO_EVERYTHING_MYSELF",
            undelegationReasons,
          })
        }}
      >
        <GlowFlexbox direction="column" gap="4">
          {Object.entries(undelegationReasonToCheckboxLabelRecord)
            .filter(
              (
                reason,
              ): reason is [
                CheckedRelayEnum<UndelegationReason>,
                MessageDescriptor,
              ] => Boolean(reason),
            )
            .map(([key, label]) => (
              <GlowFormInputQuizCheckbox
                key={key}
                formKey={key}
                label={intl.formatMessage(label, {
                  serviceLevel: capitalize(
                    intl.formatMessage(serviceLevelNameMap[from]),
                  ),
                })}
                isSelected={formState[key]}
              />
            ))}

          {formState.OTHER && (
            <GlowFormTextArea
              formKey="otherReason"
              label={intl.formatMessage({
                id: "page.settings.view.savingsMode.undelegationReasons.other.textArea.label",
                defaultMessage: "Please specify:",
              })}
              className="resize-none"
              placeholder={intl.formatMessage({
                id: "page.settings.view.savingsMode.undelegationReasons.other.textArea.placeholder",
                defaultMessage: "Maximum 500 characters",
              })}
              getErrors={(value) => {
                if (!value.trim()) {
                  return [
                    intl.formatMessage({
                      id: "page.settings.view.savingsMode.undelegationReasons.other.textArea.error.tooShort",
                      defaultMessage: "Please enter your reason",
                    }),
                  ]
                }

                if (value.trim().length > 500) {
                  return [
                    intl.formatMessage({
                      id: "page.settings.view.savingsMode.undelegationReasons.other.textArea.error.tooLong",
                      defaultMessage: "Reason can be at most 500 characters",
                    }),
                  ]
                }

                return []
              }}
            />
          )}

          {selectedReasons.length > 3 && (
            <Alert variant="error">
              <GlowText>
                <FormattedMessage
                  id="page.settings.view.savingsMode.undelegationReasons.error.tooManySelected"
                  defaultMessage="Please select a maximum of 3 reasons"
                />
              </GlowText>
            </Alert>
          )}

          <GlowButton
            label={intl.formatMessage({
              id: "page.settings.view.savingsMode.undelegationReasons.confirmButton.label",
              defaultMessage: "-> Confirm switch to Manual mode",
            })}
            type="submit"
            isDisabled={updating || isInvalidSubmission}
            isLoading={updating}
            className="w-full"
          />
        </GlowFlexbox>
      </GlowForm>
    </GlowFlexbox>
  )
}

export default UndelegationReasonsForm
