import { useIntl } from "react-intl"

import { GlowCard } from "src/glow"

import { SettingsPageSubPageLayout } from "./SettingsPageSubPageLayout"

import { SettingsPageComingSoon } from "../components/SettingsPageComingSoon"
import { titleMap } from "../utils"

export const SettingsPageSubPageNotifications = (props: {
  onBackToOverview: () => void
}) => {
  const intl = useIntl()

  return (
    <SettingsPageSubPageLayout
      title={intl.formatMessage(titleMap.notifications)}
      onBackToOverview={props.onBackToOverview}
    >
      <GlowCard
        margin={{
          sm: "4",
          md: {
            x: "4",
            top: "6",
            bottom: "4",
          },
        }}
      >
        <SettingsPageComingSoon />
      </GlowCard>
    </SettingsPageSubPageLayout>
  )
}
