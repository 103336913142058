import { createFileRoute, redirect } from "@tanstack/react-router"
import { z } from "zod"

import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

const searchSchema = z.object({
  mode: z.string().optional(),
  oobCode: z.string().optional(),
})

export const Route = createFileRoute("/manage-account")({
  validateSearch: searchSchema,
  beforeLoad({ context, search }) {
    const neededThings = Boolean(search.mode) && Boolean(search.oobCode)

    if (!neededThings && context.authState.isAuthenticated) {
      throw redirect({
        to: "/settings/",
      })
    }

    if (!neededThings) {
      throw redirect({
        to: "/login",
      })
    }

    if (search.mode === "resetPassword") {
      throw redirect({
        to: "/reset-password",
        search: {
          oobCode: search.oobCode,
        },
      })
    }

    if (context.authState.isAuthenticated) {
      throw redirect({
        to: "/",
      })
    }

    throw redirect({
      to: "/login",
    })
  },
  component: LoadingPagePlaceholder,
})
