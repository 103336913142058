import { UseMutationConfig } from "react-relay"
import { Disposable, MutationParameters } from "relay-runtime"

export function relayMutationToPromise<TMutation extends MutationParameters>(
  mutationFn: (config: UseMutationConfig<TMutation>) => Disposable,
  config: UseMutationConfig<TMutation>,
): Promise<TMutation["response"]> {
  return new Promise((resolve, reject) => {
    mutationFn({
      ...config,
      onCompleted(response, errors) {
        config.onCompleted?.(response, errors)

        if (errors && errors.length) {
          reject(errors)
        }

        resolve(response)
      },
      onError(...args) {
        config.onError?.(...args)
        reject(args[0])
      },
    })
  })
}
