import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { ServiceDetailsLoading } from "src/pages/ServiceDetails/components/ServiceDetailsLoading/ServiceDetailsLoading"
import MobilePageQuery, {
  type MobilePageQuery as MobilePageQueryType,
} from "src/pages/ServiceDetails/MobilePage/__generated__/MobilePageQuery.graphql"
import { serviceSummaryTypenameToServiceNameRecord } from "src/pages/ServiceDetails/utils/serviceSummaryTypenameToServiceNameRecord"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/mobile/$id")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context, params }) => {
    return loadQuery<MobilePageQueryType>(
      context.relayEnvironment(),
      MobilePageQuery,
      {
        id: params.id,
      },
    )
  },
  pendingComponent: () => (
    <ServiceDetailsLoading
      title={serviceSummaryTypenameToServiceNameRecord["MobileServiceSummary"]}
    />
  ),
  component: lazyRouteComponent(
    () => import("src/pages/ServiceDetails/MobilePage/MobilePage"),
  ),
})
