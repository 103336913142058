import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import CarInsurancePageQuery, {
  type CarInsurancePageQuery as CarInsurancePageQueryType,
} from "src/pages/ServiceDetails/CarInsurancePage/__generated__/CarInsurancePageQuery.graphql"
import { ServiceDetailsLoading } from "src/pages/ServiceDetails/components/ServiceDetailsLoading/ServiceDetailsLoading"
import { serviceSummaryTypenameToServiceNameRecord } from "src/pages/ServiceDetails/utils/serviceSummaryTypenameToServiceNameRecord"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/car-insurance")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) => {
    return loadQuery<CarInsurancePageQueryType>(
      context.relayEnvironment(),
      CarInsurancePageQuery,
      {},
    )
  },
  pendingComponent: () => (
    <ServiceDetailsLoading
      title={
        serviceSummaryTypenameToServiceNameRecord["CarInsuranceServiceSummary"]
      }
    />
  ),
  component: lazyRouteComponent(
    () => import("src/pages/ServiceDetails/CarInsurancePage/CarInsurancePage"),
  ),
})
