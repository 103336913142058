/**
 * @generated SignedSource<<31fd1e81a9dc9e5c92385563f79e0490>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingConnectEmailStepInitialContent_household$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useIsB2B_household">;
  readonly " $fragmentType": "OnboardingConnectEmailStepInitialContent_household";
};
export type OnboardingConnectEmailStepInitialContent_household$key = {
  readonly " $data"?: OnboardingConnectEmailStepInitialContent_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingConnectEmailStepInitialContent_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingConnectEmailStepInitialContent_household",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsB2B_household"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "c17efada7c5602be595cec53a2b9f90b";

export default node;
