import { useCallback, useState } from "react"

import { useViewport, Viewport } from "./responsive"

export default function useShare() {
  const isMobile = useViewport() < Viewport.MD
  const canShare = navigator.share != null && isMobile
  const [state, setState] = useState({
    method: canShare ? ("share" as const) : ("clipboard" as const),
    isSharing: false,
    isShared: false,
  })

  const share = useCallback(
    async (options: { url: string; title: string; text: string }) => {
      setState((state) => ({ ...state, isSharing: true }))
      if (canShare) {
        await navigator.share({
          title: options.title,
          text: options.text,
          url: options.url,
        })
        setState((state) => ({
          ...state,
          isShared: true,
          isSharing: false,
        }))
        return { method: "share" as const }
      }
      await navigator.clipboard.writeText(`${options.text} ${options.url}`)
      setState((state) => ({
        ...state,
        isShared: true,
        isSharing: false,
      }))
      return { method: "clipboard" as const }
    },
    [setState, canShare],
  )

  return {
    share,
    setShareState: setState,
    ...state,
  }
}
