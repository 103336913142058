import { useFragment, graphql } from "react-relay"

import { handleFutureValueOnRelayEnumOrNull } from "src/utils/handleFutureValueOnRelayEnumOrNull"

import { useIsTypeformUserTask_userTask$key } from "./__generated__/useIsTypeformUserTask_userTask.graphql"

export default function useIsTypeformUserTask(props: {
  userTask: useIsTypeformUserTask_userTask$key | null
}) {
  const userTask = useFragment(
    graphql`
      fragment useIsTypeformUserTask_userTask on IUserTask {
        __typename
        ... on TypeformDataCollectionUserTask {
          formType
        }
      }
    `,
    props.userTask,
  )

  if (!userTask) {
    return {
      isTypeformTask: false,
      typeformFormType: null,
    }
  }

  const isTypeformTask =
    userTask.__typename === "TypeformDataCollectionUserTask"

  return {
    isTypeformTask,
    typeformFormType: isTypeformTask
      ? handleFutureValueOnRelayEnumOrNull(userTask.formType, null)
      : null,
  }
}
