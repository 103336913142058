/**
 * @generated SignedSource<<b861a531b46cffac02c4ebd378faa43b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpgradeToFullAccountInput = {
  displayName?: string | null | undefined;
  email: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  phoneNumber?: string | null | undefined;
};
export type CreateAccountPopupUpgradeToFullAccountMutation$variables = {
  input: UpgradeToFullAccountInput;
};
export type CreateAccountPopupUpgradeToFullAccountMutation$data = {
  readonly upgradeToFullAccount: {
    readonly id: string;
  } | null | undefined;
};
export type CreateAccountPopupUpgradeToFullAccountMutation = {
  response: CreateAccountPopupUpgradeToFullAccountMutation$data;
  variables: CreateAccountPopupUpgradeToFullAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "upgradeToFullAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAccountPopupUpgradeToFullAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAccountPopupUpgradeToFullAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1a5a4be8ce42a4b02a7ad2111579239e",
    "id": null,
    "metadata": {},
    "name": "CreateAccountPopupUpgradeToFullAccountMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAccountPopupUpgradeToFullAccountMutation(\n  $input: UpgradeToFullAccountInput!\n) {\n  upgradeToFullAccount(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "11f2a3382cb691fdc473d45f981efbcd";

export default node;
