import React from "react"

import { clsx } from "clsx"

type MainSize = "md" | "lg" | "xl"
type MainProps = {
  size?: MainSize
  className?: string
  removePadding?: boolean
  children?: React.ReactNode
}

export const Main = ({
  size = "md",
  className,
  children,
  removePadding,
}: MainProps) => {
  return (
    <main
      className={clsx(
        "relative mx-auto w-full pt-6",
        size === "md"
          ? "max-w-[528px]"
          : size === "lg"
            ? "max-w-3xl"
            : "max-w-7xl",
        removePadding ? "px-0 md:px-4" : "px-4",
        className,
      )}
    >
      {children}
    </main>
  )
}
