/**
 * @generated SignedSource<<30d9d1d0faaadda22150b7f9dfa4853d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SessionPageQuery$variables = {
  organizationId: string;
  organizationOnboardingSessionId: string;
  skipOrganization: boolean;
  skipOrganizationOnboardingSession: boolean;
};
export type SessionPageQuery$data = {
  readonly organization?: {
    readonly organization: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"SessionPageHeader_organization">;
    } | null | undefined;
  } | null | undefined;
  readonly organizationOnboardingSession?: {
    readonly id: string;
  } | null | undefined;
};
export type SessionPageQuery = {
  response: SessionPageQuery$data;
  variables: SessionPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationOnboardingSessionId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipOrganization"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipOrganizationOnboardingSession"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "condition": "skipOrganizationOnboardingSession",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "organizationOnboardingSessionId"
        }
      ],
      "concreteType": "OrganizationOnboardingSession",
      "kind": "LinkedField",
      "name": "organizationOnboardingSession",
      "plural": false,
      "selections": [
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionPageQuery",
    "selections": [
      {
        "condition": "skipOrganization",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "OrganizationResponse",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SessionPageHeader_organization"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      (v6/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "SessionPageQuery",
    "selections": [
      {
        "condition": "skipOrganization",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "OrganizationResponse",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      (v6/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c7df477caf88cd2be83aeb7c23bcff16",
    "id": null,
    "metadata": {},
    "name": "SessionPageQuery",
    "operationKind": "query",
    "text": "query SessionPageQuery(\n  $organizationId: ID!\n  $skipOrganization: Boolean!\n  $organizationOnboardingSessionId: ID!\n  $skipOrganizationOnboardingSession: Boolean!\n) {\n  organization(id: $organizationId) @skip(if: $skipOrganization) {\n    organization {\n      id\n      ...SessionPageHeader_organization\n    }\n  }\n  organizationOnboardingSession(id: $organizationOnboardingSessionId) @skip(if: $skipOrganizationOnboardingSession) {\n    id\n  }\n}\n\nfragment SessionPageHeader_organization on Organization {\n  logo\n}\n"
  }
};
})();

(node as any).hash = "8ca4253bddcf00d0f5406ac4efe2173e";

export default node;
