import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import SettingsPageQuery, {
  SettingsPageQuery as SettingsPageQueryType,
} from "src/pages/SettingsPage/__generated__/SettingsPageQuery.graphql"
import { SettingsPageLayout } from "src/pages/SettingsPage/SettingsPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/settings/")({
  beforeLoad: () => {
    requireIsLoggedIn({ redirectTo: "/login" })

    throw redirect({
      to: "/settings/$",
      params: { _splat: "overview" },
      mask: {
        to: "/settings",
      },
    })
  },
  pendingComponent: SettingsPageLayout,
  loader: ({ context }) =>
    loadQuery<SettingsPageQueryType>(
      context.relayEnvironment(),
      SettingsPageQuery,
      {},
    ),

  component: lazyRouteComponent(
    () => import("src/pages/SettingsPage/SettingsPage"),
  ),
})
