import { Fragment } from "react"

import { Dialog, Transition } from "@headlessui/react"
import clsx from "clsx"

import { GlowIcon } from "src/glow"

export const Drawer = ({
  title,
  open,
  onClose,
  children,
  noPadding,
  hideManualClose,
  disableManualClose,
}: {
  title?: string
  open: boolean
  onClose: () => void
  children: React.ReactNode
  noPadding?: boolean
  hideManualClose?: boolean
  disableManualClose?: boolean
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="z-drawer relative" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed bottom-0 right-0 flex w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 md:duration-700"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-500 md:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-full">
                  <div className="flex h-full max-h-[100vh] flex-col overflow-y-scroll rounded-t-2xl bg-white py-4 shadow-xl">
                    <div className="flex justify-center px-4 md:px-6">
                      <Dialog.Title className="ml-auto text-lg font-medium text-gray-900">
                        {title}
                      </Dialog.Title>
                      {!hideManualClose && (
                        <button
                          type="button"
                          className="disabled:text-gray-300/64 ml-auto rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white disabled:cursor-not-allowed"
                          onClick={onClose}
                          disabled={disableManualClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <GlowIcon
                            name="close_regular"
                            className="opacity-64 h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      )}
                    </div>
                    <div
                      className={clsx(
                        "mt-2 flex-1",
                        !noPadding && "px-4 md:px-6",
                      )}
                    >
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
