import { useIntl } from "react-intl"

import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export const QuestsPageLayout = (props: { children?: React.ReactNode }) => {
  const intl = useIntl()
  const title = intl.formatMessage({
    defaultMessage: "To-dos",
    id: "quests.page.header",
  })
  return (
    <GlowPageLayout
      mobileNav={{
        top: (
          <MobileTopNav
            backgroundColour="white"
            variant="secondary"
            title={title}
          />
        ),
      }}
      desktopNav={<DesktopNav />}
    >
      {props.children ?? <LoadingPagePlaceholder />}
    </GlowPageLayout>
  )
}
