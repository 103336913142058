import edgeLeft1 from "src/assets/chat/left-1.svg"
import edgeLeft2 from "src/assets/chat/left-2.svg"
import edgeLeft3 from "src/assets/chat/left-3.svg"
import edgeRight1 from "src/assets/chat/right-1.svg"
import edgeRight2 from "src/assets/chat/right-2.svg"
import edgeRight3 from "src/assets/chat/right-3.svg"
import emily from "src/assets/emilyColour.png"
import { GlowFlexbox } from "src/glow"

type Props = {
  children: React.ReactNode
}

export default function OnboardingEmilyChatBubble(props: Props) {
  return (
    <GlowFlexbox direction="row" alignItems="end" className="w-full">
      <div
        className="h-10 w-10 flex-shrink-0 flex-grow-0 rounded-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("${emily}")`,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          minHeight: 48,
          flexGrow: 1,
        }}
      >
        <div
          style={{
            width: 28,
            display: "flex",
            flexDirection: "column",
            flexGrow: 0,
            flexShrink: 0,
          }}
        >
          <div
            style={{
              backgroundImage: `url("${edgeLeft1}")`,
              height: 10,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
              flexGrow: 0,
              flexShrink: 0,
            }}
          ></div>
          <div
            style={{
              backgroundImage: `url("${edgeLeft2}")`,
              backgroundRepeat: "repeat-y",
              backgroundPosition: "right top",
              flexGrow: 1,
            }}
          ></div>
          <div
            style={{
              backgroundImage: `url("${edgeLeft3}")`,
              height: "22px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
              flexGrow: 0,
              flexShrink: 0,
            }}
          ></div>
        </div>
        <div
          style={{
            flexGrow: 1,
            backgroundColor: "#FCB747",
            paddingTop: 12,
            paddingBottom: 12,
          }}
        >
          {props.children}
        </div>
        <div
          style={{
            width: 20,
            flexGrow: 0,
            flexShrink: 0,
            display: "flex",
            flexDirection: "column",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
          }}
        >
          <div
            style={{
              backgroundImage: `url("${edgeRight1}")`,
              height: 16,
              flexGrow: 0,
              flexShrink: 0,
            }}
          ></div>
          <div
            style={{
              backgroundImage: `url("${edgeRight2}")`,
              flexGrow: 1,
              backgroundRepeat: "repeat-y",
              backgroundPosition: "right top",
            }}
          ></div>
          <div
            style={{
              backgroundImage: `url("${edgeRight3}")`,
              height: 16,
              flexGrow: 0,
              flexShrink: 0,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
            }}
          ></div>
        </div>
      </div>
    </GlowFlexbox>
  )
}
