import { useRouter } from "@tanstack/react-router"
import { useIntl } from "react-intl"

import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import useTracking from "src/tracking/useTracking"

export const SettingsPageLayout = (props: {
  children?: React.ReactNode
  title?: string
}) => {
  const intl = useIntl()
  const router = useRouter()
  const track = useTracking()

  return (
    <GlowPageLayout
      mobileNav={{
        top: (
          <MobileTopNav
            variant="secondary"
            backgroundColour="white"
            title={
              props.title ??
              intl.formatMessage({
                id: "page.settings.mobileNav.title.default",
                defaultMessage: "Settings",
              })
            }
            showBack
            onBack={() => {
              track(["Settings", "Opened", { section: "overview" }])
              router.history.back()
            }}
          />
        ),
        bottom: null,
      }}
      desktopNav={<DesktopNav />}
    >
      {props.children}
    </GlowPageLayout>
  )
}
