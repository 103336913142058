import React from "react"

import clsx from "clsx"

import { GlowFlexbox } from "./GlowFlexbox"

export type GlowLayoutCenteredVariant = "narrow" | "wide" | "ultra-wide"

type CenteredProps = {
  children: React.ReactNode
  className?: string
  variant?: GlowLayoutCenteredVariant
}

const centeredVariantToClassName: Record<
  GlowLayoutCenteredVariant,
  `max-w-[${number}px]`
> = {
  narrow: "max-w-[528px]",
  wide: "max-w-[760px]",
  "ultra-wide": "max-w-[960px]",
}

export const GlowLayoutCentered = React.forwardRef<
  HTMLDivElement,
  CenteredProps
>(({ children, className, variant = "narrow" }, ref) => {
  return (
    <GlowFlexbox
      direction="column"
      alignItems="center"
      className={clsx("h-full", className)}
      innerRef={ref}
    >
      <div
        className={clsx("w-full grow-0", centeredVariantToClassName[variant])}
      >
        {children}
      </div>
    </GlowFlexbox>
  )
})

type TwoColumnProps = {
  left: React.ReactNode
  right: React.ReactNode
  className?: string
  variant?: "narrow" | "wide"
}

const twoColumnVariantToClassName = {
  narrow: "max-w-[528px]",
  wide: "max-w-7xl",
}

export function GlowLayoutTwoColumn({
  left,
  right,
  className,
  variant = "wide",
}: TwoColumnProps) {
  return (
    <div
      className={clsx(
        "relative mx-auto grid w-full grid-cols-[1fr_1fr_minmax(320px,_1fr)] gap-x-12 gap-y-4",
        twoColumnVariantToClassName[variant],
        className,
      )}
    >
      <div className="col-span-3 h-full w-full md:col-span-2">{left}</div>
      <div className="col-span-3 h-full w-full md:col-span-1">{right}</div>
    </div>
  )
}
