import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import useBudget2024AutumnModulesQuery, {
  useBudget2024AutumnModulesQuery as useBudget2024AutumnModulesQueryType,
} from "src/tools/Budget/Budget2024Autumn/__generated__/useBudget2024AutumnModulesQuery.graphql"

export const Route = createFileRoute("/widgets/budget/2024/autumn")({
  loader: ({ context }) =>
    loadQuery<useBudget2024AutumnModulesQueryType>(
      context.relayEnvironment(),
      useBudget2024AutumnModulesQuery,
      {},
    ),
  component: lazyRouteComponent(
    () => import("src/pages/Widgets/Budget2024AutumnPage/Budget2024AutumnPage"),
  ),
})
