import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import NotificationsPageQuery, {
  NotificationsPageQuery as NotificationsPageQueryType,
} from "src/pages/NotificationsPage/__generated__/NotificationsPageQuery.graphql"
import NotificationsLayout from "src/pages/NotificationsPage/components/NotificationsLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/notifications")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  pendingComponent: NotificationsLayout,
  loader: ({ context }) =>
    loadQuery<NotificationsPageQueryType>(
      context.relayEnvironment(),
      NotificationsPageQuery,
      {},
    ),
  component: lazyRouteComponent(
    () => import("src/pages/NotificationsPage/NotificationsPage"),
  ),
})
