/**
 * @generated SignedSource<<9015e97053fc5180898592cce339e469>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateHouseholdMemberInviteInput = {
  customMessage?: string | null | undefined;
  email: string;
  firstName?: string | null | undefined;
  id: string;
  lastName?: string | null | undefined;
};
export type InviteFormSendInviteMutation$variables = {
  input: CreateHouseholdMemberInviteInput;
};
export type InviteFormSendInviteMutation$data = {
  readonly sendHouseholdInviteV2: {
    readonly household: {
      readonly pendingInvites: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"InviteModalContent_invites" | "InviteModalPendingInvite_invite">;
      }>;
    } | null | undefined;
  } | null | undefined;
};
export type InviteFormSendInviteMutation = {
  response: InviteFormSendInviteMutation$data;
  variables: InviteFormSendInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteFormSendInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendHouseholdInviteV2Response",
        "kind": "LinkedField",
        "name": "sendHouseholdInviteV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Household",
            "kind": "LinkedField",
            "name": "household",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HouseholdMemberInvite",
                "kind": "LinkedField",
                "name": "pendingInvites",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InviteModalPendingInvite_invite"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InviteModalContent_invites"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteFormSendInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendHouseholdInviteV2Response",
        "kind": "LinkedField",
        "name": "sendHouseholdInviteV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Household",
            "kind": "LinkedField",
            "name": "household",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HouseholdMemberInvite",
                "kind": "LinkedField",
                "name": "pendingInvites",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "initials",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "786d3caa00d66b0598250a64e0271962",
    "id": null,
    "metadata": {},
    "name": "InviteFormSendInviteMutation",
    "operationKind": "mutation",
    "text": "mutation InviteFormSendInviteMutation(\n  $input: CreateHouseholdMemberInviteInput!\n) {\n  sendHouseholdInviteV2(input: $input) {\n    household {\n      pendingInvites {\n        id\n        ...InviteModalPendingInvite_invite\n        ...InviteModalContent_invites\n      }\n      id\n    }\n  }\n}\n\nfragment Avatar_invite on HouseholdMemberInvite {\n  initials\n  firstName\n}\n\nfragment InviteModalContent_invites on HouseholdMemberInvite {\n  id\n  ...InviteModalPendingInvite_invite\n}\n\nfragment InviteModalPendingInvite_invite on HouseholdMemberInvite {\n  ...Avatar_invite\n  displayName\n  email\n}\n"
  }
};
})();

(node as any).hash = "3649ac0ec553704fa52d6414d1920211";

export default node;
