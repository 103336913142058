/**
 * @generated SignedSource<<3afdbfcbb01be328bd536297f88ee3a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageSubPageMembers_household$data = {
  readonly members: ReadonlyArray<{
    readonly id: string;
    readonly user: {
      readonly email: string | null | undefined;
      readonly firstName: string | null | undefined;
      readonly id: string;
      readonly lastName: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"Avatar_user">;
    } | null | undefined;
  }>;
  readonly pendingInvites: ReadonlyArray<{
    readonly email: string | null | undefined;
    readonly firstName: string | null | undefined;
    readonly id: string;
    readonly lastName: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_invite" | "InviteModalPendingInvite_invite">;
  }>;
  readonly " $fragmentType": "SettingsPageSubPageMembers_household";
};
export type SettingsPageSubPageMembers_household$key = {
  readonly " $data"?: SettingsPageSubPageMembers_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageSubPageMembers_household">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageSubPageMembers_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdMember",
      "kind": "LinkedField",
      "name": "members",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Avatar_user"
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v0/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdMemberInvite",
      "kind": "LinkedField",
      "name": "pendingInvites",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_invite"
        },
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InviteModalPendingInvite_invite"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};
})();

(node as any).hash = "811bb6dc8100c1ddb0bafbf3fc329c07";

export default node;
