import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { ServiceDetailsLoading } from "src/pages/ServiceDetails/components/ServiceDetailsLoading/ServiceDetailsLoading"
import HomeInsurancePageQuery, {
  type HomeInsurancePageQuery as HomeInsurancePageQueryType,
} from "src/pages/ServiceDetails/HomeInsurancePage/__generated__/HomeInsurancePageQuery.graphql"
import { serviceSummaryTypenameToServiceNameRecord } from "src/pages/ServiceDetails/utils/serviceSummaryTypenameToServiceNameRecord"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/home-insurance")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) => {
    return loadQuery<HomeInsurancePageQueryType>(
      context.relayEnvironment(),
      HomeInsurancePageQuery,
      {},
    )
  },
  pendingComponent: () => (
    <ServiceDetailsLoading
      title={
        serviceSummaryTypenameToServiceNameRecord["HomeInsuranceServiceSummary"]
      }
    />
  ),
  component: lazyRouteComponent(
    () =>
      import("src/pages/ServiceDetails/HomeInsurancePage/HomeInsurancePage"),
  ),
})
