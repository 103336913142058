import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google"
import { FormattedMessage, useIntl } from "react-intl"

import { GlowButton, GlowFlexbox, GlowText } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import { useGoogleSSO } from "src/hooks/google"
import useEffectAfterRouterTransition from "src/hooks/useEffectAfterRouterTransition"
import useTracking from "src/tracking/useTracking"

import checkboxAlert from "./assets/checkboxAlert.png"
import checkboxAlert2x from "./assets/checkboxAlert@2x.png"
import checkboxAlert3x from "./assets/checkboxAlert@3x.png"

type Props = {
  isConnecting?: boolean
  onConnect: (authorizationCode: string) => void
  isOpen: boolean
  onClose: () => void
  onError?: () => void
}

/**
 * A component used to connect with a Gmail inbox.
 */

export default function ConnectWithGmailPopup(props: Props) {
  const clientId = __GOOGLE_CLIENT_ID__
  if (!clientId) {
    throw new Error("GOOGLE_CLIENT_ID is not defined")
  }
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <InnerConnectWithGmailPopup {...props} />
    </GoogleOAuthProvider>
  )
}

function InnerConnectWithGmailPopup(props: Props) {
  const { redirectUri, encodeState } = useGoogleSSO()
  const track = useTracking()
  useEffectAfterRouterTransition(() => track(["EmailConnection", "Offered"]))

  const state = encodeState()
  const uxMode = "popup"
  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      const { code } = response
      props.onConnect(code)
    },
    onError: (_error) => {
      props.onError?.()
    },
    flow: "auth-code",
    ux_mode: uxMode,
    redirect_uri: redirectUri,
    state,
    select_account: true,
    scope: "https://www.googleapis.com/auth/gmail.readonly",
  })

  const intl = useIntl()
  return (
    <GlowModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={intl.formatMessage({
        defaultMessage: "Connecting with Gmail...",
        id: "connectWithGmailPopup.title",
      })}
      level="1"
      noPadding
      modalClassName="md:h-[620px]"
    >
      <GlowFlexbox
        direction="column"
        gap="4"
        alignItems="center"
        className="w-full p-6 md:min-w-[528px]"
      >
        <GlowText textAlign="center">
          <FormattedMessage
            id="connectWithGmailPopup.description"
            defaultMessage="When you're asked, please give Nous permission to automatically find your providers by selecting the checkbox before clicking 'Continue' as shown below:"
          />
        </GlowText>
        <img
          srcSet={`${checkboxAlert}, ${checkboxAlert2x} 2x, ${checkboxAlert3x} 3x`}
          alt=""
          className="short:w-[200px] w-[312px]"
        />
        <GlowButton
          label={
            props.isConnecting
              ? intl.formatMessage({
                  defaultMessage: "Connecting...",
                  id: "connectWithGmailPopup.connectingCTALabel",
                })
              : intl.formatMessage({
                  defaultMessage: "Connect now",
                  id: "connectWithGmailPopup.connectNowCTALabel",
                })
          }
          onClick={() => {
            track(["EmailConnection", "Attempted", { emailProvider: "Google" }])
            googleLogin()
          }}
          className="w-full"
          isDisabled={props.isConnecting}
        />
      </GlowFlexbox>
    </GlowModal>
  )
}
