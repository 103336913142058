import { useRouterState } from "@tanstack/react-router"
import { useIntl } from "react-intl"

import { useAuthActions } from "src/auth"
import { GlowLink } from "src/glow"
import useTracking from "src/tracking/useTracking_LEGACY"

export const Logout = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  })
  const { logOut } = useAuthActions()
  const intl = useIntl()
  const track = useTracking()
  return (
    <GlowLink
      onClick={() => {
        track("Clicked logout", {
          pathname,
          journey: "Profile",
        })
        logOut()
      }}
      className="text-angsty-red-500"
      label={intl.formatMessage({
        id: "yourHouseholdPage.logout",
        defaultMessage: "Logout",
      })}
    />
  )
}
