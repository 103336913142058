/**
 * @generated SignedSource<<b5215f0e836b1e14e82312362b25de99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteModalPendingInvite_invite$data = {
  readonly displayName: string | null | undefined;
  readonly email: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_invite">;
  readonly " $fragmentType": "InviteModalPendingInvite_invite";
};
export type InviteModalPendingInvite_invite$key = {
  readonly " $data"?: InviteModalPendingInvite_invite$data;
  readonly " $fragmentSpreads": FragmentRefs<"InviteModalPendingInvite_invite">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InviteModalPendingInvite_invite",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_invite"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "HouseholdMemberInvite",
  "abstractKey": null
};

(node as any).hash = "c617d886ed7f4e756c56170e7ab02f04";

export default node;
