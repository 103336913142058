/**
 * @generated SignedSource<<7d31aedbeee2a8a71e3d22d6fe8f9ac5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationNavQuery$variables = Record<PropertyKey, never>;
export type NotificationNavQuery$data = {
  readonly household: {
    readonly " $fragmentSpreads": FragmentRefs<"NotificationNav_household">;
  } | null | undefined;
};
export type NotificationNavQuery = {
  response: NotificationNavQuery$data;
  variables: NotificationNavQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationNavQuery",
    "selections": [
      {
        "alias": "household",
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "maybeHousehold",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationNav_household"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationNavQuery",
    "selections": [
      {
        "alias": "household",
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "maybeHousehold",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 0
              },
              {
                "kind": "Literal",
                "name": "read",
                "value": false
              }
            ],
            "concreteType": "HouseholdUserNotificationConnection",
            "kind": "LinkedField",
            "name": "userNotifications",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": "userNotifications(first:0,read:false)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "97d33dd9950f1469b4e5dafa0c837723",
    "id": null,
    "metadata": {},
    "name": "NotificationNavQuery",
    "operationKind": "query",
    "text": "query NotificationNavQuery {\n  household: maybeHousehold {\n    ...NotificationNav_household\n    id\n  }\n}\n\nfragment NotificationNav_household on Household {\n  userNotifications(first: 0, read: false) {\n    totalCount\n  }\n}\n"
  }
};

(node as any).hash = "25c7156820aac2614683bf7cc6be7ebe";

export default node;
