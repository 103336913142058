import clsx from "clsx"

type GlowToggleProps = {
  isChecked?: boolean
  onToggle: (checked: boolean) => void
  isDisabled?: boolean
} & (
  | { label: string; labelClassName?: string }
  | { label?: undefined; labelClassName?: undefined }
) &
  React.ComponentProps<"label">

export function GlowToggle({
  label,
  isChecked,
  onToggle,
  isDisabled,
  labelClassName,
  ...props
}: GlowToggleProps) {
  return (
    <label className="relative inline-flex items-center" {...props}>
      <input
        type="checkbox"
        checked={isChecked}
        className="peer/checkbox sr-only"
        onChange={(e) => onToggle(e.target.checked)}
        disabled={isDisabled}
      />
      <span
        className={clsx(
          "h-8 w-14",
          "cursor-pointer rounded-full",
          "peer-checked/checkbox:bg-virtuous-green-200 bg-gray-200",
          "peer-checked/checkbox:hover:bg-virtuous-green-400 hover:bg-gray-300",
          "peer-focus/checkbox:outline-serene-blue-400 peer-focus/checkbox:outline-2 peer-focus/checkbox:outline-offset-0 peer-focus/checkbox:ring-0",

          `text-white before:absolute before:bottom-1 before:left-1 before:h-6 before:w-6 before:rounded-full before:bg-black before:text-center before:duration-300 before:content-['✗'] peer-checked/checkbox:before:translate-x-6 peer-checked/checkbox:before:content-['✓']`,

          "peer-disabled/checkbox:before:bg-black/32 peer-disabled/checkbox:cursor-default peer-disabled/checkbox:bg-gray-100 peer-disabled/checkbox:hover:bg-gray-100",
        )}
      ></span>
      {label && (
        <span
          className={clsx(
            "ml-2 peer-enabled/checkbox:cursor-pointer",
            labelClassName,
          )}
        >
          {label}
        </span>
      )}
    </label>
  )
}
