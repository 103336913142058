/**
 * @generated SignedSource<<fb2777d003f4e1e29e94ccee69a11049>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BankAccountAddOrEditPopup_bankAccount$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BankAccountDetailsGlowForm_bankAccount">;
  readonly " $fragmentType": "BankAccountAddOrEditPopup_bankAccount";
};
export type BankAccountAddOrEditPopup_bankAccount$key = {
  readonly " $data"?: BankAccountAddOrEditPopup_bankAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"BankAccountAddOrEditPopup_bankAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BankAccountAddOrEditPopup_bankAccount",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BankAccountDetailsGlowForm_bankAccount"
    }
  ],
  "type": "BankAccount",
  "abstractKey": null
};

(node as any).hash = "45e43058e25a3fe1e0fbd7a8bc339dfe";

export default node;
