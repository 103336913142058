import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import ReferralsPageQuery, {
  ReferralsPageQuery as ReferralsPageQueryType,
} from "src/pages/ReferralsPage/__generated__/ReferralsPageQuery.graphql"
import ReferralsPageLayout from "src/pages/ReferralsPage/components/ReferralsPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/referrals")({
  beforeLoad: ({ context }) => {
    requireIsLoggedIn({ redirectTo: "/login" })

    if (!context.checkGate("referrals_page")) {
      throw redirect({ to: "/" })
    }
  },
  pendingComponent: ReferralsPageLayout,
  loader: ({ context }) =>
    loadQuery<ReferralsPageQueryType>(
      context.relayEnvironment(),
      ReferralsPageQuery,
      {},
    ),
  component: lazyRouteComponent(
    () => import("src/pages/ReferralsPage/ReferralsPage"),
  ),
})
