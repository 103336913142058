import { useEffect } from "react"

import useQueryParameters from "./useQueryParameters"

const MICROSOFT_AUTHORIZATION_URL =
  "https://login.microsoftonline.com/common/oauth2/v2.0/authorize"
const MICROSOFT_OUTLOOK_REDIRECT_URI_STATE_FLAG =
  "hasComeFromMicrosoftAuthorize"

export type MicrosoftConnectionCallback = (config: {
  code: string
  redirectUri: string
}) => void

const SSO_EVENT_TYPE = "microsoft-outlook-sso"

export function useMicrosoftOutlookCatcher() {
  const params = useQueryParameters()
  useEffect(() => {
    if (params.get("state") === MICROSOFT_OUTLOOK_REDIRECT_URI_STATE_FLAG) {
      const code = params.get("code")
      if (!code) {
        return
      }
      window.opener.postMessage({
        type: SSO_EVENT_TYPE,
        code,
      })
      window.close()
    }
  }, [params])
}

export default function useMicrosoftOutlook(
  options: {
    onConnect?: MicrosoftConnectionCallback
  } = {},
): {
  navigateToMicrosoftAuthorizationPage: () => void
} {
  const clientId = __MICROSOFT_CLIENT_ID__
  if (!clientId) {
    throw new Error("MICROSOFT_CLIENT_ID is not defined")
  }

  const redirectUri = new URL("/outlook-sso", window.location.origin).toString()
  const navigateToMicrosoftAuthorizationPage = () => {
    const microsoftAuthorizeParams = new URLSearchParams({
      client_id: clientId,
      response_type: "code",
      redirect_uri: redirectUri,
      response_mode: "query",
      scope: "offline_access mail.read user.read",
      state: MICROSOFT_OUTLOOK_REDIRECT_URI_STATE_FLAG,
    })

    const authorizeUrl = new URL(
      `${MICROSOFT_AUTHORIZATION_URL}?${microsoftAuthorizeParams.toString()}`,
    )

    window.open(authorizeUrl, "_blank", "popup=true")
  }
  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return
      }
      if (event.data.type !== SSO_EVENT_TYPE) {
        return
      }
      const { code } = event.data
      if (!code) {
        return
      }
      const onConnectFn = options.onConnect
      onConnectFn?.({
        code,
        redirectUri,
      })
    }
    window.addEventListener("message", listener, false)
    return () => {
      window.removeEventListener("message", listener, false)
    }
  }, [options.onConnect, redirectUri])

  return {
    navigateToMicrosoftAuthorizationPage,
  }
}
