import { useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { titleMap } from "src/pages/SettingsPage/utils"

import { SettingsPageOverviewBankAccounts_household$key } from "./__generated__/SettingsPageOverviewBankAccounts_household.graphql"
import { SettingsPageOverviewGenericSection } from "./SettingsPageOverviewGenericSection"

export const SettingsPageOverviewBankAccounts = (props: {
  household: SettingsPageOverviewBankAccounts_household$key
  onGoToBankAccountsView: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverviewBankAccounts_household on Household {
        bankAccounts {
          id
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  return (
    <SettingsPageOverviewGenericSection
      icon={"saving_bank_1_regular"}
      title={intl.formatMessage(titleMap["bank-accounts"])}
      value={household.bankAccounts.length}
      subtitle={intl.formatMessage({
        id: "page.settings.view.overview.bankAccountsActionDescription",
        defaultMessage:
          "Add bank accounts so we can set up new providers for you",
      })}
      onClick={props.onGoToBankAccountsView}
    />
  )
}
