/**
 * @generated SignedSource<<a72592945256e780283289bdceeee38c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingConnectEmailStepInitialContent_formAnswer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingSkipReasonModal_formAnswer">;
  readonly " $fragmentType": "OnboardingConnectEmailStepInitialContent_formAnswer";
};
export type OnboardingConnectEmailStepInitialContent_formAnswer$key = {
  readonly " $data"?: OnboardingConnectEmailStepInitialContent_formAnswer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingConnectEmailStepInitialContent_formAnswer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingConnectEmailStepInitialContent_formAnswer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingSkipReasonModal_formAnswer"
    }
  ],
  "type": "FormAnswer",
  "abstractKey": null
};

(node as any).hash = "33db6fd36622e217ec47e619b8ff7feb";

export default node;
