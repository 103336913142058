export function timeoutPromise<T>({
  promise,
  timeout = 5000,
  onTimeout,
}: {
  promise: Promise<T>
  timeout?: number
  onTimeout?: () => void
}): Promise<T | null> {
  let resolved = false

  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      if (!resolved) {
        resolved = true
        onTimeout?.()
        resolve(null)
      }
    }, timeout)

    promise.then(
      (value) => {
        if (!resolved) {
          resolved = true
          clearTimeout(timeoutId)
          resolve(value)
        }
      },
      (error) => {
        if (!resolved) {
          resolved = true
          clearTimeout(timeoutId)
          reject(error)
        }
      },
    )
  })
}
