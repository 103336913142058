/**
 * @generated SignedSource<<fc71d641638e994ca9d13ac404195c7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MobileNavLinkTasksIconQuery$variables = Record<PropertyKey, never>;
export type MobileNavLinkTasksIconQuery$data = {
  readonly household: {
    readonly allPriorityTasks: {
      readonly totalCount: number;
    } | null | undefined;
    readonly urgentPriorityTasks: {
      readonly totalCount: number;
    } | null | undefined;
  };
};
export type MobileNavLinkTasksIconQuery = {
  response: MobileNavLinkTasksIconQuery$data;
  variables: MobileNavLinkTasksIconQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "completed",
  "value": false
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = {
  "alias": "urgentPriorityTasks",
  "args": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "Literal",
      "name": "priority",
      "value": "URGENT"
    }
  ],
  "concreteType": "HouseholdUserTaskConnection",
  "kind": "LinkedField",
  "name": "userTasks",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "userTasks(completed:false,first:0,priority:\"URGENT\")"
},
v4 = {
  "alias": "allPriorityTasks",
  "args": [
    (v0/*: any*/),
    (v1/*: any*/)
  ],
  "concreteType": "HouseholdUserTaskConnection",
  "kind": "LinkedField",
  "name": "userTasks",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "userTasks(completed:false,first:0)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MobileNavLinkTasksIconQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MobileNavLinkTasksIconQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ecd777a7b4f3c4e8209741822ff63e45",
    "id": null,
    "metadata": {},
    "name": "MobileNavLinkTasksIconQuery",
    "operationKind": "query",
    "text": "query MobileNavLinkTasksIconQuery {\n  household {\n    urgentPriorityTasks: userTasks(priority: URGENT, completed: false, first: 0) {\n      totalCount\n    }\n    allPriorityTasks: userTasks(first: 0, completed: false) {\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd1972be1f33055224f27ca0f2b1ba6b";

export default node;
