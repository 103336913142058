import clsx from "clsx"

import { Footer } from "src/components/Footer/Footer"
import { MobileBottomNav } from "src/components/Nav/MobileBottomNav"
import { useViewport, Viewport } from "src/hooks/responsive"

import { GlowFlexbox } from "./GlowFlexbox"

type GlowPageLayoutProps = {
  mobileNav: {
    top: React.ReactNode
    bottom?: React.ReactNode | null
  }
  desktopNav?: React.ReactNode
  children?: React.ReactNode
  hideFooter?: boolean
  className?: string
}

export const GlowPageLayout = ({
  children,
  mobileNav,
  desktopNav,
  hideFooter,
  className,
}: GlowPageLayoutProps) => {
  const viewport = useViewport()
  const isMobile = viewport <= Viewport.SM
  const topNav = isMobile ? mobileNav.top : desktopNav
  const bottomNav =
    isMobile &&
    (mobileNav.bottom !== undefined ? (
      <div>{mobileNav.bottom}</div>
    ) : (
      <MobileBottomNav />
    ))

  return (
    <GlowFlexbox direction="column" className={clsx(className, "h-full")}>
      <div>{topNav}</div>

      <div className="flex-grow pb-8">{children}</div>

      {!hideFooter && (
        <Footer>
          <Footer.Legal />
        </Footer>
      )}

      {bottomNav}
    </GlowFlexbox>
  )
}
