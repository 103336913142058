/**
 * @generated SignedSource<<f0fe11914bb474e28996930afd56a226>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageOverviewMembership_household$data = {
  readonly currentMember: {
    readonly joinedHousehold: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SettingsPageOverviewMembership_household";
};
export type SettingsPageOverviewMembership_household$key = {
  readonly " $data"?: SettingsPageOverviewMembership_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageOverviewMembership_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageOverviewMembership_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdMember",
      "kind": "LinkedField",
      "name": "currentMember",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "joinedHousehold",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "b26b471f1bc823543b034aa7a01d876b";

export default node;
