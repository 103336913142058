/**
 * @generated SignedSource<<88bf45e728a7a696fd44f87bce11fce2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailConnectionManagerDisconnectEmailConnectionMutation$variables = {
  id: string;
};
export type EmailConnectionManagerDisconnectEmailConnectionMutation$data = {
  readonly deleteEmailConnection: {
    readonly household: {
      readonly " $fragmentSpreads": FragmentRefs<"DocumentsPageEmailConnectionsList_household">;
    } | null | undefined;
  } | null | undefined;
};
export type EmailConnectionManagerDisconnectEmailConnectionMutation = {
  response: EmailConnectionManagerDisconnectEmailConnectionMutation$data;
  variables: EmailConnectionManagerDisconnectEmailConnectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailConnectionManagerDisconnectEmailConnectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteEmailConnectionResponse",
        "kind": "LinkedField",
        "name": "deleteEmailConnection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Household",
            "kind": "LinkedField",
            "name": "household",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DocumentsPageEmailConnectionsList_household"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailConnectionManagerDisconnectEmailConnectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteEmailConnectionResponse",
        "kind": "LinkedField",
        "name": "deleteEmailConnection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Household",
            "kind": "LinkedField",
            "name": "household",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EmailConnection",
                "kind": "LinkedField",
                "name": "emailConnections",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "source",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scanStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f89a3e992cf144da3720b9949480b1df",
    "id": null,
    "metadata": {},
    "name": "EmailConnectionManagerDisconnectEmailConnectionMutation",
    "operationKind": "mutation",
    "text": "mutation EmailConnectionManagerDisconnectEmailConnectionMutation(\n  $id: ID!\n) {\n  deleteEmailConnection(id: $id) {\n    household {\n      ...DocumentsPageEmailConnectionsList_household\n      id\n    }\n  }\n}\n\nfragment DocumentsPageEmailConnectionsListItemEmail_emailConnection on EmailConnection {\n  source\n  email\n}\n\nfragment DocumentsPageEmailConnectionsListItemStatus_emailConnection on EmailConnection {\n  id\n  scanStatus\n  ...EmailConnectionScanStatusIndicator_emailConnection\n}\n\nfragment DocumentsPageEmailConnectionsListItem_emailConnection on EmailConnection {\n  id\n  source\n  scanStatus\n  ...DocumentsPageEmailConnectionsListItemEmail_emailConnection\n  ...DocumentsPageEmailConnectionsListItemStatus_emailConnection\n}\n\nfragment DocumentsPageEmailConnectionsList_household on Household {\n  emailConnections {\n    id\n    ...DocumentsPageEmailConnectionsListItem_emailConnection\n  }\n}\n\nfragment EmailConnectionScanStatusIndicator_emailConnection on EmailConnection {\n  id\n  scanStatus\n}\n"
  }
};
})();

(node as any).hash = "950a6d0b29d9619d18480a478bacad39";

export default node;
