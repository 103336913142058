import { MessageDescriptor, defineMessage } from "react-intl"

import { NousServiceLevel } from "src/hooks/__generated__/useRedirectToWaitlist_household.graphql"
import { CheckedRelayEnum } from "src/types"

export const serviceLevelNameMap: Record<
  CheckedRelayEnum<NousServiceLevel>,
  MessageDescriptor
> = {
  DO_EVERYTHING_MYSELF: defineMessage({
    id: "utils.serviceLevelName.DO_EVERYTHING_MYSELF",
    defaultMessage: "MANUAL",
  }),
  DO_MOST_OF_IT_FOR_ME: defineMessage({
    id: "utils.serviceLevelName.DO_MOST_OF_IT_FOR_ME",
    defaultMessage: "BALANCED",
  }),
  DO_ALL_OF_IT_FOR_ME: defineMessage({
    id: "utils.serviceLevelName.DO_ALL_OF_IT_FOR_ME",
    defaultMessage: "FREEDOM",
  }),
}
