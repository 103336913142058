import { FormattedMessage, useIntl } from "react-intl"

import { Main } from "src/components/Main/Main"
import { GlowFlexbox, GlowIcon, GlowLink, GlowText } from "src/glow"

const MaintenancePage = () => {
  const intl = useIntl()

  return (
    <Main size="xl" className="h-full bg-white md:bg-gray-100">
      <GlowFlexbox justifyContent="center" className="h-full">
        <GlowIcon
          name="nous_logo"
          className="absolute top-6 h-5 w-20 md:left-6"
        />

        <GlowFlexbox
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="6"
          className="w-full px-6"
        >
          <img src="/images/500.png" alt="" className="max-h-48 md:w-auto" />
          <GlowFlexbox
            direction="column"
            gap="2"
            alignItems="center"
            className="max-w-lg"
          >
            <GlowText size="2xl" fontWeight="medium" as="h2" textAlign="center">
              <FormattedMessage
                id="maintenancePage.title"
                defaultMessage="We're undergoing planned maintenance"
              />
            </GlowText>
            <GlowText size="base" as="p" textAlign="center">
              <FormattedMessage
                id="maintenancePage.subtitle"
                defaultMessage="Sorry for any inconvenience caused - we'll be back soon!"
              />
            </GlowText>
            <GlowText size="base" as="p" textAlign="center">
              <FormattedMessage
                id="maintenancePage.goToHomepage"
                defaultMessage="Go to our <a>homepage</a>"
                values={{
                  a: (chunk) => (
                    <GlowLink
                      label={chunk}
                      href={intl.formatMessage({
                        id: "maintenancePage.goToHomepage.linkTo",
                        defaultMessage: "https://www.nous.co/",
                      })}
                      className="inline-flex"
                      size="base"
                    />
                  ),
                }}
              />
            </GlowText>
          </GlowFlexbox>
        </GlowFlexbox>
      </GlowFlexbox>
    </Main>
  )
}

export default MaintenancePage
