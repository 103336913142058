import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { ServiceDetailsLoading } from "src/pages/ServiceDetails/components/ServiceDetailsLoading/ServiceDetailsLoading"
import EnergyPageQuery, {
  type EnergyPageQuery as EnergyPageQueryType,
} from "src/pages/ServiceDetails/EnergyPage/__generated__/EnergyPageQuery.graphql"
import { serviceSummaryTypenameToServiceNameRecord } from "src/pages/ServiceDetails/utils/serviceSummaryTypenameToServiceNameRecord"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/energy/$id")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context, params }) =>
    loadQuery<EnergyPageQueryType>(
      context.relayEnvironment(),
      EnergyPageQuery,
      {
        id: params.id,
      },
    ),
  pendingComponent: () => (
    <ServiceDetailsLoading
      title={serviceSummaryTypenameToServiceNameRecord["EnergyServiceSummary"]}
    />
  ),
  component: lazyRouteComponent(
    () => import("src/pages/ServiceDetails/EnergyPage/EnergyPage"),
  ),
})
