import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"

import { GlowCard, GlowFlexbox, GlowIcon, GlowLink, GlowText } from "src/glow"
import useTracking from "src/tracking/useTracking_LEGACY"

export const PrivacyCard = ({ className }: { className?: string }) => {
  const intl = useIntl()
  const track = useTracking()

  const privacyUrl = intl.formatMessage({
    defaultMessage: "https://www.nous.co/privacy-statement",
    id: "privacyCard.privacyUrl",
  })

  return (
    <GlowCard colour="transparent" className={clsx("space-y-4", className)}>
      <GlowFlexbox gap="2">
        <GlowIcon name="lock_3_bold" className="h-6 w-6" />
        <GlowText as="h4" size="base" fontWeight="bold">
          <FormattedMessage
            id="privacyCard.title"
            defaultMessage="Your privacy comes first"
          />
        </GlowText>
      </GlowFlexbox>
      <GlowText as="p" size="sm">
        <FormattedMessage
          id="privacyCard.description"
          defaultMessage="We’re asking you to share some information about you and your household. Read our <a>privacy statement</a> on how we protect your privacy - we hope you’ll find it simple, informative and fair."
          values={{
            a: (chunks) => (
              <GlowLink
                target="_blank"
                href={privacyUrl}
                rel="noreferrer"
                label={chunks}
                size="sm"
                style={{ display: "inline" }}
                onClick={() => {
                  track("Clicked privacy statement")
                }}
              />
            ),
          }}
        />
      </GlowText>
    </GlowCard>
  )
}
