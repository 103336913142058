/**
 * @generated SignedSource<<9d1091f9e8623d9c0b1db063a70b1944>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DocumentCategoryEnum = "BROADBAND" | "CAR_INSURANCE" | "COUNCIL_TAX" | "ENERGY" | "HOME_INSURANCE" | "MOBILE" | "MORTGAGE" | "OTHER" | "PET_INSURANCE" | "SUBSCRIPTIONS" | "TELEVISION" | "WATER" | "%future added value";
export type DocumentTypeEnum = "BILL" | "CONTRACT" | "INSURANCE_AGREEMENT" | "MISC" | "OTHER" | "POLICY_SCHEDULE" | "RENEWAL_NOTICE" | "%future added value";
export type ValidDocumentUploadLinkProviderQuery$variables = {
  id: string;
};
export type ValidDocumentUploadLinkProviderQuery$data = {
  readonly documentUploadLink: {
    readonly category: {
      readonly value: DocumentCategoryEnum | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly type: {
      readonly value: DocumentTypeEnum | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ValidDocumentUploadLinkProviderQuery = {
  response: ValidDocumentUploadLinkProviderQuery$data;
  variables: ValidDocumentUploadLinkProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DocumentUploadLink",
    "kind": "LinkedField",
    "name": "documentUploadLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentCategory",
        "kind": "LinkedField",
        "name": "category",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentType",
        "kind": "LinkedField",
        "name": "type",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidDocumentUploadLinkProviderQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValidDocumentUploadLinkProviderQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cc957a5c434be32e46124413bcf43e9d",
    "id": null,
    "metadata": {},
    "name": "ValidDocumentUploadLinkProviderQuery",
    "operationKind": "query",
    "text": "query ValidDocumentUploadLinkProviderQuery(\n  $id: ID!\n) {\n  documentUploadLink(id: $id) {\n    id\n    category {\n      value\n    }\n    type {\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "40d334aa518b39a8f672fc79e96f9314";

export default node;
