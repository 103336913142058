import React from "react"

import { useNavigate } from "@tanstack/react-router"
import { FormattedMessage, useIntl } from "react-intl"

import { Main } from "src/components/Main/Main"
import { GlowButton, GlowFlexbox, GlowIcon, GlowText } from "src/glow"

export default function FatalErrorPage() {
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <Main size="xl" className="h-full bg-white md:bg-gray-100">
      <GlowFlexbox justifyContent="center" className="h-full">
        <GlowIcon
          name="nous_logo"
          className="absolute top-6 h-5 w-20 md:left-6"
        />
        <GlowFlexbox
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="6"
          className="w-full"
        >
          <img
            src="/images/500.png"
            alt=""
            className="w-1/2 md:max-h-[400px] md:w-auto"
          />
          <GlowFlexbox direction="column" gap="2" className="max-w-xs">
            <GlowText size="xl" fontWeight="medium" as="h2">
              <FormattedMessage
                id="fatalErrorPage.title"
                defaultMessage="Something went wrong."
              />
            </GlowText>
            <GlowText size="sm" as="p">
              <FormattedMessage
                id="fatalErrorPage.subtitle"
                defaultMessage="Please refresh the page to try again."
              />
            </GlowText>
          </GlowFlexbox>
          <GlowFlexbox direction="column" gap="4" className="w-full max-w-xs">
            <GlowButton
              variant="primary"
              onClick={() => location.reload()}
              label={intl.formatMessage({
                id: "fatalErrorPage.cta.refresh",
                defaultMessage: "Refresh the page",
              })}
            />
            <GlowButton
              variant="tertiary"
              onClick={() => navigate({ to: "/" })}
              label={intl.formatMessage({
                id: "fatalErrorPage.cta.goToDashboard",
                defaultMessage: "Go to your dashboard",
              })}
            />
          </GlowFlexbox>
        </GlowFlexbox>
      </GlowFlexbox>
    </Main>
  )
}
