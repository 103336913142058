import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { GlowIcon } from "src/glow"

import {
  EmailConnectionScanStatus,
  EmailConnectionScanStatusIndicator_emailConnection$key,
} from "./__generated__/EmailConnectionScanStatusIndicator_emailConnection.graphql"

type EmailConnectionScanStatusIndicatorProps = {
  emailConnection: EmailConnectionScanStatusIndicator_emailConnection$key
}

export const EmailConnectionScanStatusIndicator = ({
  emailConnection: emailConnectionKey,
}: EmailConnectionScanStatusIndicatorProps) => {
  const emailConnection = useFragment(
    graphql`
      fragment EmailConnectionScanStatusIndicator_emailConnection on EmailConnection {
        id
        scanStatus
      }
    `,
    emailConnectionKey,
  )

  const emailConnectionStatusToIconRecord: Record<
    EmailConnectionScanStatus,
    JSX.Element | null
  > = {
    SCANNING: (
      <GlowIcon
        name="loading_regular"
        className="mr-1.5 h-4 w-4 animate-[spin_3s_linear_infinite]"
      />
    ),
    SCAN_FAILED: (
      <GlowIcon
        name="alert_circle_bold"
        className="text-angsty-red-400 h-4 w-4"
      />
    ),
    SCAN_COMPLETED: (
      <GlowIcon
        name="check_circle_1_bold"
        className="text-virtuous-green-400 h-4 w-4"
      />
    ),
    "%future added value": null,
  }

  return emailConnectionStatusToIconRecord[
    emailConnection?.scanStatus ?? "%future added value"
  ]
}
