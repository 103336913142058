/**
 * @generated SignedSource<<12ffb66028d93f8768f4a4e97b2a6956>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NousServiceLevel = "DO_ALL_OF_IT_FOR_ME" | "DO_EVERYTHING_MYSELF" | "DO_MOST_OF_IT_FOR_ME" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingsPageOverviewSavingsMode_household$data = {
  readonly currentMember: {
    readonly joinedHousehold: string | null | undefined;
  } | null | undefined;
  readonly serviceLevel: NousServiceLevel | null | undefined;
  readonly serviceManagements: ReadonlyArray<{
    readonly __typename: string;
    readonly enabled: boolean | null | undefined;
  }>;
  readonly " $fragmentType": "SettingsPageOverviewSavingsMode_household";
};
export type SettingsPageOverviewSavingsMode_household$key = {
  readonly " $data"?: SettingsPageOverviewSavingsMode_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageOverviewSavingsMode_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageOverviewSavingsMode_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdMember",
      "kind": "LinkedField",
      "name": "currentMember",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "joinedHousehold",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "serviceManagements",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "27c6e2058695472ce6754fba6da173b9";

export default node;
