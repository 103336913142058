import { useContext, useMemo } from "react"
import React from "react"

import {
  FirebaseError,
  FirebaseOptions,
  getApp,
  getApps,
  initializeApp,
} from "firebase/app"
import * as firebaseAuth from "firebase/auth"

import useCounter from "./hooks/useCounter"
import { logger } from "./logger"
import { filterNulls } from "./utils/filterNulls"
import { first } from "./utils/first"

const firebaseConfig: FirebaseOptions = {
  apiKey: __FIREBASE_API_KEY__,
  authDomain: __FIREBASE_AUTH_DOMAIN__,
  databaseURL: __FIREBASE_DATABASE_URL__,
  projectId: __FIREBASE_PROJECT_ID__,
  storageBucket: __FIREBASE_STORAGE_BUCKET__,
  messagingSenderId: __FIREBASE_MESSAGING_SENDER_ID__,
  appId: __FIREBASE_APP_ID__,
}

const app = ((config) => {
  const apps = getApps()

  if (!apps.length) {
    initializeApp(config)
  }

  const app = getApp()

  if (
    ["development", "test"].indexOf(__NOUS_ENV__ ?? "") >= 0 &&
    __FIREBASE_AUTH_EMULATOR_HOST__ !== undefined
  ) {
    firebaseAuth.connectAuthEmulator(
      firebaseAuth.getAuth(),
      `http://${__FIREBASE_AUTH_EMULATOR_HOST__}`,
      { disableWarnings: true },
    )
  }

  return app
})(firebaseConfig)

type AuthState =
  | { status: "INITIALIZING"; promise: Promise<unknown> }
  | {
      status: "READY"
      currentUser: firebaseAuth.User | null
    }
  | { status: "ERROR"; error: Error }

type AuthContextType = {
  rerender: () => void
}

const AuthContext = React.createContext<AuthContextType | null>(null)

const firebaseInitializedPromise = new Promise<firebaseAuth.User | null>(
  (resolve, reject) => {
    const unsub = firebaseAuth.getAuth(app).onAuthStateChanged(
      (user) => {
        unsub()
        resolve(user)
      },
      (err) => {
        unsub()
        reject(err)
      },
    )
  },
)

let state: AuthState = {
  status: "INITIALIZING",
  promise: firebaseInitializedPromise.then(
    (user) => {
      logger.debug("Ready for auth", { user })
      state = {
        status: "READY",
        currentUser: user,
      }
    },
    (error) => {
      logger.debug("Auth error", { error })
      state = {
        status: "ERROR",
        error,
      }
    },
  ),
}
firebaseAuth.getAuth(app).onIdTokenChanged(async (user) => {
  state = { status: "READY", currentUser: user }
})

export function AuthProvider(props: { children: React.ReactNode }) {
  const { value: tick, increment: rerender } = useCounter()
  const value = useMemo(
    () => ({
      rerender: () => {
        logger.debug("rerendering auth context", { state, tick })
        rerender()
      },
      tick,
    }),
    [tick, rerender],
  )
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  )
}

function useAuthContext(): AuthContextType {
  const state = useContext(AuthContext)
  if (!state) {
    throw new Error("useAuthContext must be used within an AuthProvider")
  }
  return state
}

function authProviderFromSSOProvider(provider: SSOProvider) {
  switch (provider) {
    case SSOProvider.Google:
      return new firebaseAuth.GoogleAuthProvider()
    case SSOProvider.Facebook:
      return new firebaseAuth.FacebookAuthProvider()
    case SSOProvider.Microsoft:
      return new firebaseAuth.OAuthProvider("microsoft.com")
  }
}

export async function getAccessToken(): Promise<{
  token: string
  provider: string
} | null> {
  if (state.status === "INITIALIZING") {
    await state.promise
  }
  if (state.status !== "READY") {
    return null
  }
  const { currentUser } = state
  if (!currentUser) {
    return null
  }
  const token = await currentUser.getIdToken()
  return {
    token,
    provider: "firebase",
  }
}
export type User = {
  id: string
  email?: string
  displayName?: string
  isAnonymous: boolean
}

function firebaseUserToUser(user: firebaseAuth.User): User {
  const displayNameFromOtherProviders = first(
    filterNulls(user.providerData.map((u) => u.displayName)),
  )
  return {
    id: user.uid,
    email: user.email ?? undefined,
    displayName: user.displayName ?? displayNameFromOtherProviders,
    isAnonymous: user.isAnonymous,
  }
}

export function useUser(): User | null {
  useContext(AuthContext)
  if (state.status === "ERROR") {
    throw state.error
  }
  if (state.status === "INITIALIZING") {
    throw state.promise
  }
  const user = state.currentUser
  return useMemo(() => (user ? firebaseUserToUser(user) : null), [user])
}

export enum SSOProvider {
  Google = "google",
  Facebook = "facebook",
  Microsoft = "microsoft",
}

type AuthActions = {
  deleteUser: () => Promise<void>
  linkAccountWithCredentials: (args: {
    email: string
    password: string
  }) => Promise<User>
  signInAnonymously: () => Promise<User>
  signInWithCustomToken: (args: { token: string }) => Promise<string>
  signUp: (args: { email: string; password: string }) => Promise<User>
  logOut: () => Promise<void>
  signInWithEmailAndPassword: (args: {
    email: string
    password: string
  }) => Promise<User>
  signInWithSSO(provider: SSOProvider): Promise<User>
  linkWithSSO(provider: SSOProvider): Promise<User>
  sendPasswordResetEmail: (args: { email: string }) => Promise<void>
  confirmPasswordReset: (args: {
    code: string
    newPassword: string
  }) => Promise<void>
}

export function useAuthActions(): AuthActions & {
  authTransaction: <TResult>(
    fn: (actions: AuthActions) => Promise<TResult>,
  ) => Promise<TResult>
} {
  const state = useAuthContext()
  return useMemo(() => {
    const stateRerender = state.rerender
    const actions = (rerender: () => void): AuthActions => ({
      confirmPasswordReset: async ({ code, newPassword }) => {
        await firebaseAuth.confirmPasswordReset(
          firebaseAuth.getAuth(app),
          code,
          newPassword,
        )
      },
      deleteUser: async () => {
        const user = firebaseAuth.getAuth(app).currentUser
        if (!user) {
          throw new Error("user not found")
        }
        await user.delete()
        rerender()
      },
      async signInWithSSO(providerName: SSOProvider) {
        const provider = authProviderFromSSOProvider(providerName)

        const credentials = await firebaseAuth.signInWithPopup(
          firebaseAuth.getAuth(app),
          provider,
        )
        rerender()
        return firebaseUserToUser(credentials.user)
      },
      async linkWithSSO(providerName: SSOProvider) {
        const provider = authProviderFromSSOProvider(providerName)
        const auth = firebaseAuth.getAuth(app)
        const currentUser = auth.currentUser
        if (!currentUser) {
          throw new Error("user not found")
        }
        const { user } = await firebaseAuth.linkWithPopup(currentUser, provider)
        rerender()
        return firebaseUserToUser(user)
      },
      signInWithEmailAndPassword: async ({ email, password }) => {
        const user = await firebaseAuth.signInWithEmailAndPassword(
          firebaseAuth.getAuth(app),
          email,
          password,
        )
        rerender()
        return firebaseUserToUser(user.user)
      },
      signInAnonymously: async () => {
        logger.debug("Signing in anonymously")
        const user = await firebaseAuth.signInAnonymously(
          firebaseAuth.getAuth(app),
        )
        rerender()
        return firebaseUserToUser(user.user)
      },
      async signInWithCustomToken({ token }): Promise<string> {
        const user = await firebaseAuth.signInWithCustomToken(
          firebaseAuth.getAuth(app),
          token,
        )
        const userId = user.user.uid

        return userId
      },
      signUp: async ({ email, password }) => {
        const user = await firebaseAuth.createUserWithEmailAndPassword(
          firebaseAuth.getAuth(app),
          email,
          password,
        )
        rerender()
        return firebaseUserToUser(user.user)
      },
      linkAccountWithCredentials: async ({ email, password }) => {
        const emailPasswordCredentials =
          firebaseAuth.EmailAuthProvider.credential(email, password)
        const auth = firebaseAuth.getAuth(app)
        const currentUser = auth.currentUser
        if (!currentUser) {
          throw new Error("user not found")
        }
        await firebaseAuth.linkWithCredential(
          currentUser,
          emailPasswordCredentials,
        )
        const newUser = await firebaseAuth.signInWithEmailAndPassword(
          auth,
          email,
          password,
        )
        return firebaseUserToUser(newUser.user)
      },
      sendPasswordResetEmail: async ({ email }) => {
        await firebaseAuth.sendPasswordResetEmail(
          firebaseAuth.getAuth(app),
          email,
        )
      },
      logOut: async () => {
        logger.debug("Logging out")
        await firebaseAuth.signOut(firebaseAuth.getAuth(app))
        rerender()
      },
    })
    return {
      ...actions(stateRerender),
      authTransaction: async (fn) => {
        const result = await fn(actions(() => {}))
        stateRerender()
        return result
      },
    }
  }, [state.rerender])
}

export function isFirebaseError(error: unknown): error is FirebaseError {
  return error instanceof Error && error.name === "FirebaseError"
}
