import { LoadingDots } from "./components/LoadingDots/LoadingDots"
import { GlowFlexbox } from "./glow"

export default function LoadingPagePlaceholder() {
  return (
    <GlowFlexbox
      alignItems="center"
      justifyContent="center"
      className="!w-full-escape h-screen"
    >
      <LoadingDots />
    </GlowFlexbox>
  )
}
