import { MessageDescriptor, defineMessage } from "react-intl"

import { GlowIconName } from "src/glow"
import { NousServiceLevel } from "src/hooks/__generated__/useRedirectToWaitlist_household.graphql"
import { CheckedRelayEnum } from "src/types"

export const serviceLevelBenefitsMap: Record<
  CheckedRelayEnum<NousServiceLevel>,
  Array<{
    icon: GlowIconName
    text: MessageDescriptor
    missed?: boolean
  }>
> = {
  DO_EVERYTHING_MYSELF: [
    {
      icon: "check_double_1_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_EVERYTHING_MYSELF.1",
        defaultMessage:
          "We'll only ever propose like-for-like deals from trusted providers",
      }),
    },
    {
      icon: "close_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_EVERYTHING_MYSELF.2",
        defaultMessage: "You deal with the hassle of switching providers",
      }),
      missed: true,
    },
    {
      icon: "close_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_EVERYTHING_MYSELF.3",
        defaultMessage: "Forfeit cash rewards and access to exclusive deals",
      }),
      missed: true,
    },
  ],
  DO_MOST_OF_IT_FOR_ME: [
    {
      icon: "check_double_1_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_MOST_OF_IT_FOR_ME.1",
        defaultMessage:
          "We'll only ever propose like-for-like deals from trusted providers",
      }),
    },
    {
      icon: "message_bubble_question_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_MOST_OF_IT_FOR_ME.2",
        defaultMessage:
          "We'll give you plenty of time to review our proposals before we make changes",
      }),
    },
    {
      icon: "task_finger_show_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_MOST_OF_IT_FOR_ME.3",
        defaultMessage:
          "You remain in control and can opt-out of proposed changes at any time",
      }),
    },
  ],
  DO_ALL_OF_IT_FOR_ME: [
    {
      icon: "check_double_1_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_ALL_OF_IT_FOR_ME.1",
        defaultMessage:
          "We'll only ever propose like-for-like deals from trusted providers",
      }),
    },
    {
      icon: "time_rest_time_3_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_ALL_OF_IT_FOR_ME.2",
        defaultMessage:
          "We'll automatically switch your providers, letting you know once it's done",
      }),
    },
    {
      icon: "synchronize_refresh_arrow_2_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_ALL_OF_IT_FOR_ME.3",
        defaultMessage:
          "We'll reverse any changes we've made if you're unhappy with them",
      }),
    },
  ],
}
