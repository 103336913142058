import { FormattedMessage, useIntl } from "react-intl"

import { Alert } from "src/components/Alert/Alert"
import { CheckedRelayEnum } from "src/types"
import { capitalize } from "src/utils/capitalize"
import { serviceLevelNameMap } from "src/utils/serviceLevelNameMap"

import { NousServiceLevel } from "../__generated__/SettingsPageSavingsMode_household.graphql"

export function ToggleAlert(props: {
  serviceLevel: CheckedRelayEnum<NousServiceLevel>
}) {
  const intl = useIntl()

  return (
    <Alert variant="error" className="w-full">
      <FormattedMessage
        id="page.settings.view.savingsMode.togglesAlert"
        defaultMessage="In {selectedServiceLevel} mode you must have at least one service toggled on. If you no longer want us to secure savings for you, you can switch to Manual mode."
        values={{
          selectedServiceLevel: capitalize(
            intl.formatMessage(serviceLevelNameMap[props.serviceLevel]),
          ),
        }}
      />
    </Alert>
  )
}
