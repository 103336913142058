import usePathname from "./usePathname"

export function useGoogleSSO() {
  const pathname = usePathname()

  const redirectUri = new URL(`${window.location.origin}${pathname}`).toString()
  const encodeState = () =>
    encodeURIComponent(Buffer.from(redirectUri, "utf-8").toString("base64"))

  // Want redirect to be default if FF blocked or failed
  return {
    redirectUri,
    encodeState,
  }
}
