import { useCallback, useEffect, useState } from "react"

import { useIntl } from "react-intl"
import { useFragment, useMutation } from "react-relay"
import { graphql } from "relay-runtime"

import { GlowButton, GlowFlexbox } from "src/glow"
import { useViewport, Viewport } from "src/hooks/responsive"
import useTracking from "src/tracking/useTracking"
import { relayMutationToPromise } from "src/utils/relayMutationToPromise"

import { ShareSecondHouseholderInvite_currentUser$key } from "./__generated__/ShareSecondHouseholderInvite_currentUser.graphql"
import { ShareSecondHouseholderInviteMutation } from "./__generated__/ShareSecondHouseholderInviteMutation.graphql"

import ShareLink from "../../../../../../components/ShareLink/ShareLink"

type ShareSecondHouseholderInviteProps = {
  currentUser: ShareSecondHouseholderInvite_currentUser$key | null
  onShareViaEmail: () => void
}

const baseUrlRecord: Record<string, string> = {
  development: "http://localhost:3000/sign-up",
  staging: "https://app.staging.nous.co/sign-up",
  production: "https://app.nous.co/sign-up",
}

export default function ShareSecondHouseholderInvite(
  props: ShareSecondHouseholderInviteProps,
) {
  const intl = useIntl()
  const track = useTracking()
  const [inviteId, setInviteId] = useState("")
  const [error, setError] = useState(false)

  const currentUser = useFragment(
    graphql`
      fragment ShareSecondHouseholderInvite_currentUser on User {
        firstName
      }
    `,
    props.currentUser,
  )

  const [retrieveOrCreateShareableInvite, isGeneratingInvite] =
    useMutation<ShareSecondHouseholderInviteMutation>(graphql`
      mutation ShareSecondHouseholderInviteMutation {
        retrieveOrCreateShareableInvite {
          invite {
            id
          }
        }
      }
    `)

  const generateInvite = useCallback(async () => {
    await relayMutationToPromise(retrieveOrCreateShareableInvite, {
      variables: {},
      onCompleted: (response, errors) => {
        if (errors && errors.length !== 0) {
          setInviteId("")
          setError(true)
        } else {
          setInviteId(
            response.retrieveOrCreateShareableInvite?.invite?.id ?? "",
          )
        }
      },
      onError: () => {
        setInviteId("")
        setError(true)
      },
    })
  }, [retrieveOrCreateShareableInvite])

  useEffect(() => {
    generateInvite()
  }, [generateInvite])

  const shareableInviteUrl = `${baseUrlRecord[__NOUS_ENV__]}?invite=${inviteId}&utm_source=invite`

  const viewport = useViewport()

  if (error) {
    return null
  }

  return (
    <GlowFlexbox direction="column" gap="4" className="w-full">
      <ShareLink
        isLoading={isGeneratingInvite}
        linkToShare={shareableInviteUrl}
        loadingText={intl.formatMessage({
          id: "shareSecondHouseholderInvite.generating",
          defaultMessage: "Generating invite...",
        })}
        nativeShareText={intl.formatMessage({
          id: "shareSecondHouseholderInvite.shareMessage.text",
          defaultMessage:
            "Hi, I'm setting up Nous for our household. I've invited you to join as you pay some of the bills. Follow the link to accept the invitation and learn how Nous saves you money and time on household bills.",
        })}
        nativeShareTitle={intl.formatMessage(
          {
            id: "shareSecondHouseholderInvite.shareMessage.title",
            defaultMessage: "Join {userName} household",
          },
          { userName: `${currentUser?.firstName}'s` ?? "your" },
        )}
        onShared={() => track(["Invite", "Shared", { inviteId }])}
        title={intl.formatMessage({
          id: "shareSecondHouseholderInvite.title",
          defaultMessage: "Share invite link",
        })}
      />

      <GlowButton
        className="w-full"
        variant="tertiary"
        onClick={props.onShareViaEmail}
        size={viewport < Viewport.MD ? "sm" : "base"}
        label={intl.formatMessage({
          id: "shareSecondHouseholderInvite.viaEmail",
          defaultMessage: "Send invite via email",
        })}
      />
    </GlowFlexbox>
  )
}
