import { MessageDescriptor, defineMessage } from "react-intl"

import { NousServiceLevel } from "src/hooks/__generated__/useRedirectToWaitlist_household.graphql"
import { CheckedRelayEnum } from "src/types"

export const serviceLevelDescriptionMap: Record<
  CheckedRelayEnum<NousServiceLevel>,
  {
    header: MessageDescriptor
    paragraph: MessageDescriptor
  }
> = {
  DO_EVERYTHING_MYSELF: {
    header: defineMessage({
      id: "utils.serviceLevelDescription.header.DO_EVERYTHING_MYSELF",
      defaultMessage:
        "We'll flag possible savings but you'll have to sort all the details yourself.",
    }),
    paragraph: defineMessage({
      id: "utils.serviceLevelDescription.paragraph.DO_EVERYTHING_MYSELF",
      defaultMessage:
        "The ideal choice if you prefer to do the work yourself. You may miss out on Nous cash rewards.",
    }),
  },
  DO_MOST_OF_IT_FOR_ME: {
    header: defineMessage({
      id: "utils.serviceLevelDescription.header.DO_MOST_OF_IT_FOR_ME",
      defaultMessage:
        "We'll find you savings and automatically handle the hassle of switching providers for you.",
    }),
    paragraph: defineMessage({
      id: "utils.serviceLevelDescription.paragraph.DO_MOST_OF_IT_FOR_ME",
      defaultMessage:
        "The ideal choice for busy professionals and families, offering a balance of convenience and control.",
    }),
  },
  DO_ALL_OF_IT_FOR_ME: {
    header: defineMessage({
      id: "utils.serviceLevelDescription.header.DO_ALL_OF_IT_FOR_ME",
      defaultMessage:
        "We'll act on your behalf to find savings automatically and try not to bother you.",
    }),
    paragraph: defineMessage({
      id: "utils.serviceLevelDescription.paragraph.DO_ALL_OF_IT_FOR_ME",
      defaultMessage:
        "The ideal choice for landlords and serviced apartments. Delegate all decisions over household bills to Nous.",
    }),
  },
}
