/**
 * @generated SignedSource<<2e95c3a290a25e0ce29d0cc3e9bda6a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageOverviewHouse_household$data = {
  readonly address: {
    readonly firstLine: string | null | undefined;
    readonly postcode: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CreateAccountPopup_address">;
  } | null | undefined;
  readonly members: ReadonlyArray<{
    readonly id: string;
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"Avatar_user">;
    } | null | undefined;
  }>;
  readonly nousholdEmail: string | null | undefined;
  readonly pendingInvites: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_invite">;
  }>;
  readonly " $fragmentType": "SettingsPageOverviewHouse_household";
};
export type SettingsPageOverviewHouse_household$key = {
  readonly " $data"?: SettingsPageOverviewHouse_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageOverviewHouse_household">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageOverviewHouse_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdMemberInvite",
      "kind": "LinkedField",
      "name": "pendingInvites",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_invite"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdMember",
      "kind": "LinkedField",
      "name": "members",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Avatar_user"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdAddress",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstLine",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postcode",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateAccountPopup_address"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nousholdEmail",
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};
})();

(node as any).hash = "965dae2f4eca3293a83801372564e5de";

export default node;
