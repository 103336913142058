import { useIntl } from "react-intl"
import {
  PreloadedQuery,
  graphql,
  useFragment,
  useMutation,
  usePreloadedQuery,
} from "react-relay"

import { InviteForm } from "src/components/InviteModal/InviteForm"
import { useModal } from "src/hooks/modal"

import { InviteModalContent_invites$key } from "./__generated__/InviteModalContent_invites.graphql"
import { InviteModalContentCancelInviteMutation } from "./__generated__/InviteModalContentCancelInviteMutation.graphql"
import { InviteModalContentQuery } from "./__generated__/InviteModalContentQuery.graphql"
import InviteModalPendingInvite from "./InviteModalPendingInvite"

import { Modal } from "../Modal/Modal"

type Props = {
  query: PreloadedQuery<InviteModalContentQuery>
}

export default function InviteModalContent({ query: queryKey }: Props) {
  const data = usePreloadedQuery<InviteModalContentQuery>(
    graphql`
      query InviteModalContentQuery {
        household {
          pendingInvites {
            id
            ...InviteModalPendingInvite_invite
            ...InviteModalContent_invites
          }
        }
      }
    `,
    queryKey,
  )

  const allInvitesModal = useModal()

  const [cancelInvite, cancelling] =
    useMutation<InviteModalContentCancelInviteMutation>(graphql`
      mutation InviteModalContentCancelInviteMutation($id: ID!) {
        cancelHouseholdInviteV2(id: $id) {
          household {
            pendingInvites {
              id
            }
          }
        }
      }
    `)
  const first3UsefulInvites = data.household.pendingInvites.slice(0, 3)

  const actuallyCancelInvite = async (id: string) => {
    cancelInvite({
      variables: { id },
    })
  }

  const intl = useIntl()
  return (
    <>
      <div className="flex flex-col space-y-6">
        <InviteForm disableSubmit={cancelling} />
        {data.household.pendingInvites.length > 0 && (
          <div className="flex flex-col space-y-4">
            <hr />
            <div className="flex justify-between">
              <h2 className="text-sm font-bold text-gray-500">
                {intl.formatMessage(
                  {
                    id: "inviteMOdalContent.pendingInviteSectionHeader",
                    defaultMessage:
                      "Pending invites ({numberOfPendingInvites})",
                  },
                  {
                    numberOfPendingInvites:
                      data.household.pendingInvites.length,
                  },
                )}
              </h2>
              {data.household.pendingInvites.length > 3 && (
                <button
                  onClick={allInvitesModal.open}
                  className="text-serene-blue-500 underline"
                >
                  {intl.formatMessage({
                    id: "inviteModalContent.viewAllInvites",
                    defaultMessage: "Show all",
                  })}
                </button>
              )}
            </div>
            {first3UsefulInvites.map((invite) => (
              <InviteModalPendingInvite
                key={invite.id}
                invite={invite}
                onCancel={() => {
                  actuallyCancelInvite(invite.id)
                }}
              />
            ))}
          </div>
        )}
      </div>
      <AllInvitesModal
        invites={data.household.pendingInvites}
        isOpen={allInvitesModal.isOpen}
        onClose={allInvitesModal.close}
        onCancel={actuallyCancelInvite}
      />
    </>
  )
}

type AllInvitesModalProps = {
  isOpen: boolean
  onClose: () => void
  onCancel: (id: string) => void
  invites: InviteModalContent_invites$key
}

const AllInvitesModal = (props: AllInvitesModalProps) => {
  const invites = useFragment(
    graphql`
      fragment InviteModalContent_invites on HouseholdMemberInvite
      @relay(plural: true) {
        id
        ...InviteModalPendingInvite_invite
      }
    `,
    props.invites,
  )
  return (
    <Modal
      variant="fullscreen"
      header={"All invites"}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <div className="flex flex-col space-y-4">
        {invites.map((invite) => (
          <InviteModalPendingInvite
            key={invite.id}
            invite={invite}
            onCancel={() => props.onCancel(invite.id)}
          />
        ))}
      </div>
    </Modal>
  )
}
