import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import useBudget2024SpringModulesQuery, {
  useBudget2024SpringModulesQuery as useBudget2024SpringModulesQueryType,
} from "src/tools/Budget/Budget2024Spring/__generated__/useBudget2024SpringModulesQuery.graphql"

export const Route = createFileRoute("/widgets/budget/2024/spring")({
  loader: ({ context }) =>
    loadQuery<useBudget2024SpringModulesQueryType>(
      context.relayEnvironment(),
      useBudget2024SpringModulesQuery,
      {},
    ),
  component: lazyRouteComponent(
    () => import("src/pages/Widgets/Budget2024SpringPage/Budget2024SpringPage"),
  ),
})
