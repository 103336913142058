import { clsx } from "clsx"

import { GlowFlexbox } from "./GlowFlexbox"
import { GlowIcon } from "./GlowIcon"
import { GlowText, GlowTextSize } from "./GlowText"
import { GlowResponsiveSpacing } from "./structure"

export type GlowAvatarSize = "lg" | "base" | "sm"

export type GlowAvatarBackground =
  | "gradient"
  | "yellow"
  | "lightYellow"
  | "gray"

type GlowAvatarProps = {
  className?: string
  label?: string | null
  margin?: GlowResponsiveSpacing
  size?: GlowAvatarSize
} & (
  | {
      background: GlowAvatarBackground
      user: GlowAvatarUser
      withIcon?: false
    }
  | { background?: undefined; user?: undefined; withIcon: true }
)

type GlowAvatarUser = {
  initials: string
}

const avatarSize = {
  base: "h-10 w-10",
  sm: "h-8 w-8",
  lg: "h-12 w-12",
}

const iconSize = {
  base: "h-8 w-8",
  sm: "h-6 w-6",
  lg: "h-10 w-10",
}

const nameSize = {
  base: "base",
  sm: "xs",
  lg: "xl",
} satisfies Record<GlowAvatarSize, GlowTextSize>

const backgroundColour = {
  gradient: "bg-nous-glow-400 radial-gradient",
  yellow: "bg-nous-glow-400",
  lightYellow: "bg-nous-glow-300",
  gray: "bg-gray-200",
}

export function GlowAvatar({
  background,
  className,
  label,
  margin,
  user,
  size = "base",
  withIcon,
}: GlowAvatarProps) {
  return (
    <GlowFlexbox
      direction="column"
      margin={margin}
      alignItems="center"
      className={clsx(className)}
    >
      <div
        className={clsx(
          "overflow-hidden rounded-full",
          avatarSize[size],
          background ? backgroundColour[background] : "bg-gray-100",
        )}
        aria-label={user?.initials}
      >
        <div className="flex h-full flex-col items-center justify-center">
          {withIcon ? (
            <div className={clsx("relative top-1.5", iconSize[size])}>
              <GlowIcon
                name="single_neutral_action_bold"
                className="h-full w-full text-gray-300"
              />
            </div>
          ) : (
            <GlowText
              className={clsx("text-white")}
              size={nameSize[size]}
              fontWeight="bold"
            >
              {user?.initials.toUpperCase()}
            </GlowText>
          )}
        </div>
      </div>
      {label != null && (
        <GlowText size="xs" className="mt-1 truncate text-gray-500" as="div">
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          {label || "\u00A0"}
        </GlowText>
      )}
    </GlowFlexbox>
  )
}
