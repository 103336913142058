import { SetStateAction } from "react"

import { useIntl, FormattedMessage } from "react-intl"

import { GlowFlexbox, GlowText, GlowButton, GlowIcon } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import { CheckedRelayEnum } from "src/types"
import { capitalize } from "src/utils/capitalize"
import { serviceLevelNameMap } from "src/utils/serviceLevelNameMap"

import { ServiceManagementToggles } from "./ServiceManagementToggles"
import { ToggleAlert } from "./ToggleAlert"

import { NousServiceLevel } from "../__generated__/SettingsPageSavingsMode_household.graphql"
import { ServiceManagementsInput } from "../__generated__/SettingsPageSavingsModeUpdateHouseholdSavingsModeMutation.graphql"
import {
  serviceLevelChangeExplainationMap,
  serviceLevelSelectExplainationMap,
} from "../content"

type ChangeServiceLevelPopupProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  onCancel: () => void
  from: CheckedRelayEnum<NousServiceLevel> | null
  to: CheckedRelayEnum<NousServiceLevel>
  updating: boolean
  serviceTypeManagementEnablement: ServiceManagementsInput
  setServiceTypeManagementEnablement: (
    value: SetStateAction<ServiceManagementsInput>,
  ) => void
  areAllTogglesOff: boolean
}

export function ChangeServiceLevelPopup(props: ChangeServiceLevelPopupProps) {
  const intl = useIntl()

  const showToggles = props.from === "DO_EVERYTHING_MYSELF"
  const showAlert =
    props.areAllTogglesOff && props.to !== "DO_EVERYTHING_MYSELF"

  return (
    <GlowModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={intl.formatMessage(
        {
          id: "page.settings.view.savingsMode.changeServiceLevelPopup.title",
          defaultMessage: "Switch to {serviceLevel} mode?",
        },
        {
          serviceLevel: capitalize(
            intl.formatMessage(serviceLevelNameMap[props.to]),
          ),
        },
      )}
      disableManualClose={props.updating}
    >
      <GlowFlexbox direction="column" gap="4" alignItems="center">
        <GlowText textAlign="center">
          {props.from ? (
            <FormattedMessage
              {...serviceLevelChangeExplainationMap[props.from][props.to]}
            />
          ) : (
            <FormattedMessage
              {...serviceLevelSelectExplainationMap[props.to]}
            />
          )}
        </GlowText>

        {showToggles && (
          <ServiceManagementToggles
            serviceTypeManagementEnablement={
              props.serviceTypeManagementEnablement
            }
            isDisabled={false} // not shown when disablable anyway
            setServiceTypeManagementEnablement={
              props.setServiceTypeManagementEnablement
            }
          />
        )}

        {showAlert && <ToggleAlert serviceLevel={props.to} />}

        <GlowButton
          variant="primary"
          size="sm"
          label={intl.formatMessage(
            {
              id: "page.settings.view.savingsMode.changeServiceLevelPopup.confirmButton",
              defaultMessage: "-> Switch to {serviceLevel} mode",
            },
            {
              serviceLevel: capitalize(
                intl.formatMessage(serviceLevelNameMap[props.to]),
              ),
            },
          )}
          onClick={props.onConfirm}
          className="w-full"
          isLoading={props.updating}
          isDisabled={props.updating || showAlert}
        />

        <GlowButton
          variant="secondary"
          size="sm"
          label={intl.formatMessage({
            id: "page.settings.view.savingsMode.changeServiceLevelPopup.cancelButton",
            defaultMessage: "Close",
          })}
          onClick={props.onCancel}
          className="w-full"
          isLoading={props.updating}
          isDisabled={props.updating}
        />

        {props.to !== "DO_EVERYTHING_MYSELF" && (
          <GlowFlexbox gap="3" alignItems="center">
            <GlowIcon name="alarm_bell_regular" className="h-4 w-4 shrink-0" />
            <GlowText size="xs">
              <FormattedMessage
                id="page.settings.view.savingsMode.changeServiceLevelPopupinfoText"
                defaultMessage="<strong>Remember:</strong> We’ll always alert you when recommending any changes to your services so you’ll have plenty of time to review them."
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </GlowText>
          </GlowFlexbox>
        )}
      </GlowFlexbox>
    </GlowModal>
  )
}
