/**
 * @generated SignedSource<<e586e2cae621bf7ff42f2534ed5a6a8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Avatar_invite$data = {
  readonly firstName: string | null | undefined;
  readonly initials: string;
  readonly " $fragmentType": "Avatar_invite";
} | null | undefined;
export type Avatar_invite$key = {
  readonly " $data"?: Avatar_invite$data;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_invite">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Avatar_invite",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "initials",
        "storageKey": null
      },
      "action": "NONE",
      "path": "initials"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    }
  ],
  "type": "HouseholdMemberInvite",
  "abstractKey": null
};

(node as any).hash = "8d2ef7d8264a44704d2a87fb871fc550";

export default node;
