import { GlowIconName } from "src/glow"
import { SupportedManagement } from "src/types"

export const categoryManagementIconMap: Record<
  SupportedManagement,
  GlowIconName
> = {
  BroadbandManagement: "wifi_regular",
  EnergyManagement: "flash_1_regular",
  MobileManagement: "mobile_phone_regular",
  MortgageManagement: "real_estate_insurance_house_regular",
}
