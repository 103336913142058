import { useState } from "react"

import {
  FormattedMessage,
  MessageDescriptor,
  defineMessage,
  useIntl,
} from "react-intl"
import { graphql, useFragment, useMutation } from "react-relay"

import {
  GlowButton,
  GlowFlexbox,
  GlowForm,
  GlowFormInput,
  GlowFormInputSelect,
  GlowFormSortCodeInput,
  GlowHorizontalDivider,
  GlowText,
} from "src/glow"
import { logger } from "src/logger"

import { BankAccountDetailsGlowForm_bankAccount$key } from "./__generated__/BankAccountDetailsGlowForm_bankAccount.graphql"
import { BankAccountDetailsGlowFormUpdateBankAccountDetailsMutation } from "./__generated__/BankAccountDetailsGlowFormUpdateBankAccountDetailsMutation.graphql"

const selectableTitles = ["Mr", "Mrs", "Miss", "Ms", "Other"] as const
export type SelectableTitle = (typeof selectableTitles)[number]

export const titleOptions: Record<SelectableTitle, MessageDescriptor> = {
  Mr: defineMessage({
    id: "bankAccountDetailsGlowForm.title.mr",
    defaultMessage: "Mr",
  }),

  Mrs: defineMessage({
    id: "bankAccountDetailsGlowForm.title.mrs",
    defaultMessage: "Mrs",
  }),

  Miss: defineMessage({
    id: "bankAccountDetailsGlowForm.title.miss",
    defaultMessage: "Miss",
  }),

  Ms: defineMessage({
    id: "bankAccountDetailsGlowForm.title.ms",
    defaultMessage: "Ms",
  }),

  Other: defineMessage({
    id: "bankAccountDetailsGlowForm.title.otherOption",
    defaultMessage: "Other",
  }),
}

export default function BankAccountDetailsGlowForm(props: {
  bankAccount?: BankAccountDetailsGlowForm_bankAccount$key | null
  onCompleted?: () => void
  isLoading?: boolean
}) {
  const bankAccount = useFragment(
    graphql`
      fragment BankAccountDetailsGlowForm_bankAccount on BankAccount {
        title
        firstName
        lastName
        sortCode
        accountNumber
      }
    `,
    props.bankAccount,
  )

  const intl = useIntl()

  const [formState, setFormState] = useState({
    title: (selectableTitles as readonly string[]).includes(
      bankAccount?.title ?? "",
    )
      ? bankAccount?.title
      : bankAccount?.title
        ? "Other"
        : null,
    titleOther:
      bankAccount?.title &&
      !(selectableTitles as readonly string[]).includes(
        bankAccount?.title ?? "",
      )
        ? bankAccount?.title
        : "",
    firstName: bankAccount?.firstName ?? "",
    lastName: bankAccount?.lastName ?? "",
    sortCode: bankAccount?.sortCode ?? "",
    accountNumber: bankAccount?.accountNumber ?? "",
  })

  const [submitBankAccountDetails, isSubmitting] =
    useMutation<BankAccountDetailsGlowFormUpdateBankAccountDetailsMutation>(
      graphql`
        mutation BankAccountDetailsGlowFormUpdateBankAccountDetailsMutation(
          $input: UpdateBankAccountDetailsInput!
        ) {
          updateBankAccountDetailsNew(input: $input) {
            bankAccount {
              ...BankAccountDetailsGlowForm_bankAccount
            }
            household {
              ...OnboardingBankDetailsPageAccountDetailsAdditionStep_household
              ...DashboardPageQuests_household
              ...SettingsPageOverviewBankAccounts_household
              ...SettingsPageSubPageBankAccounts_household
            }
          }
        }
      `,
    )

  return (
    <GlowFlexbox direction="column" gap="6">
      <GlowForm
        onSubmit={(state) => {
          submitBankAccountDetails({
            variables: {
              input: {
                title:
                  state.title === "Other"
                    ? state.titleOther
                    : (state.title ?? ""),
                firstName: state.firstName,
                lastName: state.lastName,
                sortCode: state.sortCode,
                accountNumber: state.accountNumber,
              },
            },
            onCompleted: () => {
              props.onCompleted?.()
            },
            onError: (error) => {
              logger.error("Failed to submit bank account details glow form", {
                error,
              })
            },
          })
        }}
        value={formState}
        onChange={setFormState}
      >
        <GlowFlexbox direction="column" gap="6">
          <GlowFlexbox direction="column" gap="4">
            <GlowFormInputSelect
              formKey="title"
              label={intl.formatMessage({
                id: "bankAccountDetailsGlowForm.title.label",
                defaultMessage: "Title",
              })}
              placeholder={intl.formatMessage({
                id: "bankAccountDetailsGlowForm.title.placeholder",
                defaultMessage: "Select an option",
              })}
              options={Object.entries(titleOptions).map(([key, message]) => ({
                label: intl.formatMessage(message),
                value: key,
              }))}
              getErrors={(value) => [
                !value &&
                  intl.formatMessage({
                    id: "bankAccountDetailsGlowForm.title.error",
                    defaultMessage: "Please select your title",
                  }),
              ]}
            />
            {formState.title === "Other" && (
              <GlowFormInput
                formKey="titleOther"
                placeholder={intl.formatMessage({
                  id: "bankAccountDetailsGlowForm.titleOther.placeholder",
                  defaultMessage: "Dr",
                })}
                label={intl.formatMessage({
                  id: "bankAccountDetailsGlowForm.titleOther.label",
                  defaultMessage: "Title",
                })}
                getErrors={(value) => [
                  !value &&
                    intl.formatMessage({
                      id: "bankAccountDetailsGlowForm.titleOther.error",
                      defaultMessage: "Please enter your title",
                    }),
                ]}
              />
            )}
            <GlowFlexbox gap="2">
              <GlowFormInput
                className="grow"
                formKey="firstName"
                placeholder={intl.formatMessage({
                  id: "bankAccountDetailsGlowForm.firstName.placeholder",
                  defaultMessage: "Jane",
                })}
                label={intl.formatMessage({
                  id: "bankAccountDetailsGlowForm.firstName.label",
                  defaultMessage: "First name",
                })}
                getErrors={(value) => [
                  !value &&
                    intl.formatMessage({
                      id: "bankAccountDetailsGlowForm.firstName.error",
                      defaultMessage: "Please enter your first name",
                    }),
                ]}
              />
              <GlowFormInput
                placeholder={intl.formatMessage({
                  id: "bankAccountDetailsGlowForm.lastName.placeholder",
                  defaultMessage: "Doe",
                })}
                className="grow"
                formKey="lastName"
                label={intl.formatMessage({
                  id: "bankAccountDetailsGlowForm.lastName.label",
                  defaultMessage: "Last Name",
                })}
                getErrors={(value) => [
                  !value &&
                    intl.formatMessage({
                      id: "bankAccountDetailsGlowForm.lastName.error",
                      defaultMessage: "Please enter your last name",
                    }),
                ]}
              />
            </GlowFlexbox>

            <GlowFormSortCodeInput
              placeholder={intl.formatMessage({
                id: "bankAccountDetailsGlowForm.sortCode.placeholder",
                defaultMessage: "00-00-00",
              })}
              formKey="sortCode"
              label={intl.formatMessage({
                id: "bankAccountDetailsGlowForm.sortCode.label",
                defaultMessage: "Sort code",
              })}
              getErrors={(value) => [
                (!value || value.length !== 8) &&
                  intl.formatMessage({
                    id: "bankAccountDetailsGlowForm.sortCode.error",
                    defaultMessage: "Please enter your sort code",
                  }),
              ]}
              maxLength={8}
            />
            <GlowFormInput
              placeholder={intl.formatMessage({
                id: "bankAccountDetailsGlowForm.accountNumber.placeholder",
                defaultMessage: "12345678",
              })}
              formKey="accountNumber"
              label={intl.formatMessage({
                id: "bankAccountDetailsGlowForm.accountNumber.label",
                defaultMessage: "Account number",
              })}
              getErrors={(value) => [
                (!value || value.length !== 8) &&
                  intl.formatMessage({
                    id: "bankAccountDetailsGlowForm.accountNumber.error",
                    defaultMessage: "Please enter your account number",
                  }),
              ]}
              maxLength={8}
            />
          </GlowFlexbox>
          <GlowButton
            type="submit"
            label={intl.formatMessage({
              id: "bankAccountDetailsGlowForm.submit",
              defaultMessage: "Submit details",
            })}
            isLoading={isSubmitting || props.isLoading}
          />
        </GlowFlexbox>
      </GlowForm>

      <GlowHorizontalDivider />

      <GlowText size="sm" textAlign="center">
        <FormattedMessage
          id="bankAccountDetailsGlowForm.notice"
          defaultMessage="<strong>Please note:</strong> You're on the free tier with Nous and we'll never charge you without your explicit approval. We'll only use these details when we need to set up new providers or send you cash rewards."
          values={{
            strong: (chunks) => <GlowText fontWeight="bold">{chunks}</GlowText>,
          }}
        />
      </GlowText>
    </GlowFlexbox>
  )
}
