/**
 * @generated SignedSource<<a13a99d1d8f5a130294ac655d40e3408>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailConnectionScanStatus = "SCANNING" | "SCAN_COMPLETED" | "SCAN_FAILED" | "%future added value";
export type EmailConnectionSource = "GOOGLE" | "MICROSOFT" | "%future added value";
export type EmailConnectionManagerQuery$variables = {
  id: string;
};
export type EmailConnectionManagerQuery$data = {
  readonly emailConnection: {
    readonly email: string | null | undefined;
    readonly lastScannedAt: string | null | undefined;
    readonly scanStatus: EmailConnectionScanStatus | null | undefined;
    readonly source: EmailConnectionSource | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"EmailConnectionManager_emailConnection" | "EmailConnectionScanStatusIndicator_emailConnection">;
  } | null | undefined;
};
export type EmailConnectionManagerQuery = {
  response: EmailConnectionManagerQuery$data;
  variables: EmailConnectionManagerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scanStatus",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastScannedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailConnectionManagerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmailConnection",
        "kind": "LinkedField",
        "name": "emailConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EmailConnectionManager_emailConnection"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EmailConnectionScanStatusIndicator_emailConnection"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailConnectionManagerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmailConnection",
        "kind": "LinkedField",
        "name": "emailConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c4ffc412af6768e32adc977d4824416",
    "id": null,
    "metadata": {},
    "name": "EmailConnectionManagerQuery",
    "operationKind": "query",
    "text": "query EmailConnectionManagerQuery(\n  $id: ID!\n) {\n  emailConnection(id: $id) {\n    source\n    email\n    scanStatus\n    lastScannedAt\n    ...EmailConnectionManager_emailConnection\n    ...EmailConnectionScanStatusIndicator_emailConnection\n    id\n  }\n}\n\nfragment EmailConnectionManager_emailConnection on EmailConnection {\n  id\n  lastScannedAt\n  ...EmailConnectionScanStatusIndicator_emailConnection\n}\n\nfragment EmailConnectionScanStatusIndicator_emailConnection on EmailConnection {\n  id\n  scanStatus\n}\n"
  }
};
})();

(node as any).hash = "13fd631f95a64d2294c634d1c971f599";

export default node;
