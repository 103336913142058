import { clsx } from "clsx"

import { GlowIcon } from "src/glow"

export type AlertVariant = "error" | "warning" | "info" | "success"

const alertClassName = (variant: AlertVariant, className?: string): string => {
  return clsx(
    "flex items-center md:p-4 py-2 px-3 space-x-2 text-sm font-medium rounded-md",
    variant === "error" && "text-angsty-red-700 bg-angsty-red-100",
    variant === "warning" && "text-nous-glow-700 bg-nous-glow-100",
    variant === "info" && "text-serene-blue-700 bg-serene-blue-100",
    variant === "success" && "text-virtuous-green-700 bg-virtuous-green-100",
    className,
  )
}
const iconColourMap: Record<AlertVariant, string> = {
  error: "text-angsty-red-700",
  warning: "text-nous-glow-700",
  info: "text-serene-blue-700",
  success: "text-virtuous-green-700",
}

type HTMLDivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export type AlertProps = HTMLDivProps & {
  variant?: AlertVariant
  showCrossIcon?: boolean
  onDismiss?: () => void
}

export function Alert({
  variant = "error",
  className,
  onDismiss,
  children,
  showCrossIcon = true,
  ...props
}: AlertProps) {
  return (
    <div className={alertClassName(variant, className)} {...props}>
      {variant === "success" ? (
        <GlowIcon
          name="check_circle_1_bold"
          className={clsx("w-4", iconColourMap[variant])}
        />
      ) : (
        <GlowIcon
          name="alert_circle_bold"
          className={clsx("w-4", iconColourMap[variant])}
        />
      )}
      <p className="w-full" role="alert">
        {children}
      </p>
      {onDismiss !== undefined && showCrossIcon && (
        <button onClick={onDismiss}>
          <GlowIcon
            name="close_regular"
            className={clsx("w-4", iconColourMap[variant])}
          />
        </button>
      )}
    </div>
  )
}
