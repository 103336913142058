import { useMemo, useRef } from "react"

export default function useLastValue<T>(value: T): T | null {
  const ref = useRef<T | null>(null)
  return useMemo(() => {
    const lastValue = ref.current
    ref.current = value
    return lastValue
  }, [value])
}
