/**
 * @generated SignedSource<<9fa5fce4046683ccb45454deb206fda4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingSkipReasonModal_formAnswer$data = {
  readonly id: string;
  readonly " $fragmentType": "OnboardingSkipReasonModal_formAnswer";
};
export type OnboardingSkipReasonModal_formAnswer$key = {
  readonly " $data"?: OnboardingSkipReasonModal_formAnswer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingSkipReasonModal_formAnswer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingSkipReasonModal_formAnswer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "FormAnswer",
  "abstractKey": null
};

(node as any).hash = "a0d2f906d41bb739f4ec0556f42a2bf6";

export default node;
