import { ReactNode, useEffect, useRef } from "react"

import { Dialog, DialogPanel } from "@headlessui/react"
import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"

import { GlowIcon } from "src/glow"
import { Viewport, useViewport } from "src/hooks/responsive"

export const ModalTitle = ({ children }: { children?: ReactNode }) => (
  <p className="text-center text-base font-bold">{children}</p>
)

type ModalSharedProps = {
  header: string | ReactNode
  isOpen: boolean
  onClose: () => void
  noPadding?: boolean
  hideManualClose?: boolean
  children?: ReactNode
  modalClassName?: string
  disableManualClose?: boolean
}

type ModalFullScreenProps = ModalSharedProps

const ModalFullScreen = ({
  header,
  isOpen,
  onClose,
  noPadding,
  hideManualClose,
  children,
  modalClassName,
  disableManualClose,
}: ModalFullScreenProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          static
          as={motion.div}
          className={clsx(
            "z-modal fixed inset-0 !m-0",
            "bg-white",
            "transition duration-300 ease-out",
          )}
          initial={{ y: "100vh" }}
          exit={{ y: "100vh" }}
          animate={{ y: 0 }}
          transition
          open={isOpen}
          onClose={onClose}
        >
          <DialogPanel className="fixed inset-0 flex flex-col">
            {header && (
              <div className="w-full-escape border-gray-500/12 relative flex h-14 items-center justify-center border-b py-3">
                <div className="flex items-start">
                  {typeof header === "string" ? (
                    <ModalTitle>{header}</ModalTitle>
                  ) : (
                    header
                  )}
                </div>
                {!hideManualClose && (
                  <button
                    onClick={onClose}
                    className="disabled:text-gray-500/64 absolute right-4 p-1 disabled:cursor-not-allowed"
                    style={{ borderRadius: "50%" }}
                    disabled={disableManualClose}
                  >
                    <GlowIcon name="close_regular" className="w-6" />
                  </button>
                )}
              </div>
            )}

            <div
              className={clsx(
                "flex grow flex-col overflow-hidden",
                noPadding || "p-4 md:p-6",
                modalClassName,
              )}
            >
              {children}
            </div>
          </DialogPanel>
        </Dialog>
      )}
    </AnimatePresence>
  )
}

type ModalFloatyProps = ModalSharedProps & {
  level?: "0" | "1" | "2"
  onScroll?: () => void
  onOverlayClick?: () => void
}

export const MAX_MODAL_HEIGHT = "90vh"
export const MAX_MODAL_WIDTH = "528px"

const ModalFloaty = ({
  header,
  isOpen,
  onClose,
  noPadding,
  hideManualClose,
  children,
  modalClassName,
  level = "0",
  onScroll,
  onOverlayClick,
}: ModalFloatyProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current || !onScroll) {
      return
    }

    const element = ref.current

    element.addEventListener("scroll", onScroll)

    return () => {
      element.removeEventListener("scroll", onScroll)
    }
  }, [onScroll])

  return (
    <Dialog
      className={clsx(
        "fixed inset-0 flex flex-wrap overflow-y-auto",
        level === "0" && "z-modal",
        level === "1" && "z-modal-level-1",
        level === "2" && "z-modal-level-2",
      )}
      onClose={() => {
        if (hideManualClose) {
          return
        }

        if (onOverlayClick) {
          return onOverlayClick()
        }

        onClose()
      }}
      open={isOpen}
    >
      <div className="flex min-h-full w-full flex-col items-center justify-center md:min-h-0">
        <div className="opacity-64 fixed inset-0 bg-gray-500" />

        <Dialog.Panel
          className={clsx(
            `h-auto max-h-[${MAX_MODAL_HEIGHT}]`,
            `min-w-[500px] max-w-[${MAX_MODAL_WIDTH}]`,
            "flex flex-shrink-0 flex-col",
            "mx-6 rounded-lg bg-white shadow-lg",
            level === "1" ? "z-modal-level-1" : "z-modal",
            "cursor-default",
            modalClassName,
          )}
          tabIndex={-1}
          role="button"
        >
          <div className="border-gray-500/12 relative flex justify-center border-b">
            <div className="min-h-[56px] w-full px-12 py-4 text-center">
              {typeof header === "string" ? (
                <ModalTitle>{header}</ModalTitle>
              ) : (
                header
              )}
            </div>
            {!hideManualClose && (
              <button
                onClick={onClose}
                data-testid="modal-close-button"
                className="absolute right-4 top-4"
              >
                <GlowIcon name="close_regular" className="w-6" />
              </button>
            )}
          </div>

          <div
            className={clsx(
              "h-full overflow-y-auto",
              noPadding || "p-4 md:p-6",
            )}
            ref={ref}
          >
            {children}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export type ModalProps = ModalFullScreenProps &
  ModalFloatyProps & {
    variant?: "fullscreen" | "floaty"
  }

export const Modal = ({ variant = "floaty", ...rest }: ModalProps) => {
  const viewport = useViewport()
  const isMobile = viewport <= Viewport.SM

  if (isMobile) {
    return variant === "fullscreen" ? (
      <ModalFullScreen {...rest} />
    ) : (
      <ModalFloaty {...rest} />
    )
  }

  // No full screen modal on desktop!
  return <ModalFloaty {...rest} />
}
