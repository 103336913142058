import { MessageDescriptor, defineMessage } from "react-intl"

import { CheckedRelayEnum } from "src/types"

import { NousServiceLevel } from "./__generated__/SettingsPageSavingsMode_household.graphql"

/**
 * Ideally this would be:
 *
 * type ServiceLevelChangeExplaination = {
 *   [T in CheckedRelayEnum<NousServiceLevel>]: {
 *     [K in Exclude<CheckedRelayEnum<NousServiceLevel>, T>]: MessageDescriptor
 *   }
 * }
 *
 * But, alas, I cannot get the prop passthrough to work (says never).
 */
type ServiceLevelChangeExplaination = Record<
  CheckedRelayEnum<NousServiceLevel>,
  Record<CheckedRelayEnum<NousServiceLevel>, MessageDescriptor>
>
export const serviceLevelChangeExplainationMap: ServiceLevelChangeExplaination =
  {
    DO_EVERYTHING_MYSELF: {
      DO_EVERYTHING_MYSELF: defineMessage({
        id: "page.settings.view.savingsMode.changeServiceLevelPopup.from.DO_EVERYTHING_MYSELF.to.DO_EVERYTHING_MYSELF",
        defaultMessage: "noop",
      }),
      DO_MOST_OF_IT_FOR_ME: defineMessage({
        id: "page.settings.view.savingsMode.changeServiceLevelPopup.from.DO_EVERYTHING_MYSELF.to.DO_MOST_OF_IT_FOR_ME",
        defaultMessage:
          "If you'd like us to find you savings and handle the hassle of switching for you then you must set your savings mode to Balanced or Freedom.",
      }),
      DO_ALL_OF_IT_FOR_ME: defineMessage({
        id: "page.settings.view.savingsMode.changeServiceLevelPopup.from.DO_EVERYTHING_MYSELF.to.DO_ALL_OF_IT_FOR_ME",
        defaultMessage:
          "If you'd like us to find you savings and handle the hassle of switching for you then you must set your savings mode to Balanced or Freedom.",
      }),
    },
    DO_MOST_OF_IT_FOR_ME: {
      DO_EVERYTHING_MYSELF: defineMessage({
        id: "page.settings.view.savingsMode.changeServiceLevelPopup.from.DO_MOST_OF_IT_FOR_ME.to.DO_EVERYTHING_MYSELF",
        defaultMessage:
          "On Manual mode we won't deal with the hassle of switching providers for you and you'll forfeit cash rewards and access to exclusive deals.",
      }),
      DO_MOST_OF_IT_FOR_ME: defineMessage({
        id: "page.settings.view.savingsMode.changeServiceLevelPopup.from.DO_MOST_OF_IT_FOR_ME.to.DO_MOST_OF_IT_FOR_ME",
        defaultMessage: "noop",
      }),
      DO_ALL_OF_IT_FOR_ME: defineMessage({
        id: "page.settings.view.savingsMode.changeServiceLevelPopup.from.DO_MOST_OF_IT_FOR_ME.to.DO_ALL_OF_IT_FOR_ME",
        defaultMessage:
          "If you'd like us to find you savings and handle the hassle of switching for you then you must set your savings mode to Balanced or Freedom.",
      }),
    },
    DO_ALL_OF_IT_FOR_ME: {
      DO_EVERYTHING_MYSELF: defineMessage({
        id: "page.settings.view.savingsMode.changeServiceLevelPopup.from.DO_ALL_OF_IT_FOR_ME.to.DO_EVERYTHING_MYSELF",
        defaultMessage:
          "On Manual mode we won't deal with the hassle of switching providers for you and you'll forfeit cash rewards and access to exclusive deals.",
      }),
      DO_MOST_OF_IT_FOR_ME: defineMessage({
        id: "page.settings.view.savingsMode.changeServiceLevelPopup.from.DO_ALL_OF_IT_FOR_ME.to.DO_MOST_OF_IT_FOR_ME",
        defaultMessage:
          "If you'd like us to find you savings and handle the hassle of switching for you then you must set your savings mode to Balanced or Freedom.",
      }),
      DO_ALL_OF_IT_FOR_ME: defineMessage({
        id: "page.settings.view.savingsMode.changeServiceLevelPopup.from.DO_ALL_OF_IT_FOR_ME.to.DO_ALL_OF_IT_FOR_ME",
        defaultMessage: "noop",
      }),
    },
  }

type ServiceLevelSelectExplaination = Record<
  CheckedRelayEnum<NousServiceLevel>,
  MessageDescriptor
>
export const serviceLevelSelectExplainationMap: ServiceLevelSelectExplaination =
  {
    DO_EVERYTHING_MYSELF: defineMessage({
      id: "page.settings.view.savingsMode.changeServiceLevelPopup.DO_EVERYTHING_MYSELF",
      defaultMessage:
        "On Manual mode we won't deal with the hassle of switching providers for you and you'll forfeit cash rewards and access to exclusive deals.",
    }),
    DO_MOST_OF_IT_FOR_ME: defineMessage({
      id: "page.settings.view.savingsMode.changeServiceLevelPopup.DO_MOST_OF_IT_FOR_ME",
      defaultMessage:
        "If you'd like us to find you savings and handle the hassle of switching for you then you must set your savings mode to Balanced or Freedom.",
    }),
    DO_ALL_OF_IT_FOR_ME: defineMessage({
      id: "page.settings.view.savingsMode.changeServiceLevelPopup.DO_ALL_OF_IT_FOR_ME",
      defaultMessage:
        "If you'd like us to find you savings and handle the hassle of switching for you then you must set your savings mode to Balanced or Freedom.",
    }),
  }

export const serviceLevelChangedSuccess = defineMessage({
  id: "page.settings.view.savingsMode.serviceLevelChanged.success",
  defaultMessage: "Successfully switched to {serviceLevel} mode",
})

export const serviceLevelChangedFailure = defineMessage({
  id: "page.settings.view.savingsMode.serviceLevelChanged.failure",
  defaultMessage: "Failed to switch to {serviceLevel} mode",
})

export const serviceManagementsChangedSuccess = defineMessage({
  id: "page.settings.view.savingsMode.serviceManagementsChanged.success",
  defaultMessage: "Successfully saved preferences",
})

export const serviceManagementsChangedFailure = defineMessage({
  id: "page.settings.view.savingsMode.serviceManagementsChanged.failure",
  defaultMessage: "Failed to save preferences",
})
