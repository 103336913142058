/**
 * @generated SignedSource<<8baa5d879e7d97a65dcb9d6ea313c9c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InflationaryPayRiseCalculatorPageQuery$variables = {
  inflation: number;
};
export type InflationaryPayRiseCalculatorPageQuery$data = {
  readonly members: ReadonlyArray<{
    readonly id: string;
    readonly inflationaryPayriseCalculations: {
      readonly " $fragmentSpreads": FragmentRefs<"InflationaryPayRiseCalculator_initialComputations">;
    } | null | undefined;
    readonly user: {
      readonly isCurrentUser: boolean | null | undefined;
    } | null | undefined;
  }>;
};
export type InflationaryPayRiseCalculatorPageQuery = {
  response: InflationaryPayRiseCalculatorPageQuery$data;
  variables: InflationaryPayRiseCalculatorPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inflation"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCurrentUser",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "inflationPct",
    "variableName": "inflation"
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "precision",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InflationaryPayRiseCalculatorPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HouseholdMember",
        "kind": "LinkedField",
        "name": "members",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "InflationaryPayriseCalculationResult",
            "kind": "LinkedField",
            "name": "inflationaryPayriseCalculations",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InflationaryPayRiseCalculator_initialComputations"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InflationaryPayRiseCalculatorPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HouseholdMember",
        "kind": "LinkedField",
        "name": "members",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "InflationaryPayriseCalculationResult",
            "kind": "LinkedField",
            "name": "inflationaryPayriseCalculations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "percentagePayRise",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "requiredAnnualSalary",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "requiredPayRise",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "currentAnnualSalary",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "currentAnnualSalaryAfterTax",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "newAnnualSalaryAfterTax",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "basedOnInflation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "basedOnSalary",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "880b5a4c10056470d71e41330272a9f0",
    "id": null,
    "metadata": {},
    "name": "InflationaryPayRiseCalculatorPageQuery",
    "operationKind": "query",
    "text": "query InflationaryPayRiseCalculatorPageQuery(\n  $inflation: Float!\n) {\n  members {\n    id\n    user {\n      isCurrentUser\n      id\n    }\n    inflationaryPayriseCalculations(inflationPct: $inflation) {\n      ...InflationaryPayRiseCalculator_initialComputations\n    }\n  }\n}\n\nfragment InflationaryPayRiseCalculator_initialComputations on InflationaryPayriseCalculationResult {\n  ...usePayriseCalculator_calculations\n}\n\nfragment useMoneyToDinero_money on Money {\n  amount\n  precision\n  currency\n}\n\nfragment usePayriseCalculator_calculations on InflationaryPayriseCalculationResult {\n  percentagePayRise\n  requiredAnnualSalary {\n    ...useMoneyToDinero_money\n  }\n  requiredPayRise {\n    ...useMoneyToDinero_money\n  }\n  currentAnnualSalary {\n    ...useMoneyToDinero_money\n  }\n  currentAnnualSalaryAfterTax {\n    ...useMoneyToDinero_money\n  }\n  newAnnualSalaryAfterTax {\n    ...useMoneyToDinero_money\n  }\n  basedOnInflation\n  basedOnSalary {\n    ...useMoneyToDinero_money\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6683e218ff0d2b016192bf868bd0df7";

export default node;
