import React from "react"

import { Link as RouterLink, useNavigate } from "@tanstack/react-router"
import clsx from "clsx"
import { useIntl } from "react-intl"

import { useAuthActions } from "src/auth"
import { useCheckGate } from "src/Gate"
import { GlowFlexbox, GlowIcon, GlowText } from "src/glow"
import useTracking from "src/tracking/useTracking"
import { filterNullsAndFalse } from "src/utils/filterNullsAndFalse"

import AddressNav from "./AddressNav"
import NavAvatar from "./NavAvatar"
import NotificationNav from "./NotificationNav"

import InviteMemberButton from "../InviteMemberButton/InviteMemberButton"
import { Menu } from "../Menu/Menu"

type LinkProps = React.PropsWithChildren<{
  to: string
  activeVariant: "primary" | "secondary"
}>
const Link = ({ to, children, activeVariant }: LinkProps) => {
  const track = useTracking()
  return (
    <RouterLink
      className="text-gray-500/64 flex h-full items-center hover:text-gray-500"
      activeProps={{
        className: clsx(
          "!text-gray-500",
          activeVariant === "secondary"
            ? "shadow-bottom-virtuous-green"
            : "shadow-bottom-glow",
        ),
      }}
      to={to}
      onClick={() => {
        track(["Navigation", "Clicked", { to }])
      }}
    >
      {children}
    </RouterLink>
  )
}
const NavButton = () => {
  return (
    <div
      className="group flex items-center space-x-2"
      data-testid="desktop-menu-button"
    >
      <React.Suspense
        fallback={
          <div className="h-10 w-10 overflow-hidden rounded-full bg-gray-100" />
        }
      >
        <NavAvatar background="yellow" />
      </React.Suspense>
      <GlowIcon
        name="arrow_down_1_regular"
        className="text-off-black-64 h-4 w-4 group-hover:text-gray-500"
      />
    </div>
  )
}

export const DesktopNav = () => {
  const intl = useIntl()
  const track = useTracking()

  const checkGate = useCheckGate()
  const referralsPageEnabled = checkGate("referrals_page")

  const navLinks = filterNullsAndFalse([
    <Link key={"/"} to={"/"} activeVariant="primary">
      <p className="text-base">
        {intl.formatMessage({
          defaultMessage: "Home",
          id: "nav.home",
        })}
      </p>
    </Link>,
    <Link key={"savings"} to={"/savings"} activeVariant="primary">
      <p className="text-base">
        {intl.formatMessage({
          defaultMessage: "Savings",
          id: "nav.savings",
        })}
      </p>
    </Link>,

    <Link key={"tasks"} to={"/quests/"} activeVariant="primary">
      <p className="text-base">
        {intl.formatMessage({
          defaultMessage: "To-do",
          id: "nav.tasks",
        })}
      </p>
    </Link>,

    <Link key={"documents"} to={"/documents/"} activeVariant="primary">
      <p className="text-base">
        {intl.formatMessage({
          defaultMessage: "Documents",
          id: "nav.documents",
        })}
      </p>
    </Link>,

    <Link key={"settings"} to={"/settings/"} activeVariant="primary">
      <p className="text-base">
        {intl.formatMessage({
          defaultMessage: "Settings",
          id: "nav.settings",
        })}
      </p>
    </Link>,
    referralsPageEnabled && (
      <Link key={"referrals"} to={"/referrals/"} activeVariant="secondary">
        <p className="text-base">
          {intl.formatMessage({
            defaultMessage: "Referrals",
            id: "nav.referrals",
          })}
        </p>
      </Link>
    ),
    <div key={"inviteMember"} className="ml-auto">
      <InviteMemberButton />
    </div>,
  ])

  return (
    <GlowFlexbox as="header" direction="column" className="bg-white">
      <GlowFlexbox className="z-header border-off-black-12 border-b">
        <GlowFlexbox
          alignItems="center"
          justifyContent="space-between"
          className="mx-auto h-16 w-full max-w-7xl px-4"
        >
          <RouterLink
            to={"/"}
            onClick={() => track(["Navigation", "Clicked", { to: "/" }])}
          >
            <GlowFlexbox alignItems="start">
              <GlowIcon name="nous_logo" className="h-6 w-[97px]" />
              <GlowText
                size="xs"
                fontWeight="bold"
                className="text-off-black-64 -mt-1 ml-1.5 rounded bg-gray-100 px-1 py-[2px] uppercase"
              >
                {intl.formatMessage({
                  defaultMessage: "BETA",
                  id: "nav.logo.beta",
                })}
              </GlowText>
            </GlowFlexbox>
          </RouterLink>

          <AddressNav />

          <DropdownMenu />
        </GlowFlexbox>
      </GlowFlexbox>
      <GlowFlexbox
        alignItems="center"
        justifyContent="start"
        gap="8"
        className="mx-auto h-16 w-full max-w-7xl px-4"
      >
        {navLinks}
      </GlowFlexbox>
    </GlowFlexbox>
  )
}

const DropdownMenu = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { logOut } = useAuthActions()

  return (
    <GlowFlexbox gap="4" alignItems="center" justifyContent="center">
      <NotificationNav />
      <Menu position="right" button={<NavButton />}>
        <Menu.Item
          onClick={() =>
            navigate({
              to: "/settings/",
            })
          }
        >
          <GlowIcon name="cog_regular" className="h-4 w-4" />
          <GlowText as="p">
            {intl.formatMessage({
              defaultMessage: "Settings",
              id: "nav.desktopDropdownMenu.settings",
            })}
          </GlowText>
        </Menu.Item>

        <Menu.Item
          onClick={async () => {
            await logOut()
          }}
        >
          <GlowIcon
            name="logout_1_regular"
            className="text-angsty-red-500 h-4 w-4"
          />
          <GlowText
            as="p"
            className="text-angsty-red-500"
            data-testid="desktop-logout-button"
          >
            {intl.formatMessage({
              defaultMessage: "Logout",
              id: "nav.desktopDropdownMenu.logout",
            })}
          </GlowText>
        </Menu.Item>
      </Menu>
    </GlowFlexbox>
  )
}
