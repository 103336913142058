import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { ServiceDetailsLoading } from "src/pages/ServiceDetails/components/ServiceDetailsLoading/ServiceDetailsLoading"
import MortgagePageQuery, {
  type MortgagePageQuery as MortgagePageQueryType,
} from "src/pages/ServiceDetails/MortgagePage/__generated__/MortgagePageQuery.graphql"
import { serviceSummaryTypenameToServiceNameRecord } from "src/pages/ServiceDetails/utils/serviceSummaryTypenameToServiceNameRecord"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/mortgage/$id")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context, params }) =>
    loadQuery<MortgagePageQueryType>(
      context.relayEnvironment(),
      MortgagePageQuery,
      {
        id: params.id,
      },
    ),
  pendingComponent: () => (
    <ServiceDetailsLoading
      title={
        serviceSummaryTypenameToServiceNameRecord["MortgageServiceSummary"]
      }
    />
  ),
  component: lazyRouteComponent(
    () => import("src/pages/ServiceDetails/MortgagePage/MortgagePage"),
  ),
})
