import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import DocumentsPageQuery, {
  DocumentsPageQuery as DocumentsPageQueryType,
} from "src/pages/DocumentsPage/__generated__/DocumentsPageQuery.graphql"
import DocumentsPageLayout from "src/pages/DocumentsPage/DocumentsPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/documents/")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  pendingComponent: DocumentsPageLayout,
  loader: ({ context }) =>
    loadQuery<DocumentsPageQueryType>(
      context.relayEnvironment(),
      DocumentsPageQuery,
      {},
    ),
  component: lazyRouteComponent(
    () => import("src/pages/DocumentsPage/DocumentsPage"),
  ),
})
