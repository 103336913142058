import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import SavingsPageQuery, {
  SavingsPageQuery as SavingsPageQueryType,
} from "src/pages/SavingsPage/__generated__/SavingsPageQuery.graphql"
import SavingsPageLayout from "src/pages/SavingsPage/components/SavingsPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/savings")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  pendingComponent: SavingsPageLayout,
  loader: ({ context }) =>
    loadQuery<SavingsPageQueryType>(
      context.relayEnvironment(),
      SavingsPageQuery,
      {},
    ),

  component: lazyRouteComponent(
    () => import("src/pages/SavingsPage/SavingsPage"),
  ),
})
