import { graphql, useFragment } from "react-relay"

import { GlowAvatar, GlowAvatarBackground, GlowAvatarSize } from "src/glow"

import { Avatar_invite$key } from "./__generated__/Avatar_invite.graphql"
import { Avatar_user$key } from "./__generated__/Avatar_user.graphql"

type CommonProps = {
  size?: GlowAvatarSize
  withName?: boolean
  background?: GlowAvatarBackground
}

type Props = CommonProps &
  (
    | {
        user: Avatar_user$key
        invite?: undefined
      }
    | {
        invite: Avatar_invite$key
        user?: undefined
      }
    | { invite?: undefined; user?: undefined }
  )

export default function Avatar(props: Props) {
  const user = useFragment(
    graphql`
      fragment Avatar_user on User {
        initials @required(action: NONE)
        firstName
      }
    `,
    props.user ?? null,
  )
  const invite = useFragment(
    graphql`
      fragment Avatar_invite on HouseholdMemberInvite {
        initials @required(action: NONE)
        firstName
      }
    `,
    props.invite ?? null,
  )
  const result = user ?? invite
  const label = props.withName ? (result?.firstName ?? "") : undefined
  if (result == null) {
    return <GlowAvatar withIcon size={props.size} label={label} />
  }
  return (
    <GlowAvatar
      user={result}
      size={props.size}
      background={
        invite ? "gray" : props.background ? props.background : "gradient"
      }
      label={label}
    />
  )
}
