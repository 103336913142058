import { useIntl } from "react-intl"
import { graphql, useFragment, useMutation } from "react-relay"

import { useAuthActions } from "src/auth"
import { Modal } from "src/components/Modal/Modal"
import { notify } from "src/components/ToastNotification/ToastNotification"
import { GlowButton, GlowLink } from "src/glow"
import { useModal } from "src/hooks/modal"
import useTracking from "src/tracking/useTracking_LEGACY"

import { DeleteMyAccount_household$key } from "./__generated__/DeleteMyAccount_household.graphql"

type DeleteMyAccountProps = {
  household: DeleteMyAccount_household$key
}

export const DeleteMyAccount = ({
  household: householdKey,
}: DeleteMyAccountProps) => {
  const intl = useIntl()
  const track = useTracking()
  const { logOut } = useAuthActions()
  const household = useFragment(
    graphql`
      fragment DeleteMyAccount_household on Household {
        broadbandServiceSummary {
          isManaged
        }
        energyServiceSummary {
          isManaged
        }
        mobileServiceSummaries {
          isManaged
        }
      }
    `,
    householdKey,
  )

  const modal = useModal()

  const [deleteAccount, deleting] = useMutation(graphql`
    mutation DeleteMyAccountMutation {
      deleteMyAccount
    }
  `)

  const mobilesManaged = household?.mobileServiceSummaries?.filter(
    (mobile) => mobile?.isManaged,
  )

  if (
    household.broadbandServiceSummary?.isManaged ||
    household.energyServiceSummary?.isManaged ||
    mobilesManaged?.length
  ) {
    return null
  }

  return (
    <>
      <GlowLink
        onClick={() => {
          track("Clicked delete my account")
          modal.open()
        }}
        className="text-gray-500/64"
        label={intl.formatMessage({
          id: "deleteMyAccount.cta",
          defaultMessage: "Delete account",
        })}
      />
      <Modal
        isOpen={modal.isOpen}
        onClose={modal.close}
        header={intl.formatMessage({
          id: "deleteMyAccount.title",
          defaultMessage: "Delete account, are you sure?",
        })}
      >
        <p className="text-gray-500/64 mb-6">
          {intl.formatMessage(
            {
              id: "deleteMyAccount.content",
              defaultMessage:
                "Unless you are the only member, deleting your account will not delete the household. All members of a household must delete their account in order to delete a household.{br}{br}This action cannot be undone.",
            },
            { br: <br /> },
          )}
        </p>
        <div className="flex flex-col-reverse md:flex-row md:space-x-2">
          <GlowButton
            variant="secondary"
            onClick={modal.close}
            margin={{
              sm: { top: "2" },
              md: { top: "0" },
            }}
            className="w-full"
            label={intl.formatMessage({
              id: "deleteMyAccount.cancel",
              defaultMessage: "Cancel",
            })}
          />
          <GlowButton
            variant="primary-red"
            onClick={() =>
              deleteAccount({
                variables: {},
                onCompleted(_) {
                  logOut().then(() => {
                    window.location.href = window.location.origin
                  })
                },
                onError: (_) => {
                  notify.error("There was an issue deleting your account")
                },
              })
            }
            isLoading={deleting}
            className="w-full"
            label={intl.formatMessage({
              id: "deleteMyAccount.confirm",
              defaultMessage: "Confirm, delete account",
            })}
          />
        </div>
      </Modal>
    </>
  )
}
