import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import useBudget2024SpringModulesQuery, {
  useBudget2024SpringModulesQuery as useBudget2024SpringModulesQueryType,
} from "src/tools/Budget/Budget2024Spring/__generated__/useBudget2024SpringModulesQuery.graphql"

export const Route = createFileRoute("/budget/2024/spring")({
  loader: ({ context }) =>
    loadQuery<useBudget2024SpringModulesQueryType>(
      context.relayEnvironment(),
      useBudget2024SpringModulesQuery,
      {},
    ),
  beforeLoad: ({ context }) => {
    if (context.authState.isAuthenticated) {
      throw redirect({ to: "/tools/budget/2024/spring" })
    }
  },
  component: lazyRouteComponent(
    () => import("src/pages/Budget2024SpringPage/Budget2024SpringPage"),
  ),
})
