import { FormattedMessage, useIntl } from "react-intl"

import { GlowIconName, GlowFlexbox, GlowIcon, GlowText } from "src/glow"

function InfoRow({
  iconName,
  children,
}: {
  iconName: GlowIconName
  children: React.ReactNode
}) {
  return (
    <GlowFlexbox direction="row" gap="3" alignItems="center">
      <GlowIcon
        name={iconName}
        className="text-serene-blue-500 h-6 w-6 shrink-0 grow-0"
      />
      <GlowText size="sm">{children}</GlowText>
    </GlowFlexbox>
  )
}

export function ConnectEmailDisclaimer() {
  const intl = useIntl()

  return (
    <GlowFlexbox
      direction="column"
      gap="4"
      padding={{ x: "4", y: "6" }}
      className="border-grey-400/12 rounded-lg border"
    >
      <InfoRow iconName="login_key_1_bold">
        <FormattedMessage
          id="connectEmailDiscaliemr.infoRow1"
          defaultMessage="We only search for emails sent by a <a>short list of relevant companies.</a>"
          values={{
            a: (chunks) => (
              <a
                target="_blank"
                className="underline"
                href={intl.formatMessage({
                  id: "connectEmailDiscaliemr.infoRow1.link",
                  defaultMessage:
                    "https://nousforyourhouse.notion.site/058dcb76f49447c7832de97a279cb41e?v=8b52716625e34b66873368600a6a4dec",
                })}
                rel="noreferrer"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </InfoRow>
      <InfoRow iconName="saving_safe_1_bold">
        <FormattedMessage
          id="connectEmailDiscaliemr.infoRow2"
          defaultMessage="You approve which documents we forward to your Nous account."
        />
      </InfoRow>
      <InfoRow iconName="check_shield_bold">
        <FormattedMessage
          id="connectEmailDiscaliemr.infoRow3"
          defaultMessage="See how we protect your privacy - <a>read our privacy statement</a>"
          values={{
            a: (chunks) => (
              <a
                target="_blank"
                className="underline"
                href={intl.formatMessage({
                  id: "connectEmailDiscaliemr.infoRow3.link",
                  defaultMessage: "https://www.nous.co/privacy-statement",
                })}
                rel="noreferrer"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </InfoRow>
    </GlowFlexbox>
  )
}
