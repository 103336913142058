import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import BroadbandPageQuery, {
  type BroadbandPageQuery as BroadbandPageQueryType,
} from "src/pages/ServiceDetails/BroadbandPage/__generated__/BroadbandPageQuery.graphql"
import { ServiceDetailsLoading } from "src/pages/ServiceDetails/components/ServiceDetailsLoading/ServiceDetailsLoading"
import { serviceSummaryTypenameToServiceNameRecord } from "src/pages/ServiceDetails/utils/serviceSummaryTypenameToServiceNameRecord"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const searchSchema = z.object({
  bankLogo: z.string().optional(),
  bankName: z.string().optional(),
})

export const Route = createFileRoute("/broadband/$id")({
  validateSearch: searchSchema,
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context, params }) =>
    loadQuery<BroadbandPageQueryType>(
      context.relayEnvironment(),
      BroadbandPageQuery,
      {
        id: params.id,
      },
    ),
  pendingComponent: () => (
    <ServiceDetailsLoading
      title={
        serviceSummaryTypenameToServiceNameRecord["BroadbandServiceSummary"]
      }
    />
  ),
  component: lazyRouteComponent(
    () => import("src/pages/ServiceDetails/BroadbandPage/BroadbandPage"),
  ),
})
