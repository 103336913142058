import React from "react"
type Events = HTMLElementEventMap & WindowEventMap & DocumentEventMap
type ListenerElements = HTMLElement | Window | Document

export default function useEventListener<E extends Events[keyof Events]>({
  event,
  handler,
  element = window,
  capture = false,
}: {
  event: keyof Pick<
    Events,
    { [K in keyof Events]: Events[K] extends E ? K : never }[keyof Events]
  >
  handler: (event: E) => void
  element?: ListenerElements
  capture?: boolean
}) {
  React.useEffect(() => {
    const listenerOptions = { capture }

    element.addEventListener(event, handler as EventListener, listenerOptions)

    return () => {
      element.removeEventListener(
        event,
        handler as EventListener,
        listenerOptions,
      )
    }
  }, [event, handler, element, capture])
}
