import { FormattedMessage, useIntl } from "react-intl"

import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { GlowFlexbox, GlowText } from "src/glow"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export default function DocumentsPageLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const intl = useIntl()

  const pageTitle = intl.formatMessage({
    defaultMessage: "Documents",
    id: "documentsPage.pageTitle",
  })

  const pageDescription = intl.formatMessage({
    defaultMessage: "Documents",
    id: "documentsPage.pageDescription",
  })

  return (
    <>
      <MetaTags title={pageTitle} description={pageDescription} />
      <GlowPageLayout
        mobileNav={{
          top: (
            <GlowFlexbox
              className="bg-white"
              justifyContent="center"
              padding="4"
            >
              {/* eslint-disable-neteral-string-in-jsx */}
              <span className="text-gray-500/64 absolute left-4 top-4 rounded bg-gray-100 p-1 text-xs font-bold">
                BETA
              </span>
              <GlowText as="h1" size="lg">
                <FormattedMessage
                  defaultMessage="Documents"
                  id="documentsPage.mobileNavTitle"
                />
              </GlowText>
            </GlowFlexbox>
          ),
        }}
        desktopNav={<DesktopNav />}
        className="bg-white md:bg-gray-100"
      >
        {children ?? <LoadingPagePlaceholder />}
      </GlowPageLayout>
    </>
  )
}
