import clsx from "clsx"

import {
  GlowBadge,
  GlowFlexbox,
  GlowIcon,
  GlowIconName,
  GlowText,
} from "src/glow"
export type Props = {
  iconName?: GlowIconName
  icon?: React.ReactNode
  title?: string
  iconColourClassName?: string
  subtitle?: string
  badgeText?: string
}
export default function OnboardingContentHeader({
  badgeText,
  icon,
  iconName,
  title,
  subtitle,
  iconColourClassName,
}: Props) {
  return (
    <GlowFlexbox
      direction="column"
      padding={{
        sm: {
          x: "4",
          top: "6",
        },
        md: {
          x: "6",
          top: "6",
        },
      }}
    >
      {badgeText && (
        <GlowBadge
          className="self-center"
          size="sm"
          label={badgeText}
          icon={<GlowIcon name="touch_finger_1_regular" />}
        />
      )}
      {icon || iconName || title || subtitle ? (
        <GlowFlexbox direction="column" gap="4" alignItems="center">
          {iconName ? (
            <GlowIcon
              name={iconName}
              className={clsx(
                "h-8 w-8",
                iconColourClassName ?? "text-gray-500",
              )}
            />
          ) : icon ? (
            icon
          ) : null}
          {subtitle || title ? (
            <GlowFlexbox direction="column" gap="2" alignItems="center">
              {title && (
                <GlowText
                  fontWeight="bold"
                  textAlign="center"
                  size={{ sm: "base", md: "lg" }}
                >
                  {title}
                </GlowText>
              )}
              {subtitle && (
                <GlowText textAlign="center" size={{ sm: "sm", md: "base" }}>
                  {subtitle}
                </GlowText>
              )}
            </GlowFlexbox>
          ) : null}
        </GlowFlexbox>
      ) : null}
    </GlowFlexbox>
  )
}
