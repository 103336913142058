import { CheckedRelayEnum } from "src/types"

export function handleFutureValueOnRelayEnumOrNull<T extends string>(
  value: T | null | undefined,
  defaultValue: CheckedRelayEnum<T> | null,
): CheckedRelayEnum<T> | null {
  return (
    !value || value === "%future added value" ? defaultValue : value
  ) as CheckedRelayEnum<T>
}
