/**
 * @generated SignedSource<<ba0d109b2ceb90a73eb8a8d884ff199f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrencyCode = "GBP" | "%future added value";
export type UpdateFormAnswerAnswerInput = {
  booleanValue?: boolean | null | undefined;
  dateValue?: string | null | undefined;
  defaultAccepted?: boolean | null | undefined;
  floatValue?: number | null | undefined;
  id: string;
  intValue?: number | null | undefined;
  moneyValue?: MoneyInput | null | undefined;
  providerValue?: string | null | undefined;
  stringValue?: string | null | undefined;
};
export type MoneyInput = {
  amount: number;
  currency: CurrencyCode;
  precision: number;
};
export type OnboardingSkipReasonModalUpdateFormAnswerMutation$variables = {
  answer: UpdateFormAnswerAnswerInput;
};
export type OnboardingSkipReasonModalUpdateFormAnswerMutation$data = {
  readonly updateFormAnswers: {
    readonly formAnswers: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"OnboardingSkipReasonModal_formAnswer">;
    }>;
  } | null | undefined;
};
export type OnboardingSkipReasonModalUpdateFormAnswerMutation = {
  response: OnboardingSkipReasonModalUpdateFormAnswerMutation$data;
  variables: OnboardingSkipReasonModalUpdateFormAnswerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "answer"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "answers.0",
        "variableName": "answer"
      }
    ],
    "kind": "ListValue",
    "name": "answers"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingSkipReasonModalUpdateFormAnswerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFormAnswerResponse",
        "kind": "LinkedField",
        "name": "updateFormAnswers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormAnswer",
            "kind": "LinkedField",
            "name": "formAnswers",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OnboardingSkipReasonModal_formAnswer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnboardingSkipReasonModalUpdateFormAnswerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFormAnswerResponse",
        "kind": "LinkedField",
        "name": "updateFormAnswers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormAnswer",
            "kind": "LinkedField",
            "name": "formAnswers",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "095bf8a3e49940e5de5c38c868c71f62",
    "id": null,
    "metadata": {},
    "name": "OnboardingSkipReasonModalUpdateFormAnswerMutation",
    "operationKind": "mutation",
    "text": "mutation OnboardingSkipReasonModalUpdateFormAnswerMutation(\n  $answer: UpdateFormAnswerAnswerInput!\n) {\n  updateFormAnswers(answers: [$answer]) {\n    formAnswers {\n      ...OnboardingSkipReasonModal_formAnswer\n      id\n    }\n  }\n}\n\nfragment OnboardingSkipReasonModal_formAnswer on FormAnswer {\n  id\n}\n"
  }
};
})();

(node as any).hash = "4595120a0ab4f02130d87ca65935c8ac";

export default node;
