/**
 * @generated SignedSource<<4b07bf4c1d2e3244510405958ad47f6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AdminToolbarDebugQuery$variables = Record<PropertyKey, never>;
export type AdminToolbarDebugQuery$data = {
  readonly currentUser: {
    readonly email: string | null | undefined;
    readonly householdMember: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly maybeHousehold: {
    readonly id: string;
  } | null | undefined;
};
export type AdminToolbarDebugQuery = {
  response: AdminToolbarDebugQuery$data;
  variables: AdminToolbarDebugQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HouseholdMember",
        "kind": "LinkedField",
        "name": "householdMember",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Household",
    "kind": "LinkedField",
    "name": "maybeHousehold",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminToolbarDebugQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminToolbarDebugQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "34981953a15b7dff1aea60860942c382",
    "id": null,
    "metadata": {},
    "name": "AdminToolbarDebugQuery",
    "operationKind": "query",
    "text": "query AdminToolbarDebugQuery {\n  currentUser {\n    id\n    email\n    householdMember {\n      id\n    }\n  }\n  maybeHousehold {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ca3943efb6b0b6bb1acc3c2e2c8e758b";

export default node;
