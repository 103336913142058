import clsx from "clsx"
import { format } from "date-fns"
import { FormattedMessage, useIntl } from "react-intl"
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from "react-relay"

import { GlowButton, GlowFlexbox, GlowIcon, GlowText } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"

import { EmailConnectionManager_emailConnection$key } from "./__generated__/EmailConnectionManager_emailConnection.graphql"
import { EmailConnectionManagerQuery } from "./__generated__/EmailConnectionManagerQuery.graphql"
import { EmailConnectionScanStatusIndicator_emailConnection$key } from "./__generated__/EmailConnectionScanStatusIndicator_emailConnection.graphql"
import { EmailConnectionScanStatusIndicator } from "./EmailConnectionScanStatusIndicator"

type EmailConnectionManagerProps = {
  emailConnectionId: string
  isOpen: boolean
  onClose: () => void
}

export const EmailConnectionManager = ({
  emailConnectionId,
  isOpen,
  onClose,
}: EmailConnectionManagerProps) => {
  const intl = useIntl()

  const { emailConnection } = useLazyLoadQuery<EmailConnectionManagerQuery>(
    graphql`
      query EmailConnectionManagerQuery($id: ID!) {
        emailConnection(id: $id) {
          source
          email
          scanStatus
          lastScannedAt
          ...EmailConnectionManager_emailConnection
          ...EmailConnectionScanStatusIndicator_emailConnection
        }
      }
    `,
    { id: emailConnectionId },
  )

  const [scanEmailInbox] = useMutation(graphql`
    mutation EmailConnectionManagerScanMutation($emailConnectionId: ID!) {
      scanEmailInboxForDocuments(emailConnectionId: $emailConnectionId) {
        household {
          ...OnboardingConnectEmailBenefitsStep_household
          emailConnections {
            ...DocumentsPageEmailConnectionsListItem_emailConnection
          }
        }
      }
    }
  `)

  const [disconnectEmailConnection] = useMutation(graphql`
    mutation EmailConnectionManagerDisconnectEmailConnectionMutation($id: ID!) {
      deleteEmailConnection(id: $id) {
        household {
          ...DocumentsPageEmailConnectionsList_household
        }
      }
    }
  `)

  return (
    <GlowModal
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({
        id: "emailConnectionsManager.emailConnectionsList.emailConnectionEditor.title",
        defaultMessage: "Connected account",
      })}
      modalClassName="md:min-w-[25rem]"
    >
      <GlowFlexbox direction="column" gap="6" alignItems="center">
        <GlowFlexbox direction="column" gap="4" alignItems="center">
          <GlowFlexbox direction="column" gap="3" alignItems="center">
            {emailConnection?.source === "GOOGLE" && (
              <GlowIcon name="google" className="h-8 w-8" />
            )}
            {emailConnection?.source === "MICROSOFT" && (
              <GlowIcon name="microsoft" className="h-8 w-8" />
            )}
            <GlowFlexbox direction="column" alignItems="center">
              <GlowText size="xs">
                <FormattedMessage
                  id="emailConnectionsManager.emailConnectionsList.emailConnectionEditor.email"
                  defaultMessage="Connected to:"
                />
              </GlowText>
              <GlowText size="lg">{emailConnection?.email}</GlowText>
            </GlowFlexbox>
          </GlowFlexbox>
          {emailConnection?.scanStatus === "SCAN_FAILED" && (
            <EmailConnectionScanFailedStatusBar
              emailConnection={emailConnection}
            />
          )}
          {emailConnection?.scanStatus === "SCANNING" && (
            <EmailConnectionScanInProgressStatusBar
              emailConnection={emailConnection}
            />
          )}
          {emailConnection?.scanStatus === "SCAN_COMPLETED" &&
            emailConnection?.lastScannedAt && (
              <EmailConnectionScanCompletedStatusBar
                emailConnection={emailConnection}
              />
            )}
        </GlowFlexbox>
        <GlowFlexbox direction="column" gap="4" className="w-full">
          <GlowButton
            label={intl.formatMessage({
              id: "emailConnectionsManager.emailConnectionsList.emailConnectionEditor.scan",
              defaultMessage: "Scan for new documents",
            })}
            size="sm"
            variant="secondary"
            className="w-full"
            onClick={() => {
              scanEmailInbox({
                variables: {
                  emailConnectionId,
                },
                onCompleted: onClose,
              })
            }}
          />

          <button
            className={clsx(
              "text-angsty-red-600 text-sm underline underline-offset-4",
              "transition-opacity hover:opacity-60",
            )}
            onClick={() => {
              disconnectEmailConnection({
                variables: {
                  id: emailConnectionId,
                },
                onCompleted: onClose,
              })
            }}
          >
            <FormattedMessage
              id="emailConnectionsManager.emailConnectionsList.emailConnectionEditor.disconnect"
              defaultMessage="Disconnect account"
            />
          </button>
        </GlowFlexbox>
      </GlowFlexbox>
    </GlowModal>
  )
}

type EmailConnectionScanStatusBarProps = {
  emailConnection: EmailConnectionScanStatusIndicator_emailConnection$key
}
const EmailConnectionScanFailedStatusBar = ({
  emailConnection,
}: EmailConnectionScanStatusBarProps) => {
  return (
    <div className="rounded-full border px-2 py-1.5">
      <GlowFlexbox>
        <EmailConnectionScanStatusIndicator emailConnection={emailConnection} />
        <GlowText size="xs">
          <FormattedMessage
            id="emailConnectionsManager.emailConnectionsList.emailConnectionEditor.status.failed"
            defaultMessage="Scan failed"
          />
        </GlowText>
      </GlowFlexbox>
    </div>
  )
}

const EmailConnectionScanInProgressStatusBar = ({
  emailConnection,
}: EmailConnectionScanStatusBarProps) => {
  return (
    <div className="rounded-full border px-2 py-1.5">
      <GlowFlexbox>
        <EmailConnectionScanStatusIndicator emailConnection={emailConnection} />
        <GlowText size="xs">
          <FormattedMessage
            id="emailConnectionsManager.emailConnectionsList.emailConnectionEditor.status.scanning"
            defaultMessage="Scanning for documents"
          />
        </GlowText>
      </GlowFlexbox>
    </div>
  )
}

type EmailConnectionScanCompletedStatusBarProps = {
  emailConnection: EmailConnectionManager_emailConnection$key
}
const EmailConnectionScanCompletedStatusBar = ({
  emailConnection: emailConnectionKey,
}: EmailConnectionScanCompletedStatusBarProps) => {
  const emailConnection = useFragment(
    graphql`
      fragment EmailConnectionManager_emailConnection on EmailConnection {
        id
        lastScannedAt
        ...EmailConnectionScanStatusIndicator_emailConnection
      }
    `,
    emailConnectionKey,
  )
  return (
    <>
      {emailConnection.lastScannedAt && (
        <div className="rounded-full border px-2 py-1.5">
          <GlowFlexbox>
            <EmailConnectionScanStatusIndicator
              emailConnection={emailConnection}
            />
            <GlowText size="xs" className="ml-1.5">
              {format(
                new Date(emailConnection.lastScannedAt),
                "'Last scanned 'dd MMM yyyy p",
              )}
            </GlowText>
          </GlowFlexbox>
        </div>
      )}
    </>
  )
}
