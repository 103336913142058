/**
 * @generated SignedSource<<5c622f5271d0341fb2ce0ee7e1090aca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useBudget2024AutumnModulesQuery$variables = Record<PropertyKey, never>;
export type useBudget2024AutumnModulesQuery$data = {
  readonly budgetModules: {
    readonly alcohol: boolean | null | undefined;
    readonly benefits: boolean | null | undefined;
    readonly childBenefits: boolean | null | undefined;
    readonly childcare: boolean | null | undefined;
    readonly energy: boolean | null | undefined;
    readonly fuel: boolean | null | undefined;
    readonly incomeTax: boolean | null | undefined;
    readonly minimumWage: boolean | null | undefined;
    readonly nationalInsurance: boolean | null | undefined;
    readonly pension: boolean | null | undefined;
    readonly stealthTax: boolean | null | undefined;
    readonly winterFuelPayment: boolean | null | undefined;
  } | null | undefined;
};
export type useBudget2024AutumnModulesQuery = {
  response: useBudget2024AutumnModulesQuery$data;
  variables: useBudget2024AutumnModulesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "type": "AUTUMN",
          "year": 2024
        }
      }
    ],
    "concreteType": "BudgetModules",
    "kind": "LinkedField",
    "name": "budgetModules",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "alcohol",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "benefits",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "childBenefits",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "childcare",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "energy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fuel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "incomeTax",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimumWage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nationalInsurance",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pension",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stealthTax",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "winterFuelPayment",
        "storageKey": null
      }
    ],
    "storageKey": "budgetModules(input:{\"type\":\"AUTUMN\",\"year\":2024})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useBudget2024AutumnModulesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useBudget2024AutumnModulesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "88a2c71da15d87ac342887c716746719",
    "id": null,
    "metadata": {},
    "name": "useBudget2024AutumnModulesQuery",
    "operationKind": "query",
    "text": "query useBudget2024AutumnModulesQuery {\n  budgetModules(input: {year: 2024, type: AUTUMN}) {\n    alcohol\n    benefits\n    childBenefits\n    childcare\n    energy\n    fuel\n    incomeTax\n    minimumWage\n    nationalInsurance\n    pension\n    stealthTax\n    winterFuelPayment\n  }\n}\n"
  }
};
})();

(node as any).hash = "f904db451323905b8b5744cb99dfc1e1";

export default node;
