import {
  createRootRouteWithContext,
  Outlet,
  ScrollRestoration,
} from "@tanstack/react-router"
import { Environment } from "react-relay"

import { User } from "src/auth"
import { Config } from "src/Gate"
import MaintenancePage from "src/pages/MaintenancePage/MaintenancePage"

type RouteContextAuthState =
  | {
      isAuthenticated: true
      user: User
    }
  | {
      isAuthenticated: false
      user: null
    }

export type RouteContext = {
  relayEnvironment(): Environment
  authState: RouteContextAuthState
  checkGate(gateName: string): boolean
  dynamicConfig(configName: string): Config
}

export const Route = createRootRouteWithContext<RouteContext>()({
  beforeLoad: ({ context, location }) => {
    if (context.checkGate("app_wide_maintenance_page")) {
      if (
        context
          .dynamicConfig("routes_allowed_through_app_wide_maintenance")
          .getBoolean(location.pathname)
      ) {
        return
      }

      throw new Error("maintenance")
    }
  },
  component: () => (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  ),
  errorComponent: () => <MaintenancePage />,
})
