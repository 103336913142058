import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { router } from "src/AppRouter"
import { useUser } from "src/auth"
import CreateAccountPopup from "src/components/CreateAccountPopup/CreateAccountPopup"
import {
  InviteModal,
  useInviteModal,
} from "src/components/InviteModal/InviteModal"
import { GlowCard } from "src/glow"
import { usePopup } from "src/hooks/modal"
import ShareSecondHouseholderInvite from "src/pages/SettingsPage/components/SettingsPageOverview/components/ShareSecondHouseholderInvite/ShareSecondHouseholderInvite"
import useTracking from "src/tracking/useTracking"

import { SettingsPageOverviewShareInvite_household$key } from "./__generated__/SettingsPageOverviewShareInvite_household.graphql"

export const SettingsPageOverviewShareInvite = (props: {
  household: SettingsPageOverviewShareInvite_household$key
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverviewShareInvite_household on Household {
        currentMember {
          joinedHousehold
          user {
            ...ShareSecondHouseholderInvite_currentUser
          }
        }
        address {
          ...CreateAccountPopup_address
        }
      }
    `,
    props.household,
  )

  const user = useUser()
  const track = useTracking()
  const createAccountPopup = usePopup()
  const inviteModal = useInviteModal()

  return (
    <>
      <GlowCard>
        <ShareSecondHouseholderInvite
          currentUser={household.currentMember?.user ?? null}
          onShareViaEmail={() => {
            if (user?.isAnonymous) {
              return createAccountPopup.open()
            }

            track(["Invite", "Started"])
            inviteModal.onOpen()
          }}
        />
      </GlowCard>

      <CreateAccountPopup
        address={household?.address ?? null}
        isOpen={createAccountPopup.isOpen}
        onClose={() => {
          createAccountPopup.close()
          router.history.back()
        }}
      />
      <InviteModal query={inviteModal.query} onClose={inviteModal.onClose} />
    </>
  )
}
