import React from "react"

import { useNavigate, useRouter } from "@tanstack/react-router"
import clsx from "clsx"

import { GlowIcon } from "src/glow"

import NavAvatar from "./NavAvatar"
import NotificationNav from "./NotificationNav"

import { Logo } from "../Logo/Logo"
import { Menu } from "../Menu/Menu"

export type MenuItem = {
  content: React.ReactNode
  onClick: () => void
}

type MobileTopNavMenuProps =
  | {
      showNotificationsButton?: boolean
      dropdownContent?: never
      customMenu?: never
    }
  | {
      showNotificationsButton?: never
      dropdownContent?: MenuItem[]
      customMenu?: never
    }
  | {
      showNotificationsButton?: never
      dropdownContent?: never
      customMenu?: React.ReactElement
    }

type MobileTopNavCommon = {
  variant: "primary" | "secondary"
  backgroundColour?: "white" | "nous-glow" | "transparent"
  showBack?: boolean
  onBack?: () => void
  showAvatar?: boolean
  className?: string
} & MobileTopNavMenuProps

type MobileTopNavPrimary = MobileTopNavCommon & {
  title?: never
  topComponent?: never
}

type MobileTopNavSecondaryWithTitle = MobileTopNavCommon & {
  title: string
  topComponent?: never
}

type MobileTopNavSecondaryWithTopComponent = MobileTopNavCommon & {
  title?: never
  topComponent: React.ReactNode
}

type MobileTopNavProps =
  | MobileTopNavPrimary
  | MobileTopNavSecondaryWithTitle
  | MobileTopNavSecondaryWithTopComponent

export const MobileTopNav = ({
  variant,
  backgroundColour = "transparent",
  showBack,
  onBack,
  showNotificationsButton,
  title,
  topComponent,
  dropdownContent,
  showAvatar,
  customMenu,
  className,
}: MobileTopNavProps) => {
  const router = useRouter()
  const back = () => router.history.back()
  const navigate = useNavigate()

  return (
    <>
      <div
        className={clsx(
          backgroundColour === "white"
            ? "bg-white"
            : backgroundColour === "nous-glow"
              ? "bg-nous-glow-400"
              : backgroundColour === "transparent"
                ? "bg-transparent"
                : "",
          "grid grid-cols-[minmax(24px,_40px)_1fr_minmax(24px,_40px)] gap-4 px-4 py-4",
          className,
        )}
      >
        <div className="col-span-1 flex items-center">
          {showBack ? (
            <button onClick={onBack ?? back}>
              <GlowIcon name="arrow_left_regular" className="h-6 w-6" />
            </button>
          ) : showAvatar ? (
            <button
              onClick={() =>
                navigate({
                  to: "/settings/",
                })
              }
            >
              <React.Suspense
                fallback={
                  <div className="h-10 w-10 overflow-hidden rounded-full bg-gray-100" />
                }
              >
                <NavAvatar background="lightYellow" />
              </React.Suspense>
            </button>
          ) : null}
        </div>
        <div className="col-span-1 flex min-w-full items-center justify-center">
          {variant === "secondary" ? (
            title !== undefined ? (
              <h1 className="mx-auto overflow-hidden overflow-ellipsis whitespace-nowrap text-lg">
                {title}
              </h1>
            ) : (
              <div className="mx-auto whitespace-nowrap">{topComponent}</div>
            )
          ) : (
            <div className="flex items-start">
              <Logo className="h-5" />
            </div>
          )}
        </div>
        <div className="col-span-1 flex justify-end">
          {showNotificationsButton ? (
            <NotificationNav />
          ) : dropdownContent?.length ? (
            <Menu
              button={
                <GlowIcon
                  name="navigation_menu_vertical_regular"
                  className="h-6 w-6"
                />
              }
              position="right"
            >
              {dropdownContent.map((item, index) => (
                <Menu.Item key={index} onClick={item.onClick}>
                  {item.content}
                </Menu.Item>
              ))}
            </Menu>
          ) : customMenu ? (
            <>{customMenu}</>
          ) : null}
        </div>
      </div>
    </>
  )
}
