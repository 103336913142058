import { useIntl } from "react-intl"

import { Logo } from "src/components/Logo/Logo"
import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowLayoutCentered } from "src/glow"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export default function ConnectWhatsAppPageLayout({
  children,
}: {
  children?: React.ReactNode
}) {
  const intl = useIntl()

  return (
    <div className="radial-gradient min-h-full pb-10">
      <MetaTags
        title={intl.formatMessage({
          defaultMessage: "Connect WhatsApp",
          id: "page.connectWhatsApp.title",
        })}
        description={intl.formatMessage({
          defaultMessage: "Connect WhatsApp page",
          id: "page.connectWhatsApp.description",
        })}
      />
      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              backgroundColour="nous-glow"
              topComponent={<Logo className="h-5" />}
              showAvatar
              showNotificationsButton
            />
          ),
        }}
        desktopNav={<DesktopNav />}
      >
        <GlowLayoutCentered variant="narrow">
          {children ?? <LoadingPagePlaceholder />}
        </GlowLayoutCentered>
      </GlowPageLayout>
    </div>
  )
}
