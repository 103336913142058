import React from "react"
import { useEffect, useRef } from "react"

export default function useElementSize<TElement extends HTMLElement>(
  initialSize: { height: number; width: number } = { height: 0, width: 0 },
) {
  const [size, setSize] = React.useState<{
    height: number
    width: number
  }>(initialSize)
  const ref = useRef<TElement>(null)
  useEffect(() => {
    if (!ref.current) {
      return
    }
    const currentRef = ref.current
    const observer = new ResizeObserver(() => {
      setSize({
        height: currentRef.clientHeight,
        width: currentRef.clientWidth,
      })
    })
    observer.observe(currentRef)
    return () => observer.unobserve(currentRef)
  }, [])

  return { ...size, ref }
}
