import React from "react"

import { GlowCard } from "src/glow"

import { SettingsPageComingSoon } from "../SettingsPageComingSoon"

export const SettingsPageMembership = () => {
  return (
    <GlowCard>
      <SettingsPageComingSoon />
    </GlowCard>
  )
}
