import { useSearch } from "@tanstack/react-router"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { useIsB2B_household$key } from "./__generated__/useIsB2B_household.graphql"

export const useIsB2B = (args: {
  household: useIsB2B_household$key | null
  sessionOnly?: boolean
}) => {
  const search = useSearch({ strict: false })

  const household = useFragment(
    graphql`
      fragment useIsB2B_household on Household {
        organization {
          __typename
        }
      }
    `,
    args.household,
  )

  if (args.sessionOnly) {
    return search.organization && search.session
  }

  return Boolean(
    household?.organization ||
      search.organization ||
      search.organizationInvite ||
      search.session,
  )
}
