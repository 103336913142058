import React, { useCallback } from "react"

import { useIntl } from "react-intl"
import { PreloadedQuery, useQueryLoader } from "react-relay"

import { useUser } from "src/auth"
import { GlowFlexbox, GlowText } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import useEffectAfterRouterTransition from "src/hooks/useEffectAfterRouterTransition"
import useTracking from "src/tracking/useTracking_LEGACY"

import InviteModalContentQuery, {
  InviteModalContentQuery as InviteModalContentQueryType,
} from "./__generated__/InviteModalContentQuery.graphql"
import InviteModalContent from "./InviteModalContent"

import { LoadingDots } from "../LoadingDots/LoadingDots"

export function useInviteModal() {
  const [query, load, dispose] = useQueryLoader<InviteModalContentQueryType>(
    InviteModalContentQuery,
  )
  return {
    onOpen() {
      load({})
    },
    onClose() {
      dispose()
    },
    query,
    isOpen: query != null,
  }
}

type InviteUsersModalProps = {
  query: PreloadedQuery<InviteModalContentQueryType> | null | undefined
  onClose: () => void
}

export const InviteModal = ({ query, onClose }: InviteUsersModalProps) => {
  const track = useTracking()

  const user = useUser()

  const isOpen = query != null
  const intl = useIntl()
  useEffectAfterRouterTransition(
    useCallback(() => {
      if (user?.isAnonymous && isOpen) {
        track("Create account popup opened", {
          trigger: "Invite",
        })
      }
    }, [isOpen, track, user?.isAnonymous]),
  )

  return (
    <GlowModal
      title={intl.formatMessage({
        id: "inviteModal.title",
        defaultMessage: "Invite a household member",
      })}
      isOpen={isOpen}
      onClose={onClose}
    >
      <GlowFlexbox direction="column" gap="6">
        <GlowText as="p" size="sm">
          {intl.formatMessage({
            id: "inviteModal.description",
            defaultMessage:
              "Invite household members who pay bills so they can also see, monitor and manage all your providers and services in one place.",
          })}
        </GlowText>

        {query && (
          <React.Suspense fallback={<LoadingDots />}>
            <InviteModalContent query={query} />
          </React.Suspense>
        )}
      </GlowFlexbox>
    </GlowModal>
  )
}
