import { FormattedMessage } from "react-intl"

import { GlowFlexbox, GlowText } from "src/glow"

import nousAi from "./assets/nousAi.png"
import nousAi2x from "./assets/nousAi@2x.png"
import nousAi3x from "./assets/nousAi@3x.png"

type Props = {
  showText?: boolean
}

export function PoweredByAi({ showText = true }: Props) {
  return (
    <GlowFlexbox
      direction="row"
      gap="1"
      alignItems="center"
      justifyContent="center"
    >
      {showText && (
        <GlowText size="sm" className="mr-1">
          <FormattedMessage
            id="documentsPage.logo.poweredBy"
            defaultMessage="Powered by"
          />
        </GlowText>
      )}
      <img
        srcSet={`${nousAi}, ${nousAi2x} 2x, ${nousAi3x} 3x`}
        alt=""
        className="h-4"
      />
    </GlowFlexbox>
  )
}
