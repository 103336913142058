/**
 * @generated SignedSource<<d9e1d54c8086db6bf2d08af910495020>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BankAccountDetailsCard_bankAccount$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BankAccount" | "BankAccountAddOrEditPopup_bankAccount">;
  readonly " $fragmentType": "BankAccountDetailsCard_bankAccount";
};
export type BankAccountDetailsCard_bankAccount$key = {
  readonly " $data"?: BankAccountDetailsCard_bankAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"BankAccountDetailsCard_bankAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BankAccountDetailsCard_bankAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BankAccountAddOrEditPopup_bankAccount"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BankAccount"
    }
  ],
  "type": "BankAccount",
  "abstractKey": null
};

(node as any).hash = "d0d5517bb042b92ab131d617a12ec99a";

export default node;
