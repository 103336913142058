import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import BroadbandRecommendationPageQuery, {
  BroadbandRecommendationPageQuery as BroadbandRecommendationPageQueryType,
} from "src/pages/Recommendations/BroadbandRecommendationPage/__generated__/BroadbandRecommendationPageQuery.graphql"

export const Route = createFileRoute("/recommendation/broadband/$id")({
  loader: ({ context, params }) =>
    loadQuery<BroadbandRecommendationPageQueryType>(
      context.relayEnvironment(),
      BroadbandRecommendationPageQuery,
      {
        dealCardId: params.id,
        skipHousehold: !context.authState.isAuthenticated,
      },
    ),
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/Recommendations/BroadbandRecommendationPage/BroadbandRecommendationPage"
      ),
  ),
})
