/**
 * @generated SignedSource<<571e0fb60d97e3165f7cda38c9506f00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteMyAccount_household$data = {
  readonly broadbandServiceSummary: {
    readonly isManaged: boolean | null | undefined;
  } | null | undefined;
  readonly energyServiceSummary: {
    readonly isManaged: boolean | null | undefined;
  } | null | undefined;
  readonly mobileServiceSummaries: ReadonlyArray<{
    readonly isManaged: boolean | null | undefined;
  }>;
  readonly " $fragmentType": "DeleteMyAccount_household";
};
export type DeleteMyAccount_household$key = {
  readonly " $data"?: DeleteMyAccount_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteMyAccount_household">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isManaged",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteMyAccount_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BroadbandServiceSummary",
      "kind": "LinkedField",
      "name": "broadbandServiceSummary",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EnergyServiceSummary",
      "kind": "LinkedField",
      "name": "energyServiceSummary",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MobileServiceSummary",
      "kind": "LinkedField",
      "name": "mobileServiceSummaries",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};
})();

(node as any).hash = "7bbfc4275d356caa7b5c84b067d95b9f";

export default node;
