import { CheckedRelayEnum } from "src/types"

export function handleFutureValueOnRelayEnum<T extends string>(
  value: T | null | undefined,
  defaultValue: CheckedRelayEnum<T>,
): CheckedRelayEnum<T> {
  return (
    !value || value === "%future added value" ? defaultValue : value
  ) as CheckedRelayEnum<T>
}
