import { MessageDescriptor, defineMessage } from "react-intl"

import { VIEW } from "./SettingsPage"

export const titleMap: Record<VIEW, MessageDescriptor> = {
  overview: defineMessage({
    id: "page.settings.title.overview",
    defaultMessage: "Settings",
  }),
  membership: defineMessage({
    id: "page.settings.title.membership",
    defaultMessage: "Membership details",
  }),
  "savings-mode": defineMessage({
    id: "page.settings.title.savingsMode",
    defaultMessage: "Savings mode",
  }),
  account: defineMessage({
    id: "page.settings.title.account",
    defaultMessage: "Account details",
  }),
  members: defineMessage({
    id: "page.settings.title.members",
    defaultMessage: "Household members",
  }),
  "bank-accounts": defineMessage({
    id: "page.settings.title.bankAccounts",
    defaultMessage: "Bank accounts",
  }),
  inboxes: defineMessage({
    id: "page.settings.title.inboxes",
    defaultMessage: "Household inboxes",
  }),
  notifications: defineMessage({
    id: "page.settings.title.notifications",
    defaultMessage: "Notifications",
  }),
}
