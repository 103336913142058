import { FormattedNumber } from "react-intl"

import {
  GlowCard,
  GlowFlexbox,
  GlowIcon,
  GlowIconName,
  GlowText,
} from "src/glow"

export const SettingsPageOverviewGenericSection = (props: {
  icon: GlowIconName
  title: string
  value?: number
  subtitle?: string
  onClick: () => void
  children?: React.ReactNode
}) => {
  return (
    <button className="group" onClick={props.onClick}>
      <GlowCard>
        <GlowFlexbox justifyContent="space-between" alignItems="center">
          <GlowFlexbox direction="column" gap="3" alignItems="start">
            <GlowFlexbox gap="3" alignItems="center">
              <GlowIcon name={props.icon} className="h-auto w-5" />

              <GlowText fontWeight="bold" className="group-hover:underline">
                {props.title}
              </GlowText>

              {typeof props.value === "number" && (
                <GlowText fontWeight="bold" className="text-gray-300">
                  <FormattedNumber value={props.value} />
                </GlowText>
              )}
            </GlowFlexbox>

            {props.subtitle && <GlowText size="sm">{props.subtitle}</GlowText>}

            {props.children}
          </GlowFlexbox>

          <GlowIcon
            name="arrow_right_1_regular"
            className="text-gray-500/64 h-5 w-5 group-hover:text-gray-500"
          />
        </GlowFlexbox>
      </GlowCard>
    </button>
  )
}
