import { useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import Avatar from "src/components/Avatar/Avatar"
import { GlowFlexbox } from "src/glow"
import { titleMap } from "src/pages/SettingsPage/utils"

import { SettingsPageOverviewMembers_household$key } from "./__generated__/SettingsPageOverviewMembers_household.graphql"
import { SettingsPageOverviewGenericSection } from "./SettingsPageOverviewGenericSection"

export const SettingsPageOverviewMembers = (props: {
  household: SettingsPageOverviewMembers_household$key
  onGoToMembersView: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverviewMembers_household on Household {
        members {
          id
          user {
            ...Avatar_user
          }
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  return (
    <SettingsPageOverviewGenericSection
      icon={"multiple_neutral_2_regular"}
      title={intl.formatMessage(titleMap.members)}
      subtitle={intl.formatMessage({
        id: "page.settings.view.overview.membersActionDescription",
        defaultMessage: "Add or manage other bill payers in your household",
      })}
      onClick={props.onGoToMembersView}
    >
      <GlowFlexbox gap="2" alignItems="center">
        {household.members.map(
          (member) =>
            member.user && <Avatar key={member.id} user={member.user} />,
        )}
      </GlowFlexbox>
    </SettingsPageOverviewGenericSection>
  )
}
