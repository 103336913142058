import { FormattedMessage } from "react-intl"

import { GlowFlexbox, GlowText } from "src/glow"

import eggTimer from "../assets/eggTimer.png"
import eggTimer2x from "../assets/eggTimer@2x.png"
import eggTimer3x from "../assets/eggTimer@3x.png"

export const SettingsPageComingSoon = () => {
  return (
    <GlowFlexbox
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap="2"
    >
      <img
        srcSet={`${eggTimer}, ${eggTimer2x} 2x, ${eggTimer3x} 3x`}
        alt=""
        className="h-auto w-12"
      />
      <GlowText>
        <FormattedMessage
          id="page.settings.comingSoon"
          defaultMessage="Coming soon!"
        />
      </GlowText>
    </GlowFlexbox>
  )
}
