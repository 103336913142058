import clsx from "clsx"

import bcorp from "src/assets/bcorp.png"
import bcorp2x from "src/assets/bcorp@2x.png"
import bcorp3x from "src/assets/bcorp@3x.png"
import trustpilotMicroStarsExcellent from "src/assets/trustpilotMicroStarsExcellent.png"
import trustpilotMicroStarsExcellent2x from "src/assets/trustpilotMicroStarsExcellent@2x.png"
import trustpilotMicroStarsExcellent3x from "src/assets/trustpilotMicroStarsExcellent@3x.png"
import { GlowCard, GlowFlexbox } from "src/glow"

export const NousCertifications = ({ className }: { className?: string }) => {
  return (
    <GlowCard colour="transparent" className={clsx("space-y-4", className)}>
      <GlowFlexbox gap="12" justifyContent="center" alignItems="center">
        <img
          srcSet={`${trustpilotMicroStarsExcellent}, ${trustpilotMicroStarsExcellent2x} 2x, ${trustpilotMicroStarsExcellent3x} 3x`}
          alt=""
          className="w-[75px]"
        />
        <img
          srcSet={`${bcorp}, ${bcorp2x} 2x, ${bcorp3x} 3x`}
          alt=""
          className="w-[30px]"
        />
      </GlowFlexbox>
    </GlowCard>
  )
}
