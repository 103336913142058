import React, { useMemo } from "react"

import {
  AnyRoute,
  RouterConstructorOptions,
  RouterProvider,
  TrailingSlashOption,
  createRouter,
} from "@tanstack/react-router"
import { useRelayEnvironment } from "react-relay"

import { useUser } from "./auth"
import { useCheckGate, useDynamicConfig } from "./Gate"
import LoadingPagePlaceholder from "./LoadingPagePlaceholder"
import FatalErrorPage from "./pages/FatalErrorPage/FatalErrorPage"
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage"
import { routeTree } from "./routeTree.gen"

const options: RouterConstructorOptions<
  AnyRoute,
  TrailingSlashOption,
  Record<string, unknown>,
  Record<string, unknown>
> = {
  routeTree,
  defaultPendingMs: 0,
  defaultPendingMinMs: 0,
  defaultPendingComponent: () => <LoadingPagePlaceholder />,
  defaultNotFoundComponent: NotFoundPage,
  defaultErrorComponent: FatalErrorPage,
}
// Create a new router instance
export const router = createRouter({
  context: {
    relayEnvironment() {
      throw new Error("relayEnvironment not provided")
    },
    authState: {
      isAuthenticated: false,
      user: null,
    },
    checkGate() {
      return false
    },
  },
  ...options,
})

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}

export default function Router() {
  const user = useUser()
  const environment = useRelayEnvironment()
  const checkGate = useCheckGate()
  const dynamicConfig = useDynamicConfig()

  const router = useMemo(
    () =>
      createRouter({
        context: {
          relayEnvironment() {
            return environment
          },
          authState: user
            ? {
                isAuthenticated: true,
                user: user,
              }
            : {
                isAuthenticated: false,
                user,
              },
          checkGate,
          dynamicConfig,
        },
        ...options,
      }),
    [user, environment, checkGate, dynamicConfig],
  )
  return <RouterProvider router={router} />
}
