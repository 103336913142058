import { ReactNode } from "react"

import { useIntl } from "react-intl"

import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowLayoutCentered } from "src/glow"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

type ReferralsPageLayoutProps = {
  children: ReactNode
}

const ReferralsPageLayout = ({ children }: ReferralsPageLayoutProps) => {
  const intl = useIntl()

  return (
    <div className="bg-virtuous-green-200 h-full">
      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              title={intl.formatMessage({
                id: "referralsPage.mobileNav.title",
                defaultMessage: "Referrals",
              })}
            />
          ),
        }}
        desktopNav={<DesktopNav />}
      >
        <GlowLayoutCentered variant="wide" className="mx-4 md:mt-10">
          {children ?? <LoadingPagePlaceholder />}
        </GlowLayoutCentered>
      </GlowPageLayout>
    </div>
  )
}

export default ReferralsPageLayout
