/**
 * @generated SignedSource<<61d177c74adea8d7978096b095d131fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection">;
  readonly " $fragmentType": "OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections";
}>;
export type OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections$key = ReadonlyArray<{
  readonly " $data"?: OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection"
    }
  ],
  "type": "EmailConnection",
  "abstractKey": null
};

(node as any).hash = "28cbd48de9a92161de35c988b243375d";

export default node;
