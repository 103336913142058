/**
 * @generated SignedSource<<6e9067a931cb797d061c56ac54cdb762>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageSubPageInboxes_household$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingConnectEmailBenefitsStepContent_household" | "OnboardingConnectEmailStepInitialContent_household">;
  readonly " $fragmentType": "SettingsPageSubPageInboxes_household";
};
export type SettingsPageSubPageInboxes_household$key = {
  readonly " $data"?: SettingsPageSubPageInboxes_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageSubPageInboxes_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageSubPageInboxes_household",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingConnectEmailBenefitsStepContent_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingConnectEmailStepInitialContent_household"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "5210e9066368f04618911970ed9341e0";

export default node;
