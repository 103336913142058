import { SupportedCategory, SupportedManagement } from "src/types"

export const categoryManagementNameMap: Record<
  SupportedManagement,
  SupportedCategory
> = {
  BroadbandManagement: "Broadband",
  EnergyManagement: "Energy",
  MobileManagement: "Mobile",
  MortgageManagement: "Mortgage",
}
