/**
 * @generated SignedSource<<b3836fafb22ceb6e01c39a745d7cdcde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EmailConnectionScanStatus = "SCANNING" | "SCAN_COMPLETED" | "SCAN_FAILED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EmailConnectionScanStatusIndicator_emailConnection$data = {
  readonly id: string;
  readonly scanStatus: EmailConnectionScanStatus | null | undefined;
  readonly " $fragmentType": "EmailConnectionScanStatusIndicator_emailConnection";
};
export type EmailConnectionScanStatusIndicator_emailConnection$key = {
  readonly " $data"?: EmailConnectionScanStatusIndicator_emailConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailConnectionScanStatusIndicator_emailConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailConnectionScanStatusIndicator_emailConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scanStatus",
      "storageKey": null
    }
  ],
  "type": "EmailConnection",
  "abstractKey": null
};

(node as any).hash = "d2840cbf7be8ca205516a803e2d4d8e8";

export default node;
