import { useIntl } from "react-intl"
import { graphql, useFragment, useMutation } from "react-relay"

import { GlowButton, GlowFlexbox, GlowText } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import { logger } from "src/logger"

import { OnboardingSkipReasonModal_formAnswer$key } from "./__generated__/OnboardingSkipReasonModal_formAnswer.graphql"
import { OnboardingSkipReasonModalUpdateFormAnswerMutation } from "./__generated__/OnboardingSkipReasonModalUpdateFormAnswerMutation.graphql"

type Props = {
  isOpen: boolean
  onClose: () => void
  reasons: string[]
  onSelectReason: (reason: string) => void
  formAnswer: OnboardingSkipReasonModal_formAnswer$key
}

export const OnboardingSkipReasonModal = (props: Props) => {
  const intl = useIntl()

  const title = intl.formatMessage({
    id: "induction.skipReasons.title",
    defaultMessage: "Help us understand why",
  })

  const formAnswer = useFragment(
    graphql`
      fragment OnboardingSkipReasonModal_formAnswer on FormAnswer {
        id
      }
    `,
    props.formAnswer,
  )

  const [updateFormAnswer] =
    useMutation<OnboardingSkipReasonModalUpdateFormAnswerMutation>(graphql`
      mutation OnboardingSkipReasonModalUpdateFormAnswerMutation(
        $answer: UpdateFormAnswerAnswerInput!
      ) {
        updateFormAnswers(answers: [$answer]) {
          formAnswers {
            ...OnboardingSkipReasonModal_formAnswer
          }
        }
      }
    `)

  return (
    <GlowModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={title}
      hideDrawerTitle
    >
      <GlowFlexbox direction="column" gap="4" alignItems="center">
        <GlowText fontWeight="bold" className="block md:hidden">
          {title}
        </GlowText>
        <GlowFlexbox direction="column" gap="3">
          {props.reasons.map((reason, index) => (
            <GlowButton
              key={index}
              variant="tertiary"
              label={reason}
              onClick={() => {
                updateFormAnswer({
                  variables: {
                    answer: {
                      id: formAnswer.id,
                      stringValue: reason,
                    },
                  },
                  onCompleted: () => {
                    props.onSelectReason(reason)
                  },
                  onError: (error) => {
                    logger.error("Error saving skip reason", error)
                    props.onSelectReason(reason)
                  },
                })
              }}
              size="sm"
              className="w-full"
            />
          ))}
        </GlowFlexbox>
      </GlowFlexbox>
    </GlowModal>
  )
}
