/**
 * @generated SignedSource<<13683bafd120e3a02801bf277101ff6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ShareSecondHouseholderInviteMutation$variables = Record<PropertyKey, never>;
export type ShareSecondHouseholderInviteMutation$data = {
  readonly retrieveOrCreateShareableInvite: {
    readonly invite: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type ShareSecondHouseholderInviteMutation = {
  response: ShareSecondHouseholderInviteMutation$data;
  variables: ShareSecondHouseholderInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RetrieveOrCreateShareableInviteResponse",
    "kind": "LinkedField",
    "name": "retrieveOrCreateShareableInvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HouseholdMemberInvite",
        "kind": "LinkedField",
        "name": "invite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ShareSecondHouseholderInviteMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ShareSecondHouseholderInviteMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "609a9c61cd75432343c2f632bfd2d8b3",
    "id": null,
    "metadata": {},
    "name": "ShareSecondHouseholderInviteMutation",
    "operationKind": "mutation",
    "text": "mutation ShareSecondHouseholderInviteMutation {\n  retrieveOrCreateShareableInvite {\n    invite {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "519a2764f43944f6f2d8309a36eef3ae";

export default node;
