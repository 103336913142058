/**
 * @generated SignedSource<<b5c320338f8357ca73f57854d93176fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingWhatsAppPageQuery$variables = Record<PropertyKey, never>;
export type OnboardingWhatsAppPageQuery$data = {
  readonly currentUser: {
    readonly phoneNumber: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"OnboardingVerifyWhatsAppStep_user">;
  } | null | undefined;
  readonly household: {
    readonly formAnswers: {
      readonly whatsAppConfirmationSkipReason: {
        readonly " $fragmentSpreads": FragmentRefs<"OnboardingVerifyWhatsAppStep_formAnswer">;
      } | null | undefined;
    } | null | undefined;
    readonly isDelegated: boolean | null | undefined;
    readonly mobileServiceSummary: {
      readonly formAnswers: {
        readonly mobilePhoneNumber: {
          readonly stringValue: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  };
};
export type OnboardingWhatsAppPageQuery = {
  response: OnboardingWhatsAppPageQuery$data;
  variables: OnboardingWhatsAppPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDelegated",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "key",
    "value": "mobile.phoneNumber"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stringValue",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "key",
    "value": "whatsAppConfirmation.skipReason"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingWhatsAppPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MobileServiceSummary",
            "kind": "LinkedField",
            "name": "mobileServiceSummary",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MobileServiceSummaryFormAnswers",
                "kind": "LinkedField",
                "name": "formAnswers",
                "plural": false,
                "selections": [
                  {
                    "alias": "mobilePhoneNumber",
                    "args": (v1/*: any*/),
                    "concreteType": "FormAnswer",
                    "kind": "LinkedField",
                    "name": "answer",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": "answer(key:\"mobile.phoneNumber\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdFormAnswers",
            "kind": "LinkedField",
            "name": "formAnswers",
            "plural": false,
            "selections": [
              {
                "alias": "whatsAppConfirmationSkipReason",
                "args": (v3/*: any*/),
                "concreteType": "FormAnswer",
                "kind": "LinkedField",
                "name": "answer",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "OnboardingVerifyWhatsAppStep_formAnswer"
                  }
                ],
                "storageKey": "answer(key:\"whatsAppConfirmation.skipReason\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OnboardingVerifyWhatsAppStep_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OnboardingWhatsAppPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MobileServiceSummary",
            "kind": "LinkedField",
            "name": "mobileServiceSummary",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MobileServiceSummaryFormAnswers",
                "kind": "LinkedField",
                "name": "formAnswers",
                "plural": false,
                "selections": [
                  {
                    "alias": "mobilePhoneNumber",
                    "args": (v1/*: any*/),
                    "concreteType": "FormAnswer",
                    "kind": "LinkedField",
                    "name": "answer",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": "answer(key:\"mobile.phoneNumber\")"
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdFormAnswers",
            "kind": "LinkedField",
            "name": "formAnswers",
            "plural": false,
            "selections": [
              {
                "alias": "whatsAppConfirmationSkipReason",
                "args": (v3/*: any*/),
                "concreteType": "FormAnswer",
                "kind": "LinkedField",
                "name": "answer",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": "answer(key:\"whatsAppConfirmation.skipReason\")"
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEngagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isWhatsAppConfirmed",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1dcf3eaeb43e4ba6157c7a3b5f4d0ac",
    "id": null,
    "metadata": {},
    "name": "OnboardingWhatsAppPageQuery",
    "operationKind": "query",
    "text": "query OnboardingWhatsAppPageQuery {\n  household {\n    isDelegated\n    mobileServiceSummary {\n      formAnswers {\n        mobilePhoneNumber: answer(key: \"mobile.phoneNumber\") {\n          stringValue\n          id\n        }\n      }\n      id\n    }\n    formAnswers {\n      whatsAppConfirmationSkipReason: answer(key: \"whatsAppConfirmation.skipReason\") {\n        ...OnboardingVerifyWhatsAppStep_formAnswer\n        id\n      }\n    }\n    id\n  }\n  currentUser {\n    phoneNumber\n    ...OnboardingVerifyWhatsAppStep_user\n    id\n  }\n}\n\nfragment OnboardingSkipReasonModal_formAnswer on FormAnswer {\n  id\n}\n\nfragment OnboardingVerifyWhatsAppStep_formAnswer on FormAnswer {\n  ...OnboardingSkipReasonModal_formAnswer\n}\n\nfragment OnboardingVerifyWhatsAppStep_user on User {\n  ...useUserEngagementRefetch_user\n  engagement {\n    isWhatsAppConfirmed\n  }\n}\n\nfragment useUserEngagementRefetch_user on User {\n  id\n  engagement {\n    isWhatsAppConfirmed\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0ded66a6896efbbdbec880fec7e072d";

export default node;
