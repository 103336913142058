import { useMemo } from "react"

import { useRouterState } from "@tanstack/react-router"

export default function useQueryParameters() {
  const searchStr = useRouterState({
    select: (state) => state.location.searchStr,
  })
  return useMemo(() => {
    const search = new URLSearchParams(searchStr)
    return search
  }, [searchStr])
}
