import ImageHammock from "src/assets/Hammock.svg"
import ImageHighFive from "src/assets/HighFive.svg"
import ImagePuzzleWoman from "src/assets/PuzzleWoman.svg"
import { NousServiceLevel } from "src/hooks/__generated__/useRedirectToWaitlist_household.graphql"
import { CheckedRelayEnum } from "src/types"

export const serviceLevelImageMap: Record<
  CheckedRelayEnum<NousServiceLevel>,
  string
> = {
  DO_EVERYTHING_MYSELF: ImagePuzzleWoman,
  DO_MOST_OF_IT_FOR_ME: ImageHighFive,
  DO_ALL_OF_IT_FOR_ME: ImageHammock,
}
