/**
 * @generated SignedSource<<00938ea4c351e626aad1c6b4f8ae59c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageSubPageMembersCancelInviteMutation$variables = {
  id: string;
};
export type SettingsPageSubPageMembersCancelInviteMutation$data = {
  readonly cancelHouseholdInviteV2: {
    readonly household: {
      readonly " $fragmentSpreads": FragmentRefs<"SettingsPageSubPageMembers_household">;
    } | null | undefined;
  } | null | undefined;
};
export type SettingsPageSubPageMembersCancelInviteMutation = {
  response: SettingsPageSubPageMembersCancelInviteMutation$data;
  variables: SettingsPageSubPageMembersCancelInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsPageSubPageMembersCancelInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelHouseholdInviteV2Response",
        "kind": "LinkedField",
        "name": "cancelHouseholdInviteV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Household",
            "kind": "LinkedField",
            "name": "household",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SettingsPageSubPageMembers_household"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsPageSubPageMembersCancelInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelHouseholdInviteV2Response",
        "kind": "LinkedField",
        "name": "cancelHouseholdInviteV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Household",
            "kind": "LinkedField",
            "name": "household",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HouseholdMember",
                "kind": "LinkedField",
                "name": "members",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HouseholdMemberInvite",
                "kind": "LinkedField",
                "name": "pendingInvites",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc80cdc66ee2e2df229bf8cf9b0a1927",
    "id": null,
    "metadata": {},
    "name": "SettingsPageSubPageMembersCancelInviteMutation",
    "operationKind": "mutation",
    "text": "mutation SettingsPageSubPageMembersCancelInviteMutation(\n  $id: ID!\n) {\n  cancelHouseholdInviteV2(id: $id) {\n    household {\n      ...SettingsPageSubPageMembers_household\n      id\n    }\n  }\n}\n\nfragment Avatar_invite on HouseholdMemberInvite {\n  initials\n  firstName\n}\n\nfragment Avatar_user on User {\n  initials\n  firstName\n}\n\nfragment InviteModalPendingInvite_invite on HouseholdMemberInvite {\n  ...Avatar_invite\n  displayName\n  email\n}\n\nfragment SettingsPageSubPageMembers_household on Household {\n  members {\n    id\n    user {\n      ...Avatar_user\n      firstName\n      lastName\n      id\n      email\n    }\n  }\n  pendingInvites {\n    id\n    ...Avatar_invite\n    firstName\n    lastName\n    email\n    ...InviteModalPendingInvite_invite\n  }\n}\n"
  }
};
})();

(node as any).hash = "48ae2bafb6ed558b2cd28307cdced31d";

export default node;
