import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import { ServiceDetailsLoading } from "src/pages/ServiceDetails/components/ServiceDetailsLoading/ServiceDetailsLoading"
import TelevisionPageQuery, {
  type TelevisionPageQuery as TelevisionPageQueryType,
} from "src/pages/ServiceDetails/TelevisionPage/__generated__/TelevisionPageQuery.graphql"
import { serviceSummaryTypenameToServiceNameRecord } from "src/pages/ServiceDetails/utils/serviceSummaryTypenameToServiceNameRecord"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const searchSchema = z.object({
  bankLogo: z.string().optional(),
  bankName: z.string().optional(),
})

export const Route = createFileRoute("/television")({
  validateSearch: searchSchema,
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) => {
    return loadQuery<TelevisionPageQueryType>(
      context.relayEnvironment(),
      TelevisionPageQuery,
      {},
    )
  },
  pendingComponent: () => (
    <ServiceDetailsLoading
      title={
        serviceSummaryTypenameToServiceNameRecord["TelevisionServiceSummary"]
      }
    />
  ),
  component: lazyRouteComponent(
    () => import("src/pages/ServiceDetails/TelevisionPage/TelevisionPage"),
  ),
})
