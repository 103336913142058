import { defineMessage, MessageDescriptor } from "react-intl"

import { CheckedRelayEnum } from "src/types"

import { UndelegationReason } from "../../../__generated__/SettingsPageSavingsModeUpdateHouseholdSavingsModeMutation.graphql"

const undelegationReasonToCheckboxLabelRecord: Record<
  CheckedRelayEnum<UndelegationReason>,
  MessageDescriptor
> = {
  WANT_MORE_CONTROL: defineMessage({
    id: "undelegationReason.option.wantMoreControl",
    defaultMessage: "I want more control over my household bills",
  }),
  DID_NOT_LIKE_PROPOSED_DEALS: defineMessage({
    id: "undelegationReason.option.dislikeProposedDeals",
    defaultMessage: "I didn't like the deals Nous proposed",
  }),
  DID_NOT_UNDERSTAND_DELEGATION: defineMessage({
    id: "undelegationReason.option.didNotUnderstandDelegation",
    defaultMessage:
      "I didn't understand how {serviceLevel} mode worked when I chose it",
  }),
  DOES_NOT_TRUST_NOUS: defineMessage({
    id: "undelegationReason.option.doesNotTrust",
    defaultMessage: "I don't trust Nous to manage my bills for me",
  }),
  OTHER: defineMessage({
    id: "undelegationReason.option.other",
    defaultMessage: "Other",
  }),
}

export default undelegationReasonToCheckboxLabelRecord
