/**
 * @generated SignedSource<<06f343521194e8738ad899205a2d9661>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingConnectEmailBenefitsStepContent_household$data = {
  readonly emailConnections: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections">;
  }>;
  readonly formAnswers: {
    readonly connectEmailBenefits: {
      readonly booleanValue: boolean | null | undefined;
      readonly id: string;
      readonly key: string;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"useIsB2B_household">;
  readonly " $fragmentType": "OnboardingConnectEmailBenefitsStepContent_household";
};
export type OnboardingConnectEmailBenefitsStepContent_household$key = {
  readonly " $data"?: OnboardingConnectEmailBenefitsStepContent_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingConnectEmailBenefitsStepContent_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingConnectEmailBenefitsStepContent_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdFormAnswers",
      "kind": "LinkedField",
      "name": "formAnswers",
      "plural": false,
      "selections": [
        {
          "alias": "connectEmailBenefits",
          "args": [
            {
              "kind": "Literal",
              "name": "key",
              "value": "connectEmailBenefits"
            }
          ],
          "concreteType": "FormAnswer",
          "kind": "LinkedField",
          "name": "answer",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              "action": "THROW",
              "path": "formAnswers.connectEmailBenefits.key"
            },
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              "action": "THROW",
              "path": "formAnswers.connectEmailBenefits.id"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "booleanValue",
              "storageKey": null
            }
          ],
          "storageKey": "answer(key:\"connectEmailBenefits\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailConnection",
      "kind": "LinkedField",
      "name": "emailConnections",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsB2B_household"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "37b2edc78ae131cdca5a4a506ba0723c";

export default node;
