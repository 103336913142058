import { useEffect, useState } from "react"

import clsx from "clsx"
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay"

import { logger } from "src/logger"

import {
  MobileNavLinkTasksIconQuery,
  MobileNavLinkTasksIconQuery$data,
} from "./__generated__/MobileNavLinkTasksIconQuery.graphql"

function MobileNavLinkTasksIconLayout(props: {
  isActive?: boolean
  count?: number
  hasPriority?: boolean
  shouldAnimateIn?: boolean
}) {
  return (
    <div
      className={clsx(
        "relative",
        "h-6 w-6",
        "rounded-[4px]",
        "border-[1.5px]",
        props.isActive ? "border-serene-blue-600" : "border-black",
        props.isActive ? "bg-serene-blue-600" : "bg-transparent",
      )}
    >
      <div
        className={clsx(
          props.hasPriority ? "opacity-1" : "opacity-0",
          props.shouldAnimateIn && "transition-all duration-100 ease-in-out",
          "bg-nous-glow-400 absolute right-[-4px] top-[-4px] h-3 w-3 rounded-full border-2 border-white",
        )}
      ></div>
      <div
        className={clsx(
          "relative",
          "overflow-hidden",
          props.isActive ? "text-white" : "text-black",
          "h-full w-full text-center text-xs font-semibold",
        )}
      >
        <span
          className={clsx(
            "absolute",
            "left-0 right-0",
            props.shouldAnimateIn && "transition-all duration-100 ease-in-out",
            props.count != null
              ? "opacity-1 bottom-[2px]"
              : "bottom-[-24px] opacity-0",
          )}
        >
          {props.count ?? ""}
        </span>
      </div>
    </div>
  )
}

export default function MobileNavLinkTasksIcon(props: { isActive: boolean }) {
  const [state, setState] = useState<{
    data: MobileNavLinkTasksIconQuery$data | undefined
    shouldAnimateIn: boolean
  }>()
  const environment = useRelayEnvironment()
  useEffect(() => {
    const startTime = Date.now()
    fetchQuery<MobileNavLinkTasksIconQuery>(
      environment,
      graphql`
        query MobileNavLinkTasksIconQuery {
          household {
            urgentPriorityTasks: userTasks(
              priority: URGENT
              completed: false
              first: 0
            ) {
              totalCount
            }
            allPriorityTasks: userTasks(first: 0, completed: false) {
              totalCount
            }
          }
        }
      `,
      {},
      {
        fetchPolicy: "store-or-network",
      },
    )
      .toPromise()
      .then((data) => {
        const endTime = Date.now()
        const duration = endTime - startTime
        setState({
          data,
          shouldAnimateIn: duration > 100,
        })
      })
      .catch((error) => {
        logger.error("MobileNavLinkTasksIconQuery failed", error)
      })
  }, [environment])
  if (state?.data == null) {
    return <MobileNavLinkTasksIconLayout isActive={props.isActive} />
  }
  return (
    <MobileNavLinkTasksIconLayout
      isActive={props.isActive}
      count={state.data.household.allPriorityTasks?.totalCount ?? 0}
      hasPriority={
        (state.data.household.urgentPriorityTasks?.totalCount ?? 0) > 0
      }
      shouldAnimateIn={state.shouldAnimateIn}
    />
  )
}
