import { FormattedMessage, useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { GlowButton, GlowCard, GlowFlexbox, GlowText } from "src/glow"
import { titleMap } from "src/pages/SettingsPage/utils"

import { SettingsPageOverviewMembership_household$key } from "./__generated__/SettingsPageOverviewMembership_household.graphql"

export const SettingsPageOverviewMembership = (props: {
  household: SettingsPageOverviewMembership_household$key
  onGoToMembershipView: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverviewMembership_household on Household {
        currentMember {
          joinedHousehold
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  if (!household.currentMember?.joinedHousehold) {
    return null
  }

  return (
    <GlowCard>
      <GlowFlexbox direction="column" gap="6" alignItems="start">
        <GlowText fontWeight="bold">
          <FormattedMessage {...titleMap.membership} />
        </GlowText>

        <GlowText>
          <FormattedMessage
            id="page.settings.view.overview.memberJoinedHouseholdDate"
            defaultMessage="Member since {joinedHousehold}"
            values={{
              joinedHousehold: intl.formatDate(
                household.currentMember.joinedHousehold,
                {
                  month: "long",
                  year: "numeric",
                },
              ),
            }}
          />
        </GlowText>

        <GlowButton
          variant="tertiary"
          size="sm"
          label={intl.formatMessage({
            id: "page.settings.view.overview.goToMembershipView",
            defaultMessage: "Manage membership",
          })}
          className="w-full"
          onClick={props.onGoToMembershipView}
        />
      </GlowFlexbox>
    </GlowCard>
  )
}
