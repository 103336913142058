/**
 * @generated SignedSource<<3d116ab0b78d9a2e4b0db3a5d34c0fd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageSubPageBankAccounts_household$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BankAccountDetailsCard_household">;
  readonly " $fragmentType": "SettingsPageSubPageBankAccounts_household";
};
export type SettingsPageSubPageBankAccounts_household$key = {
  readonly " $data"?: SettingsPageSubPageBankAccounts_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageSubPageBankAccounts_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageSubPageBankAccounts_household",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BankAccountDetailsCard_household"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "85293ba04c1c050795322d13888bc547";

export default node;
