/**
 * @generated SignedSource<<ce80ee91da451a335e72b913a350cff3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferralsPageQuery$variables = Record<PropertyKey, never>;
export type ReferralsPageQuery$data = {
  readonly currentUser: {
    readonly " $fragmentSpreads": FragmentRefs<"ReferralsPageIntroductionSection_user">;
  } | null | undefined;
  readonly household: {
    readonly " $fragmentSpreads": FragmentRefs<"ReferralsPageShareSection_household">;
  };
};
export type ReferralsPageQuery = {
  response: ReferralsPageQuery$data;
  variables: ReferralsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReferralsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReferralsPageIntroductionSection_user"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReferralsPageShareSection_household"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReferralsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nousholdEmail",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "11dfb518388556a032db89e4358e16fe",
    "id": null,
    "metadata": {},
    "name": "ReferralsPageQuery",
    "operationKind": "query",
    "text": "query ReferralsPageQuery {\n  currentUser {\n    ...ReferralsPageIntroductionSection_user\n    id\n  }\n  household {\n    ...ReferralsPageShareSection_household\n    id\n  }\n}\n\nfragment ReferralsPageIntroductionSection_user on User {\n  firstName\n}\n\nfragment ReferralsPageShareSection_household on Household {\n  nousholdEmail\n}\n"
  }
};
})();

(node as any).hash = "cedd12c5c3160696981ba3eed593d39b";

export default node;
