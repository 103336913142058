/**
 * @generated SignedSource<<6776ec3020d22b1ad8a03a02eaa1ade3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteMyAccountMutation$variables = Record<PropertyKey, never>;
export type DeleteMyAccountMutation$data = {
  readonly deleteMyAccount: boolean | null | undefined;
};
export type DeleteMyAccountMutation = {
  response: DeleteMyAccountMutation$data;
  variables: DeleteMyAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deleteMyAccount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteMyAccountMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeleteMyAccountMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b22eb349f2c14723edc4d0f09f6fbe39",
    "id": null,
    "metadata": {},
    "name": "DeleteMyAccountMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteMyAccountMutation {\n  deleteMyAccount\n}\n"
  }
};
})();

(node as any).hash = "59b858e47473c5ee2c48f9dc99e9f4df";

export default node;
