/**
 * @generated SignedSource<<83c1baa88a916b586a55da7c7b0cb7cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EmailConnectionSource = "GOOGLE" | "MICROSOFT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection$data = {
  readonly email: string | null | undefined;
  readonly id: string;
  readonly lastScannedAt: string | null | undefined;
  readonly source: EmailConnectionSource | null | undefined;
  readonly " $fragmentType": "OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection";
};
export type OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection$key = {
  readonly " $data"?: OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastScannedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    }
  ],
  "type": "EmailConnection",
  "abstractKey": null
};

(node as any).hash = "26c2c61590faf887fede317aea664a98";

export default node;
