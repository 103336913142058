import { useRef, useCallback, useEffect } from "react"

import clsx from "clsx"
import { useIntl } from "react-intl"

import { GlowFlexbox } from "src/glow"
import { NousServiceLevel } from "src/hooks/__generated__/useRedirectToWaitlist_household.graphql"
import { useViewport, Viewport } from "src/hooks/responsive"
import useTracking from "src/tracking/useTracking"
import { CheckedRelayEnum } from "src/types"
import { serviceLevelImageMap } from "src/utils/serviceLevelImageMap"
import { serviceLevelNameMap } from "src/utils/serviceLevelNameMap"

import { ServiceLevelSelectButton } from "./ServiceLevelSelectButton"

export const ServiceLevelSelectButtons = ({
  selectedServiceLevel,
  onSelect,
  variant = "default",
}: {
  selectedServiceLevel?: CheckedRelayEnum<NousServiceLevel> | null
  onSelect: (serviceLevel: CheckedRelayEnum<NousServiceLevel>) => void
  variant?: "default" | "snappy"
}) => {
  const intl = useIntl()

  const track = useTracking()

  const viewport = useViewport()
  const isMobile = viewport <= Viewport.SM

  const containerRef = useRef<HTMLDivElement>(null)

  const scrollToItem = useCallback(
    (index: number) => {
      if (!isMobile) {
        return
      }

      const container = containerRef.current
      if (container) {
        const items = container.querySelectorAll<HTMLButtonElement>("button")
        if (items.length > 0) {
          const itemWidth = items[index].clientWidth
          const viewportWidth = window.innerWidth
          const offset = (viewportWidth - itemWidth) / 2

          container.scrollTo({
            left: items[index].offsetLeft - offset,
            behavior: "smooth",
          })
        }
      }
    },
    [isMobile, containerRef],
  )

  useEffect(() => {
    scrollToItem(1)
  }, [scrollToItem])

  useEffect(() => {
    const handleResize = () => {
      scrollToItem(1)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [scrollToItem])

  return (
    <div
      ref={containerRef}
      className={clsx(
        variant === "snappy" && "w-full pb-4 md:rounded-lg md:bg-white md:p-6",
        variant === "snappy" &&
          isMobile &&
          "snap-x snap-mandatory overflow-x-scroll scroll-smooth",
      )}
    >
      <GlowFlexbox
        gap="4"
        className={clsx(
          variant === "default" && "px-0",
          variant === "snappy" && "w-full",
          variant === "snappy" &&
            isMobile &&
            "px-4 after:block after:h-[1px] after:w-4 after:p-[1px]",
        )}
        direction={
          variant === "snappy"
            ? "row"
            : {
                sm: "column",
                md: "row",
              }
        }
      >
        <ServiceLevelSelectButton
          onSelect={() => {
            scrollToItem(0)
            track([
              "ServiceLevel",
              "Selected",
              {
                level: "DO_EVERYTHING_MYSELF",
                name: intl.formatMessage(
                  serviceLevelNameMap.DO_EVERYTHING_MYSELF,
                ),
                description: intl.formatMessage({
                  id: "component.serviceLevelSelect.buttonText.DO_EVERYTHING_MYSELF",
                  defaultMessage: "Do everything myself",
                }),
              },
            ])
            onSelect("DO_EVERYTHING_MYSELF")
          }}
          isSelected={selectedServiceLevel === "DO_EVERYTHING_MYSELF"}
          image={serviceLevelImageMap["DO_EVERYTHING_MYSELF"]}
          label={intl.formatMessage(serviceLevelNameMap.DO_EVERYTHING_MYSELF)}
          text={intl.formatMessage({
            id: "component.serviceLevelSelect.buttonText.DO_EVERYTHING_MYSELF",
            defaultMessage: "Do everything myself",
          })}
          variant={variant}
        />
        <ServiceLevelSelectButton
          onSelect={() => {
            scrollToItem(1)
            track([
              "ServiceLevel",
              "Selected",
              {
                level: "DO_MOST_OF_IT_FOR_ME",
                name: intl.formatMessage(
                  serviceLevelNameMap.DO_MOST_OF_IT_FOR_ME,
                ),
                description: intl.formatMessage({
                  id: "component.serviceLevelSelect.buttonText.DO_MOST_OF_IT_FOR_ME",
                  defaultMessage: "Do most of it for me",
                }),
              },
            ])
            onSelect("DO_MOST_OF_IT_FOR_ME")
          }}
          isSelected={selectedServiceLevel === "DO_MOST_OF_IT_FOR_ME"}
          image={serviceLevelImageMap["DO_MOST_OF_IT_FOR_ME"]}
          label={intl.formatMessage(serviceLevelNameMap.DO_MOST_OF_IT_FOR_ME)}
          text={intl.formatMessage({
            id: "component.serviceLevelSelect.buttonText.DO_MOST_OF_IT_FOR_ME",
            defaultMessage: "Do most of it for me",
          })}
          variant={variant}
        />
        <ServiceLevelSelectButton
          onSelect={() => {
            scrollToItem(2)
            track([
              "ServiceLevel",
              "Selected",
              {
                level: "DO_ALL_OF_IT_FOR_ME",
                name: intl.formatMessage(
                  serviceLevelNameMap.DO_ALL_OF_IT_FOR_ME,
                ),
                description: intl.formatMessage({
                  id: "component.serviceLevelSelect.buttonText.DO_ALL_OF_IT_FOR_ME",
                  defaultMessage: "Do all of it for me",
                }),
              },
            ])
            onSelect("DO_ALL_OF_IT_FOR_ME")
          }}
          isSelected={selectedServiceLevel === "DO_ALL_OF_IT_FOR_ME"}
          image={serviceLevelImageMap["DO_ALL_OF_IT_FOR_ME"]}
          label={intl.formatMessage(serviceLevelNameMap.DO_ALL_OF_IT_FOR_ME)}
          text={intl.formatMessage({
            id: "component.serviceLevelSelect.buttonText.DO_ALL_OF_IT_FOR_ME",
            defaultMessage: "Do all of it for me",
          })}
          variant={variant}
        />
      </GlowFlexbox>
    </div>
  )
}
