import clsx from "clsx"

import { GlowInputErrors } from "./GlowInput"

export type GlowQuizInputProps = {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  prefix?: string
  type?: "text" | "number"
  errors?: (string | undefined | null | false)[]
} & Omit<React.ComponentProps<"input">, "onChange">

export function GlowQuizInput({
  prefix,
  onChange,
  className,
  ...props
}: GlowQuizInputProps) {
  const errors = (props.errors?.filter(Boolean) ?? []) as string[]
  const hasErrors = (errors?.length ?? 0) > 0
  return (
    <div className="w-full">
      <label
        className={clsx(
          "flex overflow-hidden rounded-lg border-2 text-base transition-colors",
          hasErrors
            ? "border-angsty-red-400 hover:border-angsty-red-400"
            : "border-gray-500/20 hover:border-gray-500/60",
          "focus-within:outline-serene-blue-400 focus-within:border-2 focus-within:outline-2 focus-within:outline-offset-0",
          className,
        )}
      >
        {prefix && (
          <div
            className={clsx(
              "bg-gray-100 text-sm font-medium text-gray-500/60",
              "flex items-center px-2",
              "font-gray-500 font-good-sans-bold text-xl",
            )}
          >
            {prefix}
          </div>
        )}
        <input
          className={clsx(
            "w-full border-0 bg-transparent p-[10px]",
            "placeholder:text-gray-500 placeholder:opacity-30",
            "focus:outline-0 focus:ring-0",
            "font-good-sans-medium text-xl",
          )}
          onChange={(e) => onChange(e.currentTarget.value)}
          {...props}
        />
      </label>
      <GlowInputErrors errors={errors} />
    </div>
  )
}
