import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import useBudget2024AutumnModulesQuery, {
  useBudget2024AutumnModulesQuery as useBudget2024AutumnModulesQueryType,
} from "src/tools/Budget/Budget2024Autumn/__generated__/useBudget2024AutumnModulesQuery.graphql"

export const Route = createFileRoute("/budget/2024/autumn")({
  loader: ({ context }) =>
    loadQuery<useBudget2024AutumnModulesQueryType>(
      context.relayEnvironment(),
      useBudget2024AutumnModulesQuery,
      {},
    ),
  beforeLoad: ({ context }) => {
    if (context.authState.isAuthenticated) {
      throw redirect({ to: "/tools/budget/2024/autumn" })
    }
  },
  component: lazyRouteComponent(
    () => import("src/pages/Budget2024AutumnPage/Budget2024AutumnPage"),
  ),
})
