import { FormattedMessage, useIntl } from "react-intl"

import { GlowButton, GlowFlexbox, GlowText } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import useMicrosoftOutlook, {
  MicrosoftConnectionCallback,
} from "src/hooks/useMicrosoftOutlook"
import useTracking from "src/tracking/useTracking"

import microsoftAuthorizationExplainer from "./assets/microsoftAuthorizationExplainer.png"
import microsoftAuthorizationExplainer2x from "./assets/microsoftAuthorizationExplainer@2x.png"
import microsoftAuthorizationExplainer3x from "./assets/microsoftAuthorizationExplainer@3x.png"

type ConnectWithOutlookPopupProps = {
  isOpen: boolean
  onClose: () => void
  redirectUri?: string
  onConnect?: MicrosoftConnectionCallback
}

export const ConnectWithOutlookPopup = ({
  isOpen,
  onClose,
  onConnect,
}: ConnectWithOutlookPopupProps) => {
  const intl = useIntl()
  const track = useTracking()

  const { navigateToMicrosoftAuthorizationPage } = useMicrosoftOutlook({
    onConnect,
  })

  return (
    <GlowModal
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({
        defaultMessage: "Connecting with Outlook...",
        id: "connectingWithOutlookPopup.title",
      })}
      level="1"
      noPadding
      modalClassName="md:h-[620px]"
    >
      <GlowFlexbox
        direction="column"
        gap="4"
        alignItems="center"
        className="w-full p-6 md:min-w-[528px]"
      >
        <GlowText textAlign="center">
          <FormattedMessage
            id="connectingWithOutlookPopup.description"
            defaultMessage="When you're asked, please give Nous permission to automatically find your providers by clicking 'Accept' as shown below:"
          />
        </GlowText>
        <img
          srcSet={`${microsoftAuthorizationExplainer}, ${microsoftAuthorizationExplainer2x} 2x, ${microsoftAuthorizationExplainer3x} 3x`}
          alt=""
          className="w-[312px]"
        />
        <GlowButton
          label={intl.formatMessage({
            defaultMessage: "Connect now",
            id: "connectingWithGmailModal.connectNowCTALabel",
          })}
          onClick={() => {
            track([
              "EmailConnection",
              "Attempted",
              { emailProvider: "Microsoft" },
            ])
            navigateToMicrosoftAuthorizationPage()
          }}
          className="w-full"
        />
      </GlowFlexbox>
    </GlowModal>
  )
}
