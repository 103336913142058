import { useIntl } from "react-intl"

import { titleMap } from "src/pages/SettingsPage/utils"

import { SettingsPageOverviewGenericSection } from "./SettingsPageOverviewGenericSection"

export const SettingsPageOverviewNotifications = (props: {
  onGoToNotificationsView: () => void
}) => {
  const intl = useIntl()

  return (
    <SettingsPageOverviewGenericSection
      icon={"settings_slider_regular"}
      title={intl.formatMessage(titleMap.notifications)}
      onClick={props.onGoToNotificationsView}
    />
  )
}
